import {Campaign, EddyCampaignTypes} from '../global-services/campaign.model';
import {environment} from '../../environments/environment';
import * as moment from 'moment';

import {DataPattern, EyeBallShape, EyeFrameShape, GradientType, QRCodeFrame} from 'mobstac-awesome-qr/lib/Enums';
import {BaseModel} from '../global-services/base.model';
import { BARCODE_TYPES } from 'app/gs1/gs1-utils/gs1-constants';

enum QR_DOMAINS {
    TAPNSCAN_ME = 0,
    QRCODES_PRO = 1
}

enum QR_TYPES_STRING {
    URL = 'url',
    SMS = 'sms',
    PHONE = 'call',
    EMAIL = 'email',
    TEXT = 'text',
    VCARD = 'vcard',
    WIFI = 'staticwifi',
    LINKPAGE = 'linkpage'
}

export enum QR_TYPES {
    URL = 1,
    SMS = 2,
    PHONE = 3,
    EMAIL = 4,
    VCARD = 5,
    TEXT = 6,
    WIFI = 7,
    LINKPAGE
}

export enum QR_CODE_TYPES {
    Static = 1,
    Dynamic = 2
}

export enum QR_CANVAS_TYPES {
    JPEG = 'jpeg',
    PNG = 'png',
    SVG = 'svg',
    PDF = 'pdf',
    EPS = 'eps'
}

export enum QR_SIZE {
    SMALL_512 = 512,
    MEDIUM_1024 = 1024,
    LARGE_2048 = 2048,
    EXTRA_LARGE_4096 = 4096
}

export enum QR_CANVAS_TYPE_DESCRIPTION {
    PDF = 'High quality document for email and print',
    PNG = 'High quality image, great for print and digital',
    SVG = 'Sharp vector graphics at any size',
    JPEG = 'Small file size, doesn\'t support transparency',
    EPS = 'Sharp vector graphics, great for print'
}

export enum QR_ERROR_CORRECTION_LEVEL {
    L = 1,
    M = 0,
    Q = 3,
    H = 2,
}

export enum QR_BACKGROUND_TYPE {
    Transparent,
    Image,
    Color,
}

export enum QR_CODE_SOURCE {
    Dashboard = 'dashboard',
    BulkUpload = 'bulk',
}



export enum QR_CAMPAIGN_TYPE {
    Website = 'website',
    LandingPage = 'landing_page',
    FeedbackForm = 'feedback_form',
    PDF = 'pdf',
    Location = 'location',
    Image = 'image',
    MP3 = 'mp3',
    AppDownloadLinks = 'app_download_links',
    VCardPlus = 'vcard_plus',
    SocialMedia = 'social_media',
    FacebookPage = 'facebook_page',
    CouponCode = 'coupon',
    BusinessCard = 'business_card',
    Attendance = 'attendance',
    RestaurantMenu = 'restaurant_menu',
    PhoneSMS = 'phone_sms',
    PhoneCall = 'phone_call',
    Email = 'dynamic_email',
    StaticWebsite = 'static_website',
    StaticSMS = 'sms',
    StaticPhone = 'phone',
    StaticEmail = 'email',
    StaticvCard = 'vcard',
    StaticText = 'text',
    StaticWifi = 'staticwifi',
    None = '',
    Linkpage = 'linkpage',
    StaticLinkpage = 'static_linkpage',
    GeoLocation = 'geo_location',
    SmartRules = 'smart_rules',
    GS1 = 'GS1'
}

export enum QR_TEMPLATE {
    PLAIN_TEMPLATE = 'plain_template',
}

export enum QR_TEMPLATE_SIZE {
    SIZE4BY6 = 1,
    SIZE5BY7 = 2
}

export enum QR_CODE_TYPE_VALUE {
    All = 0,
    Static = 1,
    Dynamic = 2,
    Deleted = 3,
    Watchlist = 4
}

export enum QR_BULK_OPERATIONS {
    BulkDownload = 1,
    AddLabel = 2,
    CSVreport = 3,
    ShareWalletPass = 4,
    Delete = 5,
    DeletePermanently = 6,
    ViewAnalytics = 7
}

export class QR extends BaseModel {

    static qrDomains = environment.qrDomains;

    id: number;
    meta: object = {};
    place_data: object;
    tag_data: Array<any>;
    campaign: Campaign = new Campaign();
    name: string;
    url: string = '';
    threat_active: boolean;
    location_enabled: boolean;
    domain: QR_DOMAINS = QR_DOMAINS.QRCODES_PRO;
    custom_domain: number;
    attributes = {
        color: '#000000',
        colorDark: '#000000',
        colorLight: '#8f00ff',
        gradientType: GradientType.NONE,
        backgroundImage: '',
        backgroundColor: '',
        logoImage: '',
        frameStyle: QRCodeFrame.NONE,
        frameColor: '#000000',
        frameText: '',
        frameTextColor: '#000000',
        dataPattern: DataPattern.SQUARE,
        eyeBallShape: EyeBallShape.SQUARE,
        eyeFrameShape: EyeFrameShape.SQUARE,
        eyeBallColor: '#000000',
        eyeFrameColor: '#000000',
        logoBackground: true,
        margin: 80,
        dotScale: 1,
        rectangular: true,
        logoWidth: 0,
        logoHeight: 0,
        logoMargin: 10,
        logoScale: 0.2,
        isVCard: false,
        showBarcode: false,
        showBarcodeValue: false,
        barcodeValue: '',
        barcodeType: BARCODE_TYPES.CODE128,
        barcodeText: '',
        primaryIdentifierValue: '',
    };
    template: number;
    fields_data: object;
    state: string = 'A';
    created: string;
    updated: string;
    organization: number;
    maintainer: number;
    place: number;
    tags: Array<number>;
    password: string;
    view_limit: number = null;
    scans: number = 0;
    additional_query_params: object;
    qr_type: QR_CODE_TYPES = QR_CODE_TYPES.Dynamic;
    is_created_for_bulk: Boolean = false;
    notes: string;

    owner_details: {
        id: number,
        first_name: string,
        last_name: string,
        email: string,
        profile_picture: string
    }

    // UI variables
    origin: string = QR.qrDomains[this.domain];
    slug: string;
    generatedData: string;
    generatedPreviewData: string;
    qrCampaignType: QR_CAMPAIGN_TYPE;
    qrCustomUrl: string;
    hasUsageLimit: boolean = false;
    isScanable = { message: '', scanScore: ''};
    domainString: string = '';
    is_watchlisted: boolean = false;

    organization_details: {
        id: number,
        name: string,
    }

    // Models for forms
    urlModel = {
        url: ''
    };

    smsModel = {
        phone: '',
        body: ''
    };

    phoneModel = {
        phone: ''
    };

    emailModel = {
        email: '',
        subject: '',
        body: ''
    };

    vcardModel = {
        first_name: '',
        last_name: '',
        phone: '' || {home: '', work: '', mobile: ''},
        email: '',
        company: '',
        designation: '',
        website: '',
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        country: '',
        zip: ''
    };

    textModel = {
        text: ''
    };

    wifiModel = {
        ssid: '',
        password: '',
        hidden: false,
        encryption: 'WPA'
    };

    linkpageModel = {
        linkpage_url: ''
    }

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.meta = json['meta'];
        this.place_data = json['place_data'];
        this.tag_data = json['tag_data'];
        this.campaign = new Campaign(json['campaign']);
        this.fields_data = json['fields_data'];
        this.name = json['name'];
        this.url = json['url'];
        this.location_enabled = json['location_enabled'];
        this.threat_active = json['threat_active'] || false;
        this.domain = json['domain'] || 0;
        this.custom_domain = json['custom_domain'];
        this.attributes = json['attributes'];
        this.qr_type = json['qr_type'];
        this.state = json['state'];
        this.created = json['created'];
        this.updated = json['updated'];
        this.organization = json['organization'];
        this.maintainer = json['maintainer'];
        this.place = json['place'];
        this.tags = json['tags'];
        this.password = json['password'];
        this.view_limit = json['view_limit'];
        this.scans = json['scans'];
        this.organization_details = json['organization_details'];
        this.template = json['template'];
        this.owner_details = json['owner_details'] || {};
        this.additional_query_params = json['additional_query_params'];
        this.notes = json['notes'];
        if (this.attributes.logoBackground === undefined) {
            this.attributes.logoBackground = true;
        }
        if (this.attributes.rectangular === undefined) {
            this.attributes.rectangular = false;
        }
        this.attributes.logoWidth = this.attributes.logoWidth ? this.attributes.logoWidth : 0;
        this.attributes.logoHeight = this.attributes.logoHeight ? this.attributes.logoHeight : 0;
        this.attributes.logoBackground  = this.attributes.logoBackground ? this.attributes.logoBackground : false,
        this.attributes.frameColor = this.attributes.frameColor ? this.attributes.frameColor : '#000000';
        this.attributes.frameStyle = this.attributes.frameStyle || QRCodeFrame.NONE;
        this.attributes.frameText = this.attributes.frameText || '';
        this.attributes.frameTextColor = this.attributes.frameTextColor || '#ffffff';
        this.attributes.dataPattern = this.attributes.dataPattern ? this.attributes.dataPattern : DataPattern.SQUARE;
        this.attributes.eyeBallShape = this.attributes.eyeBallShape ? this.attributes.eyeBallShape : EyeBallShape.SQUARE;
        this.attributes.eyeFrameShape = this.attributes.eyeFrameShape ? this.attributes.eyeFrameShape : EyeFrameShape.SQUARE;
        this.attributes.eyeBallColor = this.attributes.eyeBallColor ? this.attributes.eyeBallColor : '';
        this.attributes.eyeFrameColor = this.attributes.eyeFrameColor ? this.attributes.eyeFrameColor : '';
        this.attributes.logoMargin = this.attributes.logoMargin ? this.attributes.logoMargin : ( this.attributes.logoMargin === 0) ? 0 : 10 ;
        this.attributes.logoScale = this.attributes.logoScale ? this.attributes.logoScale : 0.2;
        this.attributes.backgroundImage = this.attributes.backgroundImage || '';
        this.attributes.backgroundColor = this.attributes.backgroundColor || '';
        this.attributes.logoImage = this.attributes.logoImage || '';
        this.attributes.gradientType = this.attributes.gradientType || GradientType.NONE;
        this.attributes.colorLight = this.attributes.colorLight || '#8f00ff';
        this.attributes.colorDark = this.attributes.colorDark || this.attributes.color || '#000000';
        this.attributes.color = this.attributes.color || '#000000';
        this.attributes.margin = 80;
        this.attributes.dotScale = 1;
        if (!this.attributes.eyeBallColor) {
            if (!this.attributes.colorDark) {
                this.attributes.eyeBallColor = '#000000'
            } else {
                this.attributes.eyeBallColor = this.attributes.colorDark
            }
        }
        if (!this.attributes.eyeFrameColor) {
            if (!this.attributes.colorDark) {
                this.attributes.eyeFrameColor = '#000000'
            } else {
                this.attributes.eyeFrameColor = this.attributes.colorDark
            }
        }
        // @ts-ignore
        this.attributes.isVCard = this.fields_data.qr_type === QR_TYPES.VCARD;

        this.qrCustomUrl = this.campaign.custom_url;
        this.is_created_for_bulk = json['is_created_for_bulk'] || false;

        this.setUpSlug();

        this.generateStaticQRData();

        this.setHasUsageLimit();

        this.setupCampaignTypeMeta();
        this.domainString  = this.getDomain();
    }

    generateStaticQRData(previewForStepFour = false) {
        if (this.isStatic()) {
            this.buildTypeObject();
            if ( previewForStepFour ){
                this.generatedData = this.getQRCodeData(this.getTypeString());
            }
        }
    }

    getDomain() {
        if (this.custom_domain) {
            return 'custom_domain_' +  this.custom_domain;
        }else{
            return 'beaconstac_domain_' + this.domain;
        }

    }

    setDomain(domain_value: string) {
        if (domain_value.indexOf('custom_domain') !== -1){
            this.custom_domain = Number.parseInt(domain_value.replace('custom_domain_', ''));
        }else if (domain_value.indexOf('beaconstac_domain') !== -1){
            this.custom_domain = null;
            this.domain = Number.parseInt(domain_value.replace('beaconstac_domain_', ''));
        }
    }

    setupCampaignTypeMeta() {
        if (!this.meta.hasOwnProperty('dynamic_sub_campaign')) {
            let type = '';
            switch (this.campaign.content_type) {
                case EddyCampaignTypes.CustomURL:
                    type = QR_CAMPAIGN_TYPE.Website;
                    break;
                case EddyCampaignTypes.PDF:
                    type = QR_CAMPAIGN_TYPE.PDF;
                    break;
                case EddyCampaignTypes.Attendance:
                    type = QR_CAMPAIGN_TYPE.Attendance;
                    break;
                case EddyCampaignTypes.Form:
                    type = QR_CAMPAIGN_TYPE.FeedbackForm;
                    break;
                case EddyCampaignTypes.FacebookPage:
                    type = QR_CAMPAIGN_TYPE.FacebookPage;
                    break;
                case EddyCampaignTypes.Card:
                    type = QR_CAMPAIGN_TYPE.LandingPage;
                    break;
                case EddyCampaignTypes.AppLinks:
                    type = QR_CAMPAIGN_TYPE.AppDownloadLinks;
                    break;
                case EddyCampaignTypes.VCardPlus:
                    type = QR_CAMPAIGN_TYPE.VCardPlus;
                    break;
                case EddyCampaignTypes.SocialMedia:
                    type = QR_CAMPAIGN_TYPE.SocialMedia;
                    break;
                case EddyCampaignTypes.CouponCode:
                    type = QR_CAMPAIGN_TYPE.CouponCode;
                    break;
                case EddyCampaignTypes.BusinessCard:
                    type = QR_CAMPAIGN_TYPE.BusinessCard;
                    break;
                case EddyCampaignTypes.RestaurantMenu:
                    type = QR_CAMPAIGN_TYPE.RestaurantMenu;
                    break;
                case EddyCampaignTypes.PhoneCall:
                    type = QR_CAMPAIGN_TYPE.PhoneCall;
                    break;
                case EddyCampaignTypes.PhoneSMS:
                    type = QR_CAMPAIGN_TYPE.PhoneSMS;
                    break;
                case EddyCampaignTypes.Email:
                    type = QR_CAMPAIGN_TYPE.Email;
                    break;
                case EddyCampaignTypes.Linkpage:
                    type = QR_CAMPAIGN_TYPE.Linkpage;
                    break;
            }
            if (type) {
                this.meta['dynamic_sub_campaign'] = type;
            }
        }
    }

    setUpSlug() {
        this.slug = this.url.replace(this.origin, '');
    }

    setHasUsageLimit() {
        this.hasUsageLimit = this.view_limit > 0;
    }

    setUrl() {
        this.url = environment.qrUrl + encodeURIComponent(this.slug);
    }

    setGs1Url() {
        this.url = environment.gs1Url + encodeURI(this.slug)
    }

    getUrl() {
        return environment.qrUrl + decodeURIComponent(this.slug);
    }

    getPreviewUrl(): string {
        if (this.url) {
            return this.url + '?preview=true';
        } else {
            return null;
        }
    }

    typeOf() {
        return 'QR';
    }

    isStatic() {
        return this.qr_type === QR_CODE_TYPES.Static;
    }

    isDynamic() {
        return this.qr_type === QR_CODE_TYPES.Dynamic;
    }

    isWatchlisted() {
        return this.is_watchlisted;
    }

    templateData() {
        return {
            'name': this.name,
            'organization': this.organization,
            'colorDark': this.attributes.colorDark,
            'colorLight': this.attributes.colorLight,
            'dataPattern': this.attributes.dataPattern,
            'eyeBallShape': this.attributes.eyeBallShape,
            'eyeBallColor': this.attributes.eyeBallColor,
            'eyeFrameShape': this.attributes.eyeFrameShape,
            'eyeFrameColor': this.attributes.eyeFrameColor,
            'logoImage': this.attributes.logoImage,
            'logoBackground': this.attributes.logoBackground,
            'logoScale': this.attributes.logoScale,
            'rectangular': this.attributes.rectangular,
            'logoWidth': this.attributes.logoWidth,
            'logoHeight': this.attributes.logoHeight,
            'gradientType': this.attributes.gradientType,
            'backgrounfImage': this.attributes.backgroundImage,
            'backgroundColor': this.attributes.backgroundColor,
            'frameStyle': this.attributes.frameStyle,
            'frameColor': this.attributes.frameColor,
            'frameText': this.attributes.frameText,
            'frameTextColor': this.attributes.frameTextColor,
            'logoMargin': this.attributes.logoMargin
        }
    }

    private buildTypeObject() {
        switch (this.fields_data['qr_type']) {
            case QR_TYPES.URL:
                this.urlModel = {
                    url: this.fields_data['url']
                };
                break;
            case QR_TYPES.LINKPAGE:
                this.linkpageModel = {
                    linkpage_url: this.fields_data['linkpage_url']
                };
                break;
            case QR_TYPES.SMS:
                this.fields_data['phone'] = this.fields_data['phone'] + '';
                this.fields_data['phone'] = this.fields_data['phone'].replace(/\s/g, '');
                this.smsModel = {
                    phone: this.fields_data['phone'],
                    body: this.fields_data['body']
                };
                break;
            case QR_TYPES.PHONE:
                this.phoneModel = {
                    phone: this.fields_data['phone']
                };

                break;
            case QR_TYPES.EMAIL:
                this.emailModel = {
                    email: this.fields_data['email'],
                    subject: this.fields_data['subject'],
                    body: encodeURIComponent(this.fields_data['body'])
                };

                break;
            case QR_TYPES.TEXT:
                this.textModel = {
                    text: this.fields_data['text']
                };
                break;
            case QR_TYPES.VCARD:
                this.vcardModel = {
                    first_name: this.fields_data['first_name'],
                    last_name: this.fields_data['last_name'],
                    phone: this.fields_data['phone'],
                    email: this.fields_data['email'],
                    company: this.fields_data['company'] || '',
                    designation: this.fields_data['designation'] || '',
                    website: this.fields_data['website'] || '',
                    address_line1: this.fields_data['address_line1'] || '',
                    address_line2: this.fields_data['address_line2'] || '',
                    city: this.fields_data['city'] || '',
                    state: this.fields_data['state'] || '',
                    country: this.fields_data['country'] || '',
                    zip: this.fields_data['zip'] || '',
                };
                break;
            case QR_TYPES.WIFI:
                this.wifiModel = {
                    ssid: this.fields_data['ssid'],
                    password: this.fields_data['password'],
                    hidden: this.fields_data['hidden'],
                    encryption: this.fields_data['encryption']
                };
                break;
        }

    }

    getTypeString() {
        switch (this.fields_data['qr_type']) {
            case QR_TYPES.URL:
                return QR_TYPES_STRING.URL;
            case QR_TYPES.SMS:
                return QR_TYPES_STRING.SMS;
            case QR_TYPES.PHONE:
                return QR_TYPES_STRING.PHONE;
            case QR_TYPES.VCARD:
                return QR_TYPES_STRING.VCARD;
            case QR_TYPES.TEXT:
                return QR_TYPES_STRING.TEXT;
            case QR_TYPES.EMAIL:
                return QR_TYPES_STRING.EMAIL;
            case QR_TYPES.WIFI:
                return QR_TYPES_STRING.WIFI;
            case QR_TYPES.LINKPAGE:
                return QR_TYPES_STRING.LINKPAGE;

        }
    }

    getQRCodeData(type) {
    // Common variables
        let text = null;
        let email = null;
        let msg = null;
        switch (type) {
            case QR_TYPES_STRING.URL:
                text = this.urlModel.url;
                break;
            case QR_TYPES_STRING.TEXT:
                text = this.textModel.text;
                break;
            case QR_TYPES_STRING.EMAIL:
                email = this.emailModel.email;
                const sub = this.emailModel.subject;
                msg = this.emailModel.body;
                text = ('mailto:' + email + '?subject=' + sub + '&body=' + msg);
                break;
            case QR_TYPES_STRING.SMS:
                const num = this.smsModel.phone;
                msg = this.smsModel.body;
                text = ('smsto:' + num + ':' + msg);
                break;
            case QR_TYPES_STRING.PHONE:
                const phone_number = this.phoneModel.phone;
                text = ('tel:' + phone_number);
                break;
            case QR_TYPES_STRING.VCARD:
                const fn = this.vcardModel.first_name;
                const ln = this.vcardModel.last_name;
                const mob = this.vcardModel.phone;
                email = this.vcardModel.email;

                let street = '';
                if (this.vcardModel.address_line1 && this.vcardModel.address_line2) {
                    street = this.vcardModel.address_line1 + ', ' + this.vcardModel.address_line2
                } else if (this.vcardModel.address_line1) {
                    street = this.vcardModel.address_line1;
                } else if (this.vcardModel.address_line2) {
                    street = this.vcardModel.address_line2;
                }
                let company = '';
                // since ; appears in company field if it is absent
                if (this.vcardModel.company) {
                    company = this.vcardModel.company;
                }

                text = 'BEGIN:VCARD\n' +
          'VERSION:3.0\n' +
          'N:' + ln + ';' + fn + ';;\n' +
          'FN:' + fn + ' ' + ln + '\n' +
          'ORG:' + company + '\n' +
          'TITLE:' + this.vcardModel.designation + '\n';
                if (typeof mob === 'string') {
                    text += 'TEL;TYPE=work:' + mob + '\n';
                } else {
                    text += 'TEL;TYPE=work:' + mob['work'] + '\n';
                    if (mob['mobile']) {
                        text += 'TEL;TYPE=mobile:' + mob['mobile'] + '\n';
                    }
                    if (mob['home']) {
                        text += 'TEL;TYPE=home:' + mob['home'] + '\n';
                    }
                }
                text += 'EMAIL:' + email + '\n' +
          'ADR;TYPE=WORK:;;' + street + ';' +
          this.vcardModel.city + ';' + this.vcardModel.state + ';' + this.vcardModel.zip + ';' + this.vcardModel.country + '\n' +
          'URL:' + this.vcardModel.website + '\n' +
          'REV:2008-04-24T19:52:43Z\n' +
          'END:VCARD';
                break;
            case QR_TYPES_STRING.WIFI:
                const ssid = this.wifiModel.ssid;
                const password = this.wifiModel.password;
                const hidden = this.wifiModel.hidden;
                const encryption = this.wifiModel.encryption;
                text = 'WIFI:' +
          'S:' + ssid + ';' +
          'T:' + encryption + ';';
                if (!(encryption === 'nopass')) {
                    text += 'P:' + password;
                }
                text += ';' +
          'H:' + hidden +
          ';';
                break;
            case QR_TYPES_STRING.LINKPAGE:
                text = this.linkpageModel.linkpage_url;
                break;

        }
        return text;
    }

    getReadableCreatedDate(): string {
        return moment(this.created).format('MMM D YYYY');
    }

    getReadableUpdatedDate(): string {
        return moment(this.updated).format('MMM D YYYY');
    }

    getQrTypeString(): string {
        return QR_CODE_TYPES[this.qr_type];
    }

    getStaticCampaignType() {
        return this.isStatic() ? this.fields_data['qr_type'] : null;
    }

    resetAttributes() {
        this.attributes.rectangular = false;
        this.attributes.logoWidth = 0;
        this.attributes.logoHeight =  0;
        this.attributes.backgroundColor = '';
        this.attributes.logoBackground = true;
        this.attributes.backgroundImage = '';
        this.attributes.colorDark = '#000000';
        this.attributes.colorLight = '#8f00ff';
        this.attributes.dataPattern = DataPattern.SQUARE;
        this.attributes.eyeBallColor = '#000000';
        this.attributes.eyeBallShape = EyeBallShape.SQUARE;
        this.attributes.eyeFrameColor = '#000000';
        this.attributes.eyeFrameShape = EyeFrameShape.SQUARE;
        this.attributes.frameColor = '#000000';
        this.attributes.frameStyle = QRCodeFrame.NONE;
        this.attributes.frameText = '';
        this.attributes.frameTextColor = '';
        this.attributes.gradientType = GradientType.NONE;
        this.attributes.logoImage = '';
        this.attributes.logoMargin = 10;
        this.attributes.logoScale = 0.2;
        this.attributes.margin = 80;
    }


    addHttpsToCustomUrl() {
        if (this.campaign.custom_url && this.campaign.custom_url.toLowerCase().indexOf('http://') === -1 && this.campaign.custom_url.toLowerCase().indexOf('https://') === -1) {
            this.campaign.custom_url = 'https://' + this.campaign.custom_url;
        }
    }

    addHttpsToGeoLocation() {
        if (this.campaign.geo_location_content) {
            for (const country of Object.keys(this.campaign.geo_location_content.location_content)) {
                if (this.campaign.geo_location_content.location_content[country].url && this.campaign.geo_location_content.location_content[country].url.toLowerCase().indexOf('http://') === -1 && this.campaign.geo_location_content.location_content[country].url.toLowerCase().indexOf('https://') === -1) {
                    this.campaign.geo_location_content.location_content[country].url = 'https://' + this.campaign.geo_location_content.location_content[country].url;
                }
            }
        }
    }


    addHttpsToSocialMediaWebsiteCampaign() {
        if (this.campaign.social_media && this.campaign.social_media.urls && this.campaign.social_media.urls.website) {
            if (this.campaign.social_media.urls.website.toLowerCase().indexOf('http://') === -1 && this.campaign.social_media.urls.website.toLowerCase().indexOf('https://') === -1) {
                this.campaign.social_media.urls.website = 'https://' + this.campaign.social_media.urls.website;
            }
        }
    }

    addHttpsToCustomUrlForMultiLang() {
        for (const language of Object.keys(this.campaign.multi_language_content.custom_urls)) {
            if (this.campaign.multi_language_content.custom_urls[language].url && this.campaign.multi_language_content.custom_urls[language].url.toLowerCase().indexOf('http://') === -1 && this.campaign.multi_language_content.custom_urls[language].url.toLowerCase().indexOf('https://') === -1) {
                this.campaign.multi_language_content.custom_urls[language].url = 'https://' + this.campaign.multi_language_content.custom_urls[language].url;
            }
        }
    }

    addHttpsToAppLinks() {
        if (!this.campaign.app_links) {
            return;
        }
        if (this.campaign.app_links.fallback_url && this.campaign.app_links.fallback_url.toLowerCase().indexOf('http://') === -1
      && this.campaign.app_links.fallback_url.toLowerCase().indexOf('https://') === -1) {
            this.campaign.app_links.fallback_url = 'https://' + this.campaign.app_links.fallback_url;
        }
        if (this.campaign.app_links.android_url && this.campaign.app_links.android_url.toLowerCase().indexOf('http://') === -1
      && this.campaign.app_links.android_url.toLowerCase().indexOf('https://') === -1) {
            this.campaign.app_links.android_url = 'https://' + this.campaign.app_links.android_url;
        }
        if (this.campaign.app_links.iphone_url && this.campaign.app_links.fallback_url.toLowerCase().indexOf('http://') === -1
      && this.campaign.app_links.iphone_url.toLowerCase().indexOf('https://') === -1) {
            this.campaign.app_links.iphone_url = 'https://' + this.campaign.app_links.iphone_url;
        }
        if (this.campaign.app_links.ipad_url && this.campaign.app_links.ipad_url.toLowerCase().indexOf('http://') === -1
      && this.campaign.app_links.ipad_url.toLowerCase().indexOf('https://') === -1) {
            this.campaign.app_links.ipad_url = 'https://' + this.campaign.app_links.ipad_url;
        }
    }

}

export class QrURLValidation {
    url: string;
}

export enum QRCODE_OPERATION {
    showMore = 'showMore',
    duplicate = 'duplicate',
    addLabel = 'addLabel',
    changeOrganization = 'changeOrganization',
    changeMaintainer = 'changeOwner',
    saveTemplate = 'saveTemplate',
    delete = 'delete',
    edit = 'edit',
    analytics = 'analytics',
    preview = 'preview',
    download = 'download',
    shareWalletPass = 'shareWalletPass',
    addWatchlist = 'addWatchlist',
    removeWatchlist = 'removeWatchlist',
}

export enum QR_LIST_TOOLBAR_OPERATION {
    details = 'details',
    analytics = 'analytics',
    preview = 'preview',
    download = 'download',
    restore = 'restore',
    deletepermanently = 'deletepermanently'
}

export enum QRCODE_VIEW_TYPE {
    default = 'grid',
    list = 'list'
}

export enum  QRCODE_OPERATION_TYPE {
    FILTER = 1,
    LIST = 2
}

export class LinkedQRCodes extends BaseModel {
    id: number;
    name: string = '';
    attributes: {} = {};
    url: string = '';
    updated: string = '';
    maintainer: string;
    constructor(json: Partial<LinkedQRCodes>) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.name = json['name'] || '';
        this.attributes = json['attributes'] || {};
        this.url = json['url'] || '';
        this.updated = json['updated'] || '';
        this.maintainer = json['maintainer'] || '';
    }
}

