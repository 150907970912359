/**
 * Created by aakash on 29/05/17.
 */

import * as moment from 'moment-timezone';
import { EddyCampaignTypes } from '../global-services/campaign.model';
import { AuthService } from '../global-services/auth.service';
import { DataPattern, EyeBallShape, EyeFrameShape, GradientType, QRCodeFrame } from 'mobstac-awesome-qr/lib/Enums';
import { QR_BACKGROUND_TYPE, QR_TEMPLATE } from 'app/qr/qr.model';
import { BEACONSTAC_ADMIN, BEACONSTAC_HOSTS } from '../app.constants';
import { PLAN_TYPES, USER_GROUPS } from '../user-account/user.model';
import { Filter, FILTER_OPERATOR_TYPE } from '../global-components/filter-products/filter.model';
import {DBC_BACKGROUND_TYPE} from '../digital-business-card/digital-business-card.model';
import {environment} from '../../environments/environment';
import {isEqual} from 'lodash'
import { Organization } from 'app/user-account/user-organization.model';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

export enum COLORS {
    BEACONSTAC_BLUE = '#2595FF',
    WHITE = '#ffffff',
    GRAY_SHADE_1 = '#5E6980'
}

export enum PRODUCT_TYPES {
    QR = 'qr',
    DBC = 'dbc',
    LINKPAGE = 'linkpage',
    FORM = 'form',
    CARDS = 'cards' // this can be used for sending product type in api's
}

export enum EXPORT_TYPE {
    CSV = 'csv',
    PDF = 'pdf',
    ALL_ORG_CSV = 'allOrgCsv',
}

export interface ProductUsageCountInterface {
    qrcodes_count: number;
    dbc_count: number;
    linkpage_count: number;
}

export const INTERCOM_WIDGET_HIDDEN_ON_ROUTES: string[] = ['/design-library', '/digital-business-cards/my-cards/add', 'digital-business-cards/onboard'];

export enum PRODUCT_STATE {
    ACTIVE = 'A',
    SLEEPING = 'S',
}

export enum RETARGETING_TYPE {
    FACEBOOK_PIXEL = 'facebook-pixel',
    GOOGLE_ADS = 'google-ads',
    LINKPAGE_FACEBOOK_PIXEL = 'linkpage-facebook-pixel',
    LINKPAGE_GOOGLE_ADS = 'linkpage-google-ads',
}

export enum LAUNCH_DARKLY_CUSTOM_METRICS {
    LINKPAGE_SELECTION = 'linkpage selection',
    WEBSITE_QR_CODE_CREATED = 'website-qr-code-created',
}

export enum Intervals {
    TODAY = 0,
    YESTERDAY = 1,
    WEEK = 7,
    MONTH = 30,
    TWOMONTH = 60,
    QUARTER = 90,
    HALFYEARLY = 180,
    CUSTOM = -1,
    LIFETIME = -2,
    TWO_WEEKS = 14,
}

export enum DATE_FORMAT {
    DATE_FORMAT_STRING = 'MMM DD, YYYY',
    CHART_INTERVAL_FORMAT = 'MMM DD, YYYY',
}

export enum QR_ATTRIBUTES {
    EYE_BALL_COLOR = 'eyeBallColor',
    EYE_FRAME_COLOR = 'eyeFrameColor',
    DARK_COLOR = 'colorDark',
    LIGHT_COLOR = 'colorLight',
    FRAME_COLOR = 'frameColor',
    FRAME_TEXT = 'frameText',
    FRAME_TEXT_COLOR = 'frameTextColor',
    DATE_FORMAT_STRING = 'MMM DD, YYYY',
    LOGO_MARGIN = 'logoMargin',
}

export enum PWA_MOBILE_NUDGE_SOURCE {
    MOBILE_OVERVIEW_PAGE = 'mobile_overview_page',
    MOBILE_CARD_LIST_PAGE = 'mobile_card_list_page'
}

export enum DEFAULT_MAP_LOCATION {
    LATITUDE = 40.756270,
    LONGITUDE = -73.978240
}

export const WALLET_PASS_SRC = {
    APPLE : `${environment.imagesEndpoint}add_wallet_helper/add_to_apple_wallet_logo.png`,
    GOOGLE : `${environment.imagesEndpoint}add_wallet_helper/add_to_google_wallet_logo.png`
}

export function getDefaultForPersonalInfo(layout: string) {
    const defaultTypography = {
        google_font_style: 'Semi Bold',
        google_font_colour: '#000000',
        google_font_size: 24,
    };

    switch (layout) {
        case '5':
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '3':
            defaultTypography.google_font_style = 'Medium';
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '7':
            defaultTypography.google_font_colour = '#266E49';
            break;
        case '8':
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '9':
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
    }
    return defaultTypography;
}

export function getDefaultForCompanyDetails(layout: string) {
    const defaultTypography = {
        google_font_style: 'Regular',
        google_font_colour: '#000000',
        google_font_size: 16,
    };

    switch (layout) {
        case '1':
            defaultTypography.google_font_size = 20;
            break;
        case '2':
            defaultTypography.google_font_size = 20;
            break;
        case '5':
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '3':
            defaultTypography.google_font_style = 'Light';
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '8':
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '9':
            defaultTypography.google_font_style = 'Light';
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '11':
            defaultTypography.google_font_size = 14;
            break;
    }
    return defaultTypography;
}

export function getDefaultForContactDetails(layout: string) {
    const defaultTypography = {
        google_font_style: 'Semi Bold',
        google_font_colour: '#000000',
        google_font_size: 14
    }

    switch (layout) {
        case '2':
            defaultTypography.google_font_style = 'Medium';
            break;
        case '3':
            defaultTypography.google_font_style = 'Regular';
            break;
        case '7':
            defaultTypography.google_font_colour = '#266E49';
            break;
        case '9':
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '11':
            defaultTypography.google_font_size = 14;
            defaultTypography.google_font_style = 'Regular';
            break;
    }
    return defaultTypography;
}

export function getDefaultForBio(layout: string) {
    const defaultTypography = {
        google_font_style: 'Regular',
        google_font_colour: '#000000',
        google_font_size: 18,
    }

    switch (layout) {
        case '2':
            defaultTypography.google_font_size = 16;
            break;
        case '9':
            defaultTypography.google_font_colour = '#FFFFFF';
            break;
        case '11':
            defaultTypography.google_font_size = 14;
            break;
    }
    return defaultTypography;
}

export function getDefaultForButton(layout: string) {
    const defaultTypography =  {
        google_font_style: 'Regular',
        google_font_colour: '#FFFFFF',
        google_font_size: 20,
    }

    switch (layout) {
        case '11':
            defaultTypography.google_font_size = 14;
            break;
        case '9':
            defaultTypography.google_font_colour = '#000000';
            break;
    }
    return defaultTypography;
}

export function getWeekDayName(i: number) {
    const days = {
        0: 'Mon',
        1: 'Tue',
        2: 'Wed',
        3: 'Thu',
        4: 'Fri',
        5: 'Sat',
        6: 'Sun',
    };
    return days[i];
}

export function getTimeofDay(i: number) {
    if (i === 0) {
        return '12am';
    } else if (i <= 11) {
        return i + 'am';
    } else if (i === 12) {
        return '12pm';
    } else {
        return i - 12 + 'pm';
    }
}

export function getHourFromTimeOfDay(time: string): number | null {
    // Regular expression to match a valid time format (1-12 followed by "am" or "pm", case-insensitive)
    const hour_am_pm_regex = /^(\d{1,2})(am|pm)$/i;
    const match = time.match(hour_am_pm_regex);

    if (!match) {
        return null;
    } // Return null if format is incorrect

    const hour = parseInt(match[1], 10);
    const period = match[2].toLowerCase();

    if (period === 'am') {
        return hour === 12 ? 0 : hour; // 12am is 0, otherwise keep the hour as is
    } else {
        return hour === 12 ? 12 : hour + 12; // 12pm is 12, otherwise add 12 to convert to 24-hour format
    }
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

export function isEncoded(uri) {
    uri = uri || '';
    return uri !== decodeURIComponent(uri);
}

export function sanitizeScriptTags(body) {
    return body.replace(/<script\b[^>]*>([\s\S]*?)<\/script>/gm, '');
}

export function pinterestDBCTag(isDBC, hashedEmail) {
    try {
        if (isDBC) {
            (e: any) => {
                if (!window.pintrk) {
                    window.pintrk = function () {
                        window.pintrk.queue.push(Array.prototype.slice.call(arguments));
                    };
                    const n = window.pintrk;
                    (n.queue = []), (n.version = '3.0');
                    const t = document.createElement('script');
                    (t.async = !0), (t.src = e);
                    const r = document.getElementsByTagName('script')[0];
                    r.parentNode.insertBefore(t, r);
                }
            };
            ('https://s.pinimg.com/ct/core.js');
            window.pintrk('load', '2613494544082', { em: hashedEmail });
            window.pintrk('page');
        }
    } catch (err) {
        console.error('Error in Pinterest Ads Plugin:', err);
    }
}

export class Utils {
    // Add utility methods here as public static

    public static FB_PIXEL_EVENTS: Array<string> = [
        'PageView',
        'ViewContent',
        'Search',
        'AddToCart',
        'AddToWishlist',
        'InitiateCheckout',
        'AddPaymentInfo',
        'Purchase',
        'Lead',
        'CompleteRegistration',
    ];

    public static ANALYTICS_EXPORT_OPTIONS = [
        { name: 'Export data as CSV', value: 'csv', isPremium: false },
        { name: 'Export graphs as PDF', value: 'pdf', isPremium: false },
    ];

    public static ANALYTICS_EXPORT_OPTIONS_V2 = [
        { name: 'Scan Data as CSV', value: EXPORT_TYPE.CSV, isPremium: false },
        { name: 'Analytics Graphs as PDF', value: EXPORT_TYPE.PDF, isPremium: false },
    ];

    public static countryDialingCodes: string[] = [
        '+880',
        '+32',
        '+226',
        '+359',
        '+387',
        '+1246',
        '+681',
        '+590',
        '+1441',
        '+673',
        '+591',
        '+973',
        '+257',
        '+229',
        '+975',
        '+1876',
        '+267',
        '+685',
        '+599',
        '+55',
        '+1242',
        '+441534',
        '+375',
        '+501',
        '+7',
        '+250',
        '+381',
        '+670',
        '+262',
        '+993',
        '+992',
        '+40',
        '+690',
        '+245',
        '+1671',
        '+502',
        '+30',
        '+240',
        '+590',
        '+81',
        '+592',
        '+441481',
        '+594',
        '+995',
        '+1473',
        '+44',
        '+241',
        '+503',
        '+224',
        '+220',
        '+299',
        '+350',
        '+233',
        '+968',
        '+216',
        '+962',
        '+385',
        '+509',
        '+36',
        '+852',
        '+504',
        '+58',
        '+1787',
        '+1939',
        '+970',
        '+680',
        '+351',
        '+47',
        '+595',
        '+964',
        '+507',
        '+689',
        '+675',
        '+51',
        '+92',
        '+63',
        '+870',
        '+48',
        '+508',
        '+260',
        '+212',
        '+372',
        '+20',
        '+27',
        '+593',
        '+39',
        '+84',
        '+677',
        '+251',
        '+252',
        '+263',
        '+966',
        '+34',
        '+291',
        '+382',
        '+373',
        '+261',
        '+590',
        '+212',
        '+377',
        '+998',
        '+95',
        '+223',
        '+853',
        '+976',
        '+692',
        '+389',
        '+230',
        '+356',
        '+265',
        '+960',
        '+596',
        '+1670',
        '+1664',
        '+222',
        '+441624',
        '+256',
        '+255',
        '+60',
        '+52',
        '+972',
        '+33',
        '+246',
        '+290',
        '+358',
        '+679',
        '+500',
        '+691',
        '+298',
        '+505',
        '+31',
        '+47',
        '+264',
        '+678',
        '+687',
        '+227',
        '+672',
        '+234',
        '+64',
        '+977',
        '+674',
        '+683',
        '+682',
        '+225',
        '+41',
        '+57',
        '+86',
        '+237',
        '+56',
        '+61',
        '+1',
        '+242',
        '+236',
        '+243',
        '+420',
        '+357',
        '+61',
        '+506',
        '+599',
        '+238',
        '+53',
        '+268',
        '+963',
        '+599',
        '+996',
        '+254',
        '+211',
        '+597',
        '+686',
        '+855',
        '+1869',
        '+269',
        '+239',
        '+421',
        '+82',
        '+386',
        '+850',
        '+965',
        '+221',
        '+378',
        '+232',
        '+248',
        '+7',
        '+1345',
        '+65',
        '+46',
        '+249',
        '+1809',
        '+1829',
        '+1767',
        '+253',
        '+45',
        '+1284',
        '+49',
        '+967',
        '+213',
        '+1',
        '+598',
        '+262',
        '+1',
        '+961',
        '+1758',
        '+856',
        '+688',
        '+886',
        '+1868',
        '+90',
        '+94',
        '+423',
        '+371',
        '+676',
        '+370',
        '+352',
        '+231',
        '+266',
        '+66',
        '+228',
        '+235',
        '+1649',
        '+218',
        '+379',
        '+1784',
        '+971',
        '+376',
        '+1268',
        '+93',
        '+1264',
        '+1340',
        '+354',
        '+98',
        '+374',
        '+355',
        '+244',
        '+672',
        '+1684',
        '+54',
        '+61',
        '+43',
        '+297',
        '+91',
        '+35818',
        '+994',
        '+353',
        '+62',
        '+380',
        '+974',
        '+258',
    ];

    private static serverTimezones = [
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Addis_Ababa',
        'Africa/Algiers',
        'Africa/Asmara',
        'Africa/Asmera',
        'Africa/Bamako',
        'Africa/Bangui',
        'Africa/Banjul',
        'Africa/Bissau',
        'Africa/Blantyre',
        'Africa/Brazzaville',
        'Africa/Bujumbura',
        'Africa/Cairo',
        'Africa/Casablanca',
        'Africa/Ceuta',
        'Africa/Conakry',
        'Africa/Dakar',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Douala',
        'Africa/El_Aaiun',
        'Africa/Freetown',
        'Africa/Gaborone',
        'Africa/Harare',
        'Africa/Johannesburg',
        'Africa/Juba',
        'Africa/Kampala',
        'Africa/Khartoum',
        'Africa/Kigali',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Africa/Lome',
        'Africa/Luanda',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Malabo',
        'Africa/Maputo',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Africa/Mogadishu',
        'Africa/Monrovia',
        'Africa/Nairobi',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Africa/Porto-Novo',
        'Africa/Sao_Tome',
        'Africa/Timbuktu',
        'Africa/Tripoli',
        'Africa/Tunis',
        'Africa/Windhoek',
        'America/Adak',
        'America/Anchorage',
        'America/Anguilla',
        'America/Antigua',
        'America/Araguaina',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Argentina/ComodRivadavia',
        'America/Argentina/Cordoba',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Argentina/Mendoza',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Aruba',
        'America/Asuncion',
        'America/Atikokan',
        'America/Atka',
        'America/Bahia',
        'America/Bahia_Banderas',
        'America/Barbados',
        'America/Belem',
        'America/Belize',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Bogota',
        'America/Boise',
        'America/Buenos_Aires',
        'America/Cambridge_Bay',
        'America/Campo_Grande',
        'America/Cancun',
        'America/Caracas',
        'America/Catamarca',
        'America/Cayenne',
        'America/Cayman',
        'America/Chicago',
        'America/Chihuahua',
        'America/Coral_Harbour',
        'America/Cordoba',
        'America/Costa_Rica',
        'America/Creston',
        'America/Cuiaba',
        'America/Curacao',
        'America/Danmarkshavn',
        'America/Dawson',
        'America/Dawson_Creek',
        'America/Denver',
        'America/Detroit',
        'America/Dominica',
        'America/Edmonton',
        'America/Eirunepe',
        'America/El_Salvador',
        'America/Ensenada',
        'America/Fort_Nelson',
        'America/Fort_Wayne',
        'America/Fortaleza',
        'America/Glace_Bay',
        'America/Godthab',
        'America/Goose_Bay',
        'America/Grand_Turk',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guatemala',
        'America/Guayaquil',
        'America/Guyana',
        'America/Halifax',
        'America/Havana',
        'America/Hermosillo',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indianapolis',
        'America/Inuvik',
        'America/Iqaluit',
        'America/Jamaica',
        'America/Jujuy',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Knox_IN',
        'America/Kralendijk',
        'America/La_Paz',
        'America/Lima',
        'America/Los_Angeles',
        'America/Louisville',
        'America/Lower_Princes',
        'America/Maceio',
        'America/Managua',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Mendoza',
        'America/Menominee',
        'America/Merida',
        'America/Metlakatla',
        'America/Mexico_City',
        'America/Miquelon',
        'America/Moncton',
        'America/Monterrey',
        'America/Montevideo',
        'America/Montreal',
        'America/Montserrat',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Nome',
        'America/Noronha',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Ojinaga',
        'America/Panama',
        'America/Pangnirtung',
        'America/Paramaribo',
        'America/Phoenix',
        'America/Port-au-Prince',
        'America/Port_of_Spain',
        'America/Porto_Acre',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Punta_Arenas',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Recife',
        'America/Regina',
        'America/Resolute',
        'America/Rio_Branco',
        'America/Rosario',
        'America/Santa_Isabel',
        'America/Santarem',
        'America/Santiago',
        'America/Santo_Domingo',
        'America/Sao_Paulo',
        'America/Scoresbysund',
        'America/Shiprock',
        'America/Sitka',
        'America/St_Barthelemy',
        'America/St_Johns',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Swift_Current',
        'America/Tegucigalpa',
        'America/Thule',
        'America/Thunder_Bay',
        'America/Tijuana',
        'America/Toronto',
        'America/Tortola',
        'America/Vancouver',
        'America/Virgin',
        'America/Whitehorse',
        'America/Winnipeg',
        'America/Yakutat',
        'America/Yellowknife',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville',
        'Antarctica/Macquarie',
        'Antarctica/Mawson',
        'Antarctica/McMurdo',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/South_Pole',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'Arctic/Longyearbyen',
        'Asia/Aden',
        'Asia/Almaty',
        'Asia/Amman',
        'Asia/Anadyr',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Ashkhabad',
        'Asia/Atyrau',
        'Asia/Baghdad',
        'Asia/Bahrain',
        'Asia/Baku',
        'Asia/Bangkok',
        'Asia/Barnaul',
        'Asia/Beirut',
        'Asia/Bishkek',
        'Asia/Brunei',
        'Asia/Calcutta',
        'Asia/Chita',
        'Asia/Choibalsan',
        'Asia/Chongqing',
        'Asia/Chungking',
        'Asia/Colombo',
        'Asia/Dacca',
        'Asia/Damascus',
        'Asia/Dhaka',
        'Asia/Dili',
        'Asia/Dubai',
        'Asia/Dushanbe',
        'Asia/Famagusta',
        'Asia/Gaza',
        'Asia/Harbin',
        'Asia/Hebron',
        'Asia/Ho_Chi_Minh',
        'Asia/Hong_Kong',
        'Asia/Hovd',
        'Asia/Irkutsk',
        'Asia/Istanbul',
        'Asia/Jakarta',
        'Asia/Jayapura',
        'Asia/Jerusalem',
        'Asia/Kabul',
        'Asia/Kamchatka',
        'Asia/Karachi',
        'Asia/Kashgar',
        'Asia/Kathmandu',
        'Asia/Katmandu',
        'Asia/Khandyga',
        'Asia/Kolkata',
        'Asia/Krasnoyarsk',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Kuwait',
        'Asia/Macao',
        'Asia/Macau',
        'Asia/Magadan',
        'Asia/Makassar',
        'Asia/Manila',
        'Asia/Muscat',
        'Asia/Nicosia',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Omsk',
        'Asia/Oral',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Pyongyang',
        'Asia/Qatar',
        'Asia/Qyzylorda',
        'Asia/Rangoon',
        'Asia/Riyadh',
        'Asia/Saigon',
        'Asia/Sakhalin',
        'Asia/Samarkand',
        'Asia/Seoul',
        'Asia/Shanghai',
        'Asia/Singapore',
        'Asia/Srednekolymsk',
        'Asia/Taipei',
        'Asia/Tashkent',
        'Asia/Tbilisi',
        'Asia/Tehran',
        'Asia/Tel_Aviv',
        'Asia/Thimbu',
        'Asia/Thimphu',
        'Asia/Tokyo',
        'Asia/Tomsk',
        'Asia/Ujung_Pandang',
        'Asia/Ulaanbaatar',
        'Asia/Ulan_Bator',
        'Asia/Urumqi',
        'Asia/Ust-Nera',
        'Asia/Vientiane',
        'Asia/Vladivostok',
        'Asia/Yakutsk',
        'Asia/Yangon',
        'Asia/Yekaterinburg',
        'Asia/Yerevan',
        'Atlantic/Azores',
        'Atlantic/Bermuda',
        'Atlantic/Canary',
        'Atlantic/Cape_Verde',
        'Atlantic/Faeroe',
        'Atlantic/Faroe',
        'Atlantic/Jan_Mayen',
        'Atlantic/Madeira',
        'Atlantic/Reykjavik',
        'Atlantic/South_Georgia',
        'Atlantic/St_Helena',
        'Atlantic/Stanley',
        'Australia/ACT',
        'Australia/Adelaide',
        'Australia/Brisbane',
        'Australia/Broken_Hill',
        'Australia/Canberra',
        'Australia/Currie',
        'Australia/Darwin',
        'Australia/Eucla',
        'Australia/Hobart',
        'Australia/LHI',
        'Australia/Lindeman',
        'Australia/Lord_Howe',
        'Australia/Melbourne',
        'Australia/NSW',
        'Australia/North',
        'Australia/Perth',
        'Australia/Queensland',
        'Australia/South',
        'Australia/Sydney',
        'Australia/Tasmania',
        'Australia/Victoria',
        'Australia/West',
        'Australia/Yancowinna',
        'Brazil/Acre',
        'Brazil/DeNoronha',
        'Brazil/East',
        'Brazil/West',
        'CET',
        'CST6CDT',
        'Canada/Atlantic',
        'Canada/Central',
        'Canada/East-Saskatchewan',
        'Canada/Eastern',
        'Canada/Mountain',
        'Canada/Newfoundland',
        'Canada/Pacific',
        'Canada/Saskatchewan',
        'Canada/Yukon',
        'Chile/Continental',
        'Chile/EasterIsland',
        'Cuba',
        'EET',
        'EST',
        'EST5EDT',
        'Egypt',
        'Eire',
        'Etc/GMT',
        'Etc/GMT+0',
        'Etc/GMT+1',
        'Etc/GMT+10',
        'Etc/GMT+11',
        'Etc/GMT+12',
        'Etc/GMT+2',
        'Etc/GMT+3',
        'Etc/GMT+4',
        'Etc/GMT+5',
        'Etc/GMT+6',
        'Etc/GMT+7',
        'Etc/GMT+8',
        'Etc/GMT+9',
        'Etc/GMT-0',
        'Etc/GMT-1',
        'Etc/GMT-10',
        'Etc/GMT-11',
        'Etc/GMT-12',
        'Etc/GMT-13',
        'Etc/GMT-14',
        'Etc/GMT-2',
        'Etc/GMT-3',
        'Etc/GMT-4',
        'Etc/GMT-5',
        'Etc/GMT-6',
        'Etc/GMT-7',
        'Etc/GMT-8',
        'Etc/GMT-9',
        'Etc/GMT0',
        'Etc/Greenwich',
        'Etc/UCT',
        'Etc/UTC',
        'Etc/Universal',
        'Etc/Zulu',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Astrakhan',
        'Europe/Athens',
        'Europe/Belfast',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Bratislava',
        'Europe/Brussels',
        'Europe/Bucharest',
        'Europe/Budapest',
        'Europe/Busingen',
        'Europe/Chisinau',
        'Europe/Copenhagen',
        'Europe/Dublin',
        'Europe/Gibraltar',
        'Europe/Guernsey',
        'Europe/Helsinki',
        'Europe/Isle_of_Man',
        'Europe/Istanbul',
        'Europe/Jersey',
        'Europe/Kaliningrad',
        'Europe/Kiev',
        'Europe/Kirov',
        'Europe/Lisbon',
        'Europe/Ljubljana',
        'Europe/London',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Europe/Malta',
        'Europe/Mariehamn',
        'Europe/Minsk',
        'Europe/Monaco',
        'Europe/Moscow',
        'Europe/Nicosia',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Podgorica',
        'Europe/Prague',
        'Europe/Riga',
        'Europe/Rome',
        'Europe/Samara',
        'Europe/San_Marino',
        'Europe/Sarajevo',
        'Europe/Saratov',
        'Europe/Simferopol',
        'Europe/Skopje',
        'Europe/Sofia',
        'Europe/Stockholm',
        'Europe/Tallinn',
        'Europe/Tirane',
        'Europe/Tiraspol',
        'Europe/Ulyanovsk',
        'Europe/Uzhgorod',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Vilnius',
        'Europe/Volgograd',
        'Europe/Warsaw',
        'Europe/Zagreb',
        'Europe/Zaporozhye',
        'Europe/Zurich',
        'GB',
        'GB-Eire',
        'GMT',
        'GMT+0',
        'GMT-0',
        'GMT0',
        'Greenwich',
        'HST',
        'Hongkong',
        'Iceland',
        'Indian/Antananarivo',
        'Indian/Chagos',
        'Indian/Christmas',
        'Indian/Cocos',
        'Indian/Comoro',
        'Indian/Kerguelen',
        'Indian/Mahe',
        'Indian/Maldives',
        'Indian/Mauritius',
        'Indian/Mayotte',
        'Indian/Reunion',
        'Iran',
        'Israel',
        'Jamaica',
        'Japan',
        'Kwajalein',
        'Libya',
        'MET',
        'MST',
        'MST7MDT',
        'Mexico/BajaNorte',
        'Mexico/BajaSur',
        'Mexico/General',
        'NZ',
        'NZ-CHAT',
        'Navajo',
        'PRC',
        'PST8PDT',
        'Pacific/Apia',
        'Pacific/Auckland',
        'Pacific/Bougainville',
        'Pacific/Chatham',
        'Pacific/Chuuk',
        'Pacific/Easter',
        'Pacific/Efate',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Fiji',
        'Pacific/Funafuti',
        'Pacific/Galapagos',
        'Pacific/Gambier',
        'Pacific/Guadalcanal',
        'Pacific/Guam',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Kiritimati',
        'Pacific/Kosrae',
        'Pacific/Kwajalein',
        'Pacific/Majuro',
        'Pacific/Marquesas',
        'Pacific/Midway',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Norfolk',
        'Pacific/Noumea',
        'Pacific/Pago_Pago',
        'Pacific/Palau',
        'Pacific/Pitcairn',
        'Pacific/Pohnpei',
        'Pacific/Ponape',
        'Pacific/Port_Moresby',
        'Pacific/Rarotonga',
        'Pacific/Saipan',
        'Pacific/Samoa',
        'Pacific/Tahiti',
        'Pacific/Tarawa',
        'Pacific/Tongatapu',
        'Pacific/Truk',
        'Pacific/Wake',
        'Pacific/Wallis',
        'Pacific/Yap',
        'Poland',
        'Portugal',
        'ROC',
        'ROK',
        'Singapore',
        'Turkey',
        'UCT',
        'US/Alaska',
        'US/Aleutian',
        'US/Arizona',
        'US/Central',
        'US/East-Indiana',
        'US/Eastern',
        'US/Hawaii',
        'US/Indiana-Starke',
        'US/Michigan',
        'US/Mountain',
        'US/Pacific',
        'US/Pacific-New',
        'US/Samoa',
        'UTC',
        'Universal',
        'W-SU',
        'WET',
        'Zulu',
    ];

    public static serverSafeTimezones = moment.tz.names().filter((t) => Utils.serverTimezones.includes(t));

    public static getCurrentTimezone(): string {
        const timezone = moment.tz.guess();
        if (Utils.serverSafeTimezones.includes(timezone)) {
            return timezone;
        } else {
            return 'UTC';
        }
    }

    public static colorOptions = [
        {'colour': '#000000', 'selected': false},
        {'colour': '#4C5260', 'selected': false},
        {'colour': '#ED2A2A', 'selected': false},
        {'colour': '#E03653', 'selected': false},
        {'colour': '#A8273E', 'selected': false},
        {'colour': '#EB567F', 'selected': false},
        {'colour': '#9E3572', 'selected': false},
        {'colour': '#D13BC4', 'selected': false},
        {'colour': '#942FA5', 'selected': false},
        {'colour': '#F19FC3', 'selected': false},
        {'colour': '#AF8DF2', 'selected': false},
        {'colour': '#A357D7', 'selected': false},
        {'colour': '#6733B9', 'selected': false},
        {'colour': '#6D2BEB', 'selected': false},
        {'colour': '#183CD4', 'selected': false},
        {'colour': '#102D9F', 'selected': false},
        {'colour': '#3578F6', 'selected': false},
        {'colour': '#5DB1F9', 'selected': false},
        {'colour': '#67D9DA', 'selected': false},
        {'colour': '#51AAAC', 'selected': false},
        {'colour': '#5BBC81', 'selected': false},
        {'colour': '#499849', 'selected': false},
        {'colour': '#145134', 'selected': false},
        {'colour': '#FA9A2D', 'selected': false},
        {'colour': '#ED7531', 'selected': false},
        {'colour': '#744D41', 'selected': false}
    ];

    public static qrLogos = [
        'https://static.beaconstac.com/assets/img/qr-code-logos/app-store.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/calender.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/email.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/facebook.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/gmail.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/google-bussiness.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/instagram.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/linkedin.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/mp3.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/pdf.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/phone-call.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/pintrest.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/play-store.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/ratings.png',
        'https://static.beaconstac.com/assets/img/qr-code-logos/twitter.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/youtube.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/covid-logo-1.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/covid-logo-2.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/covid-logo-3.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/covid-logo-4.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/covid-logo-5.svg',
        'https://static.beaconstac.com/assets/img/qr-code-logos/covid-logo-6.svg',
    ];

    public static qrTemplates = [
        // {
        //   'url': 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/templates/template-1.svg',
        //   'name': 'template-1',
        //   'attributes': {
        //     'backgroundColor': '',
        //     'backgroundImage': '',
        //     'eyeFrameShape': EyeFrameShape.ROUNDED,
        //     'eyeBallShape': EyeBallShape.LEFT_LEAF,
        //     'dataPattern': DataPattern.CIRCLE,
        //     'colorDark': '#1b09eb',
        //     'colorLight': '#7d06d7',
        //     'gradientType': GradientType.LINEAR,
        //     'frameStyle': QRCodeFrame.NONE,
        //     'frameColor': '#000000',
        //     'eyeBallColor': '',
        //     'eyeFrameColor': ''
        //
        //   },
        //   'backGroundType': QR_BACKGROUND_TYPE.None
        // },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/templates/covid-template.svg',
            name: 'template-1',
            attributes: {
                backgroundColor: '#ffffff',
                backgroundImage: '',
                eyeFrameShape: EyeFrameShape.ROUNDED,
                eyeBallShape: EyeBallShape.CIRCLE,
                dataPattern: DataPattern.CIRCLE,
                colorDark: '#5ED6EE',
                colorLight: '#29507F',
                gradientType: GradientType.RADIAL,
                frameStyle: QRCodeFrame.NONE,
                frameColor: '#000000',
                eyeBallColor: '#29507F',
                eyeFrameColor: '#29507F',
                logoImage: 'https://static.beaconstac.com/assets/img/qr-code-logos/covid-logo-1.svg',
            },
            backGroundType: QR_BACKGROUND_TYPE.Color,
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/templates/template-2.svg',
            name: 'template-2',
            attributes: {
                backgroundColor: '#ffffff',
                backgroundImage: '',
                eyeFrameShape: EyeFrameShape.ROUNDED,
                eyeBallShape: EyeBallShape.LEFT_DIAMOND,
                dataPattern: DataPattern.SQUARE,
                colorDark: '#000000',
                colorLight: '#ffffff',
                gradientType: GradientType.NONE,
                frameStyle: QRCodeFrame.NONE,
                frameColor: '#000000',
                eyeBallColor: '#000000',
                eyeFrameColor: '#000000',
                logoImage: '',
            },
            backGroundType: QR_BACKGROUND_TYPE.Color,
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/templates/template-3.svg',
            name: 'template-3',
            attributes: {
                backgroundColor: '#f7f7f7',
                backgroundImage: '',
                eyeFrameShape: EyeFrameShape.LEFT_LEAF,
                eyeBallShape: EyeBallShape.LEFT_LEAF,
                dataPattern: DataPattern.LEFT_DIAMOND,
                colorDark: '#32733f',
                colorLight: '#ffffff',
                gradientType: GradientType.NONE,
                frameStyle: QRCodeFrame.NONE,
                frameColor: '#000000',
                eyeBallColor: '#32733f',
                eyeFrameColor: '#32733f',
                logoImage: '',
            },
            backGroundType: QR_BACKGROUND_TYPE.Color,
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/templates/template-4.svg',
            name: 'template-4',
            attributes: {
                backgroundColor: '#f7f7f7',
                backgroundImage: '',
                eyeFrameShape: EyeFrameShape.CIRCLE,
                eyeBallShape: EyeBallShape.CIRCLE,
                dataPattern: DataPattern.SQUARE,
                colorDark: '#d37a7c',
                colorLight: '#ffffff',
                gradientType: GradientType.NONE,
                frameStyle: QRCodeFrame.NONE,
                frameColor: '#000000',
                eyeBallColor: '#d37a7c',
                eyeFrameColor: '#616789',
                logoImage: '',
            },
            backGroundType: QR_BACKGROUND_TYPE.Color,
        },
    ];

    public static qrEyeBalls = [
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-balls/square.svg',
            name: 'square',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-balls/rounded.svg',
            name: 'rounded',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-balls/circle.svg',
            name: 'circle',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-balls/left-diamond.svg',
            name: 'left-diamond',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-balls/right-diamond.svg',
            name: 'right-diamond',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-balls/left-leaf.svg',
            name: 'left-leaf',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-balls/right-leaf.svg',
            name: 'right-leaf',
        },
    ];

    public static qrEyeFrames = [
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-frames/square.svg',
            name: 'square',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-frames/rounded.svg',
            name: 'rounded',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-frames/circle.svg',
            name: 'circle',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-frames/left-leaf.svg',
            name: 'left-leaf',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/eye-frames/right-leaf.svg',
            name: 'right-leaf',
        },
    ];

    public static qrDataPatterns = [
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/square.svg',
            name: 'square',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/circle.svg',
            name: 'circle',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/kite.svg',
            name: 'kite',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/left-diamond.svg',
            name: 'left-diamond',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/right-diamond.svg',
            name: 'right-diamond',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/thin-square.svg',
            name: 'thin-square',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/smooth-round.svg',
            name: 'smooth-round',
        },
        {
            url: 'https://static.beaconstac.com/assets/img/mobstac-awesome-qr/data-patterns/smooth-sharp.svg',
            name: 'smooth-sharp',
        },
    ];

    public static qrCodeFrames = [
        {
            url: 'assets/img/frames/banner-bottom.svg',
            name: 'banner-bottom',
        },
        {
            url: 'assets/img/frames/balloon-bottom.svg',
            name: 'balloon-bottom',
        },
        {
            url: 'assets/img/frames/box-bottom.svg',
            name: 'box-bottom',
        },
        {
            url: 'assets/img/frames/banner-top.svg',
            name: 'banner-top',
        },
        {
            url: 'assets/img/frames/box-top.svg',
            name: 'box-top',
        },
        {
            url: 'assets/img/frames/balloon-top.svg',
            name: 'balloon-top',
        },
        {
            url: 'assets/img/frames/circular.svg',
            name: 'circular',
        },
        {
            url: 'assets/img/frames/text-only.svg',
            name: 'text-only',
        },
        {
            url: 'assets/img/frames/focus.svg',
            name: 'focus',
        },
    ];

    public static qrPrintableTemplates = [
        {
            url: '../../../assets/img/printable_templates/template_4by6.png',
            name: QR_TEMPLATE.PLAIN_TEMPLATE,
        },
    ];

    public static countries = [
        {name: 'Afghanistan', code: 'AF'},
        {name: 'Åland Islands', code: 'AX'},
        {name: 'Albania', code: 'AL'},
        {name: 'Algeria', code: 'DZ'},
        {name: 'American Samoa', code: 'AS'},
        {name: 'AndorrA', code: 'AD'},
        {name: 'Angola', code: 'AO'},
        {name: 'Anguilla', code: 'AI'},
        {name: 'Antarctica', code: 'AQ'},
        {name: 'Antigua and Barbuda', code: 'AG'},
        {name: 'Argentina', code: 'AR'},
        {name: 'Armenia', code: 'AM'},
        {name: 'Aruba', code: 'AW'},
        {name: 'Australia', code: 'AU'},
        {name: 'Austria', code: 'AT'},
        {name: 'Azerbaijan', code: 'AZ'},
        {name: 'Bahamas', code: 'BS'},
        {name: 'Bahrain', code: 'BH'},
        {name: 'Bangladesh', code: 'BD'},
        {name: 'Barbados', code: 'BB'},
        {name: 'Belarus', code: 'BY'},
        {name: 'Belgium', code: 'BE'},
        {name: 'Belize', code: 'BZ'},
        {name: 'Benin', code: 'BJ'},
        {name: 'Bermuda', code: 'BM'},
        {name: 'Bhutan', code: 'BT'},
        {name: 'Bolivia', code: 'BO'},
        {name: 'Bosnia and Herzegovina', code: 'BA'},
        {name: 'Botswana', code: 'BW'},
        {name: 'Bouvet Island', code: 'BV'},
        {name: 'Brazil', code: 'BR'},
        {name: 'British Indian Ocean Territory', code: 'IO'},
        {name: 'Brunei Darussalam', code: 'BN'},
        {name: 'Bulgaria', code: 'BG'},
        {name: 'Burkina Faso', code: 'BF'},
        {name: 'Burundi', code: 'BI'},
        {name: 'Cambodia', code: 'KH'},
        {name: 'Cameroon', code: 'CM'},
        {name: 'Canada', code: 'CA'},
        {name: 'Cape Verde', code: 'CV'},
        {name: 'Cayman Islands', code: 'KY'},
        {name: 'Central African Republic', code: 'CF'},
        {name: 'Chad', code: 'TD'},
        {name: 'Chile', code: 'CL'},
        {name: 'China', code: 'CN'},
        {name: 'Christmas Island', code: 'CX'},
        {name: 'Cocos (Keeling) Islands', code: 'CC'},
        {name: 'Colombia', code: 'CO'},
        {name: 'Comoros', code: 'KM'},
        {name: 'Congo', code: 'CG'},
        {name: 'Congo, The Democratic Republic of the', code: 'CD'},
        {name: 'Cook Islands', code: 'CK'},
        {name: 'Costa Rica', code: 'CR'},
        {name: 'Cote D\'Ivoire', code: 'CI'},
        {name: 'Croatia', code: 'HR'},
        {name: 'Cuba', code: 'CU'},
        {name: 'Cyprus', code: 'CY'},
        {name: 'Czech Republic', code: 'CZ'},
        {name: 'Denmark', code: 'DK'},
        {name: 'Djibouti', code: 'DJ'},
        {name: 'Dominica', code: 'DM'},
        {name: 'Dominican Republic', code: 'DO'},
        {name: 'Ecuador', code: 'EC'},
        {name: 'Egypt', code: 'EG'},
        {name: 'El Salvador', code: 'SV'},
        {name: 'Equatorial Guinea', code: 'GQ'},
        {name: 'Eritrea', code: 'ER'},
        {name: 'Estonia', code: 'EE'},
        {name: 'Ethiopia', code: 'ET'},
        {name: 'Falkland Islands (Malvinas)', code: 'FK'},
        {name: 'Faroe Islands', code: 'FO'},
        {name: 'Fiji', code: 'FJ'},
        {name: 'Finland', code: 'FI'},
        {name: 'France', code: 'FR'},
        {name: 'French Guiana', code: 'GF'},
        {name: 'French Polynesia', code: 'PF'},
        {name: 'French Southern Territories', code: 'TF'},
        {name: 'Gabon', code: 'GA'},
        {name: 'Gambia', code: 'GM'},
        {name: 'Georgia', code: 'GE'},
        {name: 'Germany', code: 'DE'},
        {name: 'Ghana', code: 'GH'},
        {name: 'Gibraltar', code: 'GI'},
        {name: 'Greece', code: 'GR'},
        {name: 'Greenland', code: 'GL'},
        {name: 'Grenada', code: 'GD'},
        {name: 'Guadeloupe', code: 'GP'},
        {name: 'Guam', code: 'GU'},
        {name: 'Guatemala', code: 'GT'},
        {name: 'Guernsey', code: 'GG'},
        {name: 'Guinea', code: 'GN'},
        {name: 'Guinea-Bissau', code: 'GW'},
        {name: 'Guyana', code: 'GY'},
        {name: 'Haiti', code: 'HT'},
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
        {name: 'Holy See (Vatican City State)', code: 'VA'},
        {name: 'Honduras', code: 'HN'},
        {name: 'Hong Kong', code: 'HK'},
        {name: 'Hungary', code: 'HU'},
        {name: 'Iceland', code: 'IS'},
        {name: 'India', code: 'IN'},
        {name: 'Indonesia', code: 'ID'},
        {name: 'Iran, Islamic Republic Of', code: 'IR'},
        {name: 'Iraq', code: 'IQ'},
        {name: 'Ireland', code: 'IE'},
        {name: 'Isle of Man', code: 'IM'},
        {name: 'Israel', code: 'IL'},
        {name: 'Italy', code: 'IT'},
        {name: 'Jamaica', code: 'JM'},
        {name: 'Japan', code: 'JP'},
        {name: 'Jersey', code: 'JE'},
        {name: 'Jordan', code: 'JO'},
        {name: 'Kazakhstan', code: 'KZ'},
        {name: 'Kenya', code: 'KE'},
        {name: 'Kiribati', code: 'KI'},
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
        {name: 'Korea, Republic of', code: 'KR'},
        {name: 'Kuwait', code: 'KW'},
        {name: 'Kyrgyzstan', code: 'KG'},
        {name: 'Lao People\'S Democratic Republic', code: 'LA'},
        {name: 'Latvia', code: 'LV'},
        {name: 'Lebanon', code: 'LB'},
        {name: 'Lesotho', code: 'LS'},
        {name: 'Liberia', code: 'LR'},
        {name: 'Libyan Arab Jamahiriya', code: 'LY'},
        {name: 'Liechtenstein', code: 'LI'},
        {name: 'Lithuania', code: 'LT'},
        {name: 'Luxembourg', code: 'LU'},
        {name: 'Macao', code: 'MO'},
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
        {name: 'Madagascar', code: 'MG'},
        {name: 'Malawi', code: 'MW'},
        {name: 'Malaysia', code: 'MY'},
        {name: 'Maldives', code: 'MV'},
        {name: 'Mali', code: 'ML'},
        {name: 'Malta', code: 'MT'},
        {name: 'Marshall Islands', code: 'MH'},
        {name: 'Martinique', code: 'MQ'},
        {name: 'Mauritania', code: 'MR'},
        {name: 'Mauritius', code: 'MU'},
        {name: 'Mayotte', code: 'YT'},
        {name: 'Mexico', code: 'MX'},
        {name: 'Micronesia, Federated States of', code: 'FM'},
        {name: 'Moldova, Republic of', code: 'MD'},
        {name: 'Monaco', code: 'MC'},
        {name: 'Mongolia', code: 'MN'},
        {name: 'Montserrat', code: 'MS'},
        {name: 'Morocco', code: 'MA'},
        {name: 'Mozambique', code: 'MZ'},
        {name: 'Myanmar', code: 'MM'},
        {name: 'Namibia', code: 'NA'},
        {name: 'Nauru', code: 'NR'},
        {name: 'Nepal', code: 'NP'},
        {name: 'Netherlands', code: 'NL'},
        {name: 'Netherlands Antilles', code: 'AN'},
        {name: 'New Caledonia', code: 'NC'},
        {name: 'New Zealand', code: 'NZ'},
        {name: 'Nicaragua', code: 'NI'},
        {name: 'Niger', code: 'NE'},
        {name: 'Nigeria', code: 'NG'},
        {name: 'Niue', code: 'NU'},
        {name: 'Norfolk Island', code: 'NF'},
        {name: 'Northern Mariana Islands', code: 'MP'},
        {name: 'Norway', code: 'NO'},
        {name: 'Oman', code: 'OM'},
        {name: 'Pakistan', code: 'PK'},
        {name: 'Palau', code: 'PW'},
        {name: 'Palestinian Territory, Occupied', code: 'PS'},
        {name: 'Panama', code: 'PA'},
        {name: 'Papua New Guinea', code: 'PG'},
        {name: 'Paraguay', code: 'PY'},
        {name: 'Peru', code: 'PE'},
        {name: 'Philippines', code: 'PH'},
        {name: 'Pitcairn', code: 'PN'},
        {name: 'Poland', code: 'PL'},
        {name: 'Portugal', code: 'PT'},
        {name: 'Puerto Rico', code: 'PR'},
        {name: 'Qatar', code: 'QA'},
        {name: 'Reunion', code: 'RE'},
        {name: 'Romania', code: 'RO'},
        {name: 'Russian Federation', code: 'RU'},
        {name: 'Rwanda', code: 'RW'},
        {name: 'Saint Helena', code: 'SH'},
        {name: 'Saint Kitts and Nevis', code: 'KN'},
        {name: 'Saint Lucia', code: 'LC'},
        {name: 'Saint Pierre and Miquelon', code: 'PM'},
        {name: 'Saint Vincent and the Grenadines', code: 'VC'},
        {name: 'Samoa', code: 'WS'},
        {name: 'San Marino', code: 'SM'},
        {name: 'Sao Tome and Principe', code: 'ST'},
        {name: 'Saudi Arabia', code: 'SA'},
        {name: 'Senegal', code: 'SN'},
        {name: 'Serbia and Montenegro', code: 'CS'},
        {name: 'Seychelles', code: 'SC'},
        {name: 'Sierra Leone', code: 'SL'},
        {name: 'Singapore', code: 'SG'},
        {name: 'Slovakia', code: 'SK'},
        {name: 'Slovenia', code: 'SI'},
        {name: 'Solomon Islands', code: 'SB'},
        {name: 'Somalia', code: 'SO'},
        {name: 'South Africa', code: 'ZA'},
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
        {name: 'Spain', code: 'ES'},
        {name: 'Sri Lanka', code: 'LK'},
        {name: 'Sudan', code: 'SD'},
        {name: 'Suriname', code: 'SR'},
        {name: 'Svalbard and Jan Mayen', code: 'SJ'},
        {name: 'Swaziland', code: 'SZ'},
        {name: 'Sweden', code: 'SE'},
        {name: 'Switzerland', code: 'CH'},
        {name: 'Syrian Arab Republic', code: 'SY'},
        {name: 'Taiwan, Province of China', code: 'TW'},
        {name: 'Tajikistan', code: 'TJ'},
        {name: 'Tanzania, United Republic of', code: 'TZ'},
        {name: 'Thailand', code: 'TH'},
        {name: 'Timor-Leste', code: 'TL'},
        {name: 'Togo', code: 'TG'},
        {name: 'Tokelau', code: 'TK'},
        {name: 'Tonga', code: 'TO'},
        {name: 'Trinidad and Tobago', code: 'TT'},
        {name: 'Tunisia', code: 'TN'},
        {name: 'Turkey', code: 'TR'},
        {name: 'Turkmenistan', code: 'TM'},
        {name: 'Turks and Caicos Islands', code: 'TC'},
        {name: 'Tuvalu', code: 'TV'},
        {name: 'Uganda', code: 'UG'},
        {name: 'Ukraine', code: 'UA'},
        {name: 'United Arab Emirates', code: 'AE'},
        {name: 'United Kingdom', code: 'GB'},
        {name: 'United States', code: 'US'},
        {name: 'United States Minor Outlying Islands', code: 'UM'},
        {name: 'Uruguay', code: 'UY'},
        {name: 'Uzbekistan', code: 'UZ'},
        {name: 'Vanuatu', code: 'VU'},
        {name: 'Venezuela', code: 'VE'},
        {name: 'Viet Nam', code: 'VN'},
        {name: 'Virgin Islands, British', code: 'VG'},
        {name: 'Virgin Islands, U.S.', code: 'VI'},
        {name: 'Wallis and Futuna', code: 'WF'},
        {name: 'Western Sahara', code: 'EH'},
        {name: 'Yemen', code: 'YE'},
        {name: 'Zambia', code: 'ZM'},
        {name: 'Zimbabwe', code: 'ZW'},
    ];

    public static contentTypes = [
        'image/png',
        'image/jpeg',
        'image/heic',
        'image/gif',
        'image/bmp',
        'image/svg+xml',
        'audio/mp3',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'audio/mpeg',
        'audio/x-m4a',
        'image/webp',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/x-ms-bmp',
        'application/vnd.ms-excel',
        'image/x-icon',
        'image/tiff',
        'audio/vnd.dlna.adts',
        'application/msword',
        'audio/wav',
        'video/mp4',
        'audio/mp4',
        'audio/ogg',
        'text/csv',
        'text/calendar',
        'video/MP2T',
        'video/3gpp',
        'video/quicktime',
        'video/x-m4v',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    public static internationalizationSupportedLanguage = [
        'af',
        'am',
        'ar',
        'az',
        'be',
        'bg',
        'bn',
        'bs',
        'ca',
        'ceb',
        'co',
        'cs',
        'cy',
        'da',
        'de',
        'el',
        'en',
        'eo',
        'es',
        'et',
        'eu',
        'fa',
        'fi',
        'fr',
        'fy',
        'ga',
        'gd',
        'gl',
        'gu',
        'ha',
        'haw',
        'he',
        'hi',
        'hmn',
        'hr',
        'ht',
        'hu',
        'hy',
        'id',
        'ig',
        'is',
        'it',
        'iw',
        'ja',
        'jw',
        'ka',
        'kk',
        'km',
        'kn',
        'ko',
        'ku',
        'ky',
        'la',
        'lb',
        'lo',
        'lt',
        'lv',
        'mg',
        'mi',
        'mk',
        'ml',
        'mn',
        'mr',
        'ms',
        'mt',
        'my',
        'ne',
        'nl',
        'no',
        'ny',
        'or',
        'pa',
        'pl',
        'ps',
        'pt',
        'ro',
        'ru',
        'rw',
        'sd',
        'si',
        'sk',
        'sl',
        'sm',
        'sn',
        'so',
        'sq',
        'sr',
        'st',
        'su',
        'sv',
        'sw',
        'ta',
        'te',
        'tg',
        'th',
        'tk',
        'tl',
        'tr',
        'tt',
        'ug',
        'uk',
        'ur',
        'uz',
        'vi',
        'xh',
        'yi',
        'yo',
        'zh',
        'zh-CN',
        'zh-TW',
        'zu',
    ];

    public static urlRegex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,63}\b([-a-zA-Z0-9()!@:%_\+.~#?,&\/\/=]*)?(\/.*)?$/i;
    // stringUrlRegex used in vCard and DBCs
    public static stringUrlRegex =
        /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()!@:%_\+.~#?,&\/\/=]*)?(\/.*)?$/i;
    public static alphaNumericRegex = /^[a-zA-Z0-9-]+$/;
    public static emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    public static ga3Regex = /^[Uu][Aa]-[0-9]{4,9}-[0-9]{1,4}$/i;
    public static ga4Regex = /^[Gg]-[a-zA-z0-9]+$/i;
    public static phoneRegex = /^[+]?[(]?([0-9]{1,4})?[)]?[-\s.0-9]{5,15}$/;
    public static phoneRegexWithExtension = /^\+?(?:\d{1,3})?(?:[-.\s]?)(?:\(?\d{1,4}\)?)(?:[-.\s]?)(\d{1,4})(?:[-.\s]?)(\d{1,4})(?:[-.\s]?)(?:\d{1,9})?(?:\s?(?:,|\.)?\s?(?:[eE]xt|[xX])?\s?\d{1,5})?$/;
    public static deepUrlRegex = /(?:([A-Za-z][A-Za-z0-9+.-]*):)(?:\/\/([^/?#]*))?([^?#]*)(?:\?([^#]*))?(?:#(.*))?/i;
    public static hasURLRegex = /(?:https?:\/\/)?(?:www\.)?(?:[\w-]+\.)+[\w-]+(?:\/\S*)?/i;
    public static filterScanCountRegex = /^(?!\d*$).+/;

    public static available_fonts = [
        {name: 'Karla', value: 'Karla'},
        {name: 'Montserrat', value: 'Montserrat'},
        {name: 'Lato', value: 'Lato'},
        {name: 'Sniglet', value: 'Sniglet'},
        {name: 'Dancing Script', value: 'Dancing Script'},
        {name: 'Georgia', value: 'Georgia'},
        {name: 'Acme', value: 'Acme'},
        {name: 'Handlee', value: 'Handlee'},
        {name: 'Arvo', value: 'Arvo'},
        {name: 'Raleway', value: 'Raleway'},
        {name: 'Lekton', value: 'Lekton'},
        {name: 'Oswald', value: 'Oswald'},
        {name: 'Bangers', value: 'Bangers'},
        {name: 'Crete Round', value: 'Crete Round'},
        {name: 'Roboto', value: 'Roboto'},
        {name: 'Arial', value: 'Arial'},
        {name: 'Hammersmith One', value: 'Hammersmith One'},
        {name: 'Open Sans', value: 'Open Sans'},
        {name: 'Playfair Display', value: 'Playfair Display'},
        {name: 'PT Sans', value: 'PT Sans'},
        {name: 'Poppins', value: 'Poppins'},
        {name: 'Ubuntu', value: 'Ubuntu'},
        {name: 'Source Sans Pro', value: 'Source Sans Pro'},
    ];
    public static new_available_fonts = [
        {name: 'ABeeZee', value: 'ABeeZee'},
        {name: 'Abel', value: 'Abel'},
        {name: 'Abhaya Libre', value: 'Abhaya Libre'},
        {name: 'Abril Fatface', value: 'Abril Fatface'},
        {name: 'Aclonica', value: 'Aclonica'},
        {name: 'Acme', value: 'Acme'},
        {name: 'Actor', value: 'Actor'},
        {name: 'Adamina', value: 'Adamina'},
        {name: 'Advent Pro', value: 'Advent Pro'},
        {name: 'Aguafina Script', value: 'Aguafina Script'},
        {name: 'Akaya Kanadaka', value: 'Akaya Kanadaka'},
        {name: 'Akaya Telivigala', value: 'Akaya Telivigala'},
        {name: 'Akronim', value: 'Akronim'},
        {name: 'Akshar', value: 'Akshar'},
        {name: 'Aladin', value: 'Aladin'},
        {name: 'Alata', value: 'Alata'},
        {name: 'Alatsi', value: 'Alatsi'},
        {name: 'Aldrich', value: 'Aldrich'},
        {name: 'Alef', value: 'Alef'},
        {name: 'Alegreya', value: 'Alegreya'},
        {name: 'Alegreya SC', value: 'Alegreya SC'},
        {name: 'Alegreya Sans', value: 'Alegreya Sans'},
        {name: 'Alegreya Sans SC', value: 'Alegreya Sans SC'},
        {name: 'Aleo', value: 'Aleo'},
        {name: 'Alex Brush', value: 'Alex Brush'},
        {name: 'Alfa Slab One', value: 'Alfa Slab One'},
        {name: 'Alice', value: 'Alice'},
        {name: 'Alike', value: 'Alike'},
        {name: 'Alike Angular', value: 'Alike Angular'},
        {name: 'Allan', value: 'Allan'},
        {name: 'Allerta', value: 'Allerta'},
        {name: 'Allerta Stencil', value: 'Allerta Stencil'},
        {name: 'Allison', value: 'Allison'},
        {name: 'Allura', value: 'Allura'},
        {name: 'Almarai', value: 'Almarai'},
        {name: 'Almendra', value: 'Almendra'},
        {name: 'Almendra Display', value: 'Almendra Display'},
        {name: 'Almendra SC', value: 'Almendra SC'},
        {name: 'Alumni Sans', value: 'Alumni Sans'},
        {name: 'Alumni Sans Inline One', value: 'Alumni Sans Inline One'},
        {name: 'Amarante', value: 'Amarante'},
        {name: 'Amaranth', value: 'Amaranth'},
        {name: 'Amatic SC', value: 'Amatic SC'},
        {name: 'Amethysta', value: 'Amethysta'},
        {name: 'Amiko', value: 'Amiko'},
        {name: 'Amiri', value: 'Amiri'},
        {name: 'Amita', value: 'Amita'},
        {name: 'Anaheim', value: 'Anaheim'},
        {name: 'Andada Pro', value: 'Andada Pro'},
        {name: 'Andika', value: 'Andika'},
        {name: 'Andika New Basic', value: 'Andika New Basic'},
        {name: 'Anek Bangla', value: 'Anek Bangla'},
        {name: 'Anek Devanagari', value: 'Anek Devanagari'},
        {name: 'Anek Gujarati', value: 'Anek Gujarati'},
        {name: 'Anek Gurmukhi', value: 'Anek Gurmukhi'},
        {name: 'Anek Kannada', value: 'Anek Kannada'},
        {name: 'Anek Latin', value: 'Anek Latin'},
        {name: 'Anek Malayalam', value: 'Anek Malayalam'},
        {name: 'Anek Odia', value: 'Anek Odia'},
        {name: 'Anek Tamil', value: 'Anek Tamil'},
        {name: 'Anek Telugu', value: 'Anek Telugu'},
        {name: 'Angkor', value: 'Angkor'},
        {name: 'Annie Use Your Telescope', value: 'Annie Use Your Telescope'},
        {name: 'Anonymous Pro', value: 'Anonymous Pro'},
        {name: 'Antic', value: 'Antic'},
        {name: 'Antic Didone', value: 'Antic Didone'},
        {name: 'Antic Slab', value: 'Antic Slab'},
        {name: 'Anton', value: 'Anton'},
        {name: 'Antonio', value: 'Antonio'},
        {name: 'Anybody', value: 'Anybody'},
        {name: 'Arapey', value: 'Arapey'},
        {name: 'Arbutus', value: 'Arbutus'},
        {name: 'Arbutus Slab', value: 'Arbutus Slab'},
        {name: 'Architects Daughter', value: 'Architects Daughter'},
        {name: 'Archivo', value: 'Archivo'},
        {name: 'Archivo Black', value: 'Archivo Black'},
        {name: 'Archivo Narrow', value: 'Archivo Narrow'},
        {name: 'Are You Serious', value: 'Are You Serious'},
        {name: 'Aref Ruqaa', value: 'Aref Ruqaa'},
        {name: 'Arial', value: 'Arial'},
        {name: 'Arima Madurai', value: 'Arima Madurai'},
        {name: 'Arimo', value: 'Arimo'},
        {name: 'Arizonia', value: 'Arizonia'},
        {name: 'Armata', value: 'Armata'},
        {name: 'Arsenal', value: 'Arsenal'},
        {name: 'Artifika', value: 'Artifika'},
        {name: 'Arvo', value: 'Arvo'},
        {name: 'Arya', value: 'Arya'},
        {name: 'Asap', value: 'Asap'},
        {name: 'Asap Condensed', value: 'Asap Condensed'},
        {name: 'Asar', value: 'Asar'},
        {name: 'Asset', value: 'Asset'},
        {name: 'Assistant', value: 'Assistant'},
        {name: 'Astloch', value: 'Astloch'},
        {name: 'Asul', value: 'Asul'},
        {name: 'Athiti', value: 'Athiti'},
        {name: 'Atkinson Hyperlegible', value: 'Atkinson Hyperlegible'},
        {name: 'Atma', value: 'Atma'},
        {name: 'Atomic Age', value: 'Atomic Age'},
        {name: 'Aubrey', value: 'Aubrey'},
        {name: 'Audiowide', value: 'Audiowide'},
        {name: 'Autour One', value: 'Autour One'},
        {name: 'Average', value: 'Average'},
        {name: 'Average Sans', value: 'Average Sans'},
        {name: 'Averia Gruesa Libre', value: 'Averia Gruesa Libre'},
        {name: 'Averia Libre', value: 'Averia Libre'},
        {name: 'Averia Sans Libre', value: 'Averia Sans Libre'},
        {name: 'Averia Serif Libre', value: 'Averia Serif Libre'},
        {name: 'Azeret Mono', value: 'Azeret Mono'},
        {name: 'B612', value: 'B612'},
        {name: 'B612 Mono', value: 'B612 Mono'},
        {name: 'BIZ UDGothic', value: 'BIZ UDGothic'},
        {name: 'BIZ UDMincho', value: 'BIZ UDMincho'},
        {name: 'BIZ UDPGothic', value: 'BIZ UDPGothic'},
        {name: 'BIZ UDPMincho', value: 'BIZ UDPMincho'},
        {name: 'Babylonica', value: 'Babylonica'},
        {name: 'Bad Script', value: 'Bad Script'},
        {name: 'Bahiana', value: 'Bahiana'},
        {name: 'Bahianita', value: 'Bahianita'},
        {name: 'Bai Jamjuree', value: 'Bai Jamjuree'},
        {name: 'Bakbak One', value: 'Bakbak One'},
        {name: 'Ballet', value: 'Ballet'},
        {name: 'Baloo 2', value: 'Baloo 2'},
        {name: 'Baloo Bhai 2', value: 'Baloo Bhai 2'},
        {name: 'Baloo Bhaijaan 2', value: 'Baloo Bhaijaan 2'},
        {name: 'Baloo Bhaina 2', value: 'Baloo Bhaina 2'},
        {name: 'Baloo Chettan 2', value: 'Baloo Chettan 2'},
        {name: 'Baloo Da 2', value: 'Baloo Da 2'},
        {name: 'Baloo Paaji 2', value: 'Baloo Paaji 2'},
        {name: 'Baloo Tamma 2', value: 'Baloo Tamma 2'},
        {name: 'Baloo Tammudu 2', value: 'Baloo Tammudu 2'},
        {name: 'Baloo Thambi 2', value: 'Baloo Thambi 2'},
        {name: 'Balsamiq Sans', value: 'Balsamiq Sans'},
        {name: 'Balthazar', value: 'Balthazar'},
        {name: 'Bangers', value: 'Bangers'},
        {name: 'Barlow', value: 'Barlow'},
        {name: 'Barlow Condensed', value: 'Barlow Condensed'},
        {name: 'Barlow Semi Condensed', value: 'Barlow Semi Condensed'},
        {name: 'Barriecito', value: 'Barriecito'},
        {name: 'Barrio', value: 'Barrio'},
        {name: 'Basic', value: 'Basic'},
        {name: 'Baskervville', value: 'Baskervville'},
        {name: 'Battambang', value: 'Battambang'},
        {name: 'Baumans', value: 'Baumans'},
        {name: 'Bayon', value: 'Bayon'},
        {name: 'Be Vietnam Pro', value: 'Be Vietnam Pro'},
        {name: 'Beau Rivage', value: 'Beau Rivage'},
        {name: 'Bebas Neue', value: 'Bebas Neue'},
        {name: 'Belgrano', value: 'Belgrano'},
        {name: 'Bellefair', value: 'Bellefair'},
        {name: 'Belleza', value: 'Belleza'},
        {name: 'Bellota', value: 'Bellota'},
        {name: 'Bellota Text', value: 'Bellota Text'},
        {name: 'BenchNine', value: 'BenchNine'},
        {name: 'Benne', value: 'Benne'},
        {name: 'Bentham', value: 'Bentham'},
        {name: 'Berkshire Swash', value: 'Berkshire Swash'},
        {name: 'Besley', value: 'Besley'},
        {name: 'Beth Ellen', value: 'Beth Ellen'},
        {name: 'Bevan', value: 'Bevan'},
        {name: 'BhuTuka Expanded One', value: 'BhuTuka Expanded One'},
        {name: 'Big Shoulders Display', value: 'Big Shoulders Display'},
        {name: 'Big Shoulders Inline Display', value: 'Big Shoulders Inline Display'},
        {name: 'Big Shoulders Inline Text', value: 'Big Shoulders Inline Text'},
        {name: 'Big Shoulders Stencil Display', value: 'Big Shoulders Stencil Display'},
        {name: 'Big Shoulders Stencil Text', value: 'Big Shoulders Stencil Text'},
        {name: 'Big Shoulders Text', value: 'Big Shoulders Text'},
        {name: 'Bigelow Rules', value: 'Bigelow Rules'},
        {name: 'Bigshot One', value: 'Bigshot One'},
        {name: 'Bilbo', value: 'Bilbo'},
        {name: 'Bilbo Swash Caps', value: 'Bilbo Swash Caps'},
        {name: 'BioRhyme', value: 'BioRhyme'},
        {name: 'BioRhyme Expanded', value: 'BioRhyme Expanded'},
        {name: 'Birthstone', value: 'Birthstone'},
        {name: 'Birthstone Bounce', value: 'Birthstone Bounce'},
        {name: 'Biryani', value: 'Biryani'},
        {name: 'Bitter', value: 'Bitter'},
        {name: 'Black And White Picture', value: 'Black And White Picture'},
        {name: 'Black Han Sans', value: 'Black Han Sans'},
        {name: 'Black Ops One', value: 'Black Ops One'},
        {name: 'Blaka', value: 'Blaka'},
        {name: 'Blaka Hollow', value: 'Blaka Hollow'},
        {name: 'Blinker', value: 'Blinker'},
        {name: 'Bodoni Moda', value: 'Bodoni Moda'},
        {name: 'Bokor', value: 'Bokor'},
        {name: 'Bona Nova', value: 'Bona Nova'},
        {name: 'Bonbon', value: 'Bonbon'},
        {name: 'Bonheur Royale', value: 'Bonheur Royale'},
        {name: 'Boogaloo', value: 'Boogaloo'},
        {name: 'Bowlby One', value: 'Bowlby One'},
        {name: 'Bowlby One SC', value: 'Bowlby One SC'},
        {name: 'Brawler', value: 'Brawler'},
        {name: 'Bree Serif', value: 'Bree Serif'},
        {name: 'Brygada 1918', value: 'Brygada 1918'},
        {name: 'Bubblegum Sans', value: 'Bubblegum Sans'},
        {name: 'Bubbler One', value: 'Bubbler One'},
        {name: 'Buda', value: 'Buda'},
        {name: 'Buenard', value: 'Buenard'},
        {name: 'Bungee', value: 'Bungee'},
        {name: 'Bungee Hairline', value: 'Bungee Hairline'},
        {name: 'Bungee Inline', value: 'Bungee Inline'},
        {name: 'Bungee Outline', value: 'Bungee Outline'},
        {name: 'Bungee Shade', value: 'Bungee Shade'},
        {name: 'Butcherman', value: 'Butcherman'},
        {name: 'Butterfly Kids', value: 'Butterfly Kids'},
        {name: 'Cabin', value: 'Cabin'},
        {name: 'Cabin Condensed', value: 'Cabin Condensed'},
        {name: 'Cabin Sketch', value: 'Cabin Sketch'},
        {name: 'Caesar Dressing', value: 'Caesar Dressing'},
        {name: 'Cagliostro', value: 'Cagliostro'},
        {name: 'Cairo', value: 'Cairo'},
        {name: 'Caladea', value: 'Caladea'},
        {name: 'Calistoga', value: 'Calistoga'},
        {name: 'Calligraffitti', value: 'Calligraffitti'},
        {name: 'Cambay', value: 'Cambay'},
        {name: 'Cambo', value: 'Cambo'},
        {name: 'Candal', value: 'Candal'},
        {name: 'Cantarell', value: 'Cantarell'},
        {name: 'Cantata One', value: 'Cantata One'},
        {name: 'Cantora One', value: 'Cantora One'},
        {name: 'Capriola', value: 'Capriola'},
        {name: 'Caramel', value: 'Caramel'},
        {name: 'Carattere', value: 'Carattere'},
        {name: 'Cardo', value: 'Cardo'},
        {name: 'Carme', value: 'Carme'},
        {name: 'Carrois Gothic', value: 'Carrois Gothic'},
        {name: 'Carrois Gothic SC', value: 'Carrois Gothic SC'},
        {name: 'Carter One', value: 'Carter One'},
        {name: 'Castoro', value: 'Castoro'},
        {name: 'Catamaran', value: 'Catamaran'},
        {name: 'Caudex', value: 'Caudex'},
        {name: 'Caveat', value: 'Caveat'},
        {name: 'Caveat Brush', value: 'Caveat Brush'},
        {name: 'Cedarville Cursive', value: 'Cedarville Cursive'},
        {name: 'Ceviche One', value: 'Ceviche One'},
        {name: 'Chakra Petch', value: 'Chakra Petch'},
        {name: 'Changa', value: 'Changa'},
        {name: 'Changa One', value: 'Changa One'},
        {name: 'Chango', value: 'Chango'},
        {name: 'Charis SIL', value: 'Charis SIL'},
        {name: 'Charm', value: 'Charm'},
        {name: 'Charmonman', value: 'Charmonman'},
        {name: 'Chathura', value: 'Chathura'},
        {name: 'Chau Philomene One', value: 'Chau Philomene One'},
        {name: 'Chela One', value: 'Chela One'},
        {name: 'Chelsea Market', value: 'Chelsea Market'},
        {name: 'Chenla', value: 'Chenla'},
        {name: 'Cherish', value: 'Cherish'},
        {name: 'Cherry Cream Soda', value: 'Cherry Cream Soda'},
        {name: 'Cherry Swash', value: 'Cherry Swash'},
        {name: 'Chewy', value: 'Chewy'},
        {name: 'Chicle', value: 'Chicle'},
        {name: 'Chilanka', value: 'Chilanka'},
        {name: 'Chivo', value: 'Chivo'},
        {name: 'Chonburi', value: 'Chonburi'},
        {name: 'Cinzel', value: 'Cinzel'},
        {name: 'Cinzel Decorative', value: 'Cinzel Decorative'},
        {name: 'Clicker Script', value: 'Clicker Script'},
        {name: 'Coda', value: 'Coda'},
        {name: 'Coda Caption', value: 'Coda Caption'},
        {name: 'Codystar', value: 'Codystar'},
        {name: 'Coiny', value: 'Coiny'},
        {name: 'Combo', value: 'Combo'},
        {name: 'Comfortaa', value: 'Comfortaa'},
        {name: 'Comforter', value: 'Comforter'},
        {name: 'Comforter Brush', value: 'Comforter Brush'},
        {name: 'Comic Neue', value: 'Comic Neue'},
        {name: 'Coming Soon', value: 'Coming Soon'},
        {name: 'Commissioner', value: 'Commissioner'},
        {name: 'Concert One', value: 'Concert One'},
        {name: 'Condiment', value: 'Condiment'},
        {name: 'Content', value: 'Content'},
        {name: 'Contrail One', value: 'Contrail One'},
        {name: 'Convergence', value: 'Convergence'},
        {name: 'Cookie', value: 'Cookie'},
        {name: 'Copse', value: 'Copse'},
        {name: 'Corben', value: 'Corben'},
        {name: 'Corinthia', value: 'Corinthia'},
        {name: 'Cormorant', value: 'Cormorant'},
        {name: 'Cormorant Garamond', value: 'Cormorant Garamond'},
        {name: 'Cormorant Infant', value: 'Cormorant Infant'},
        {name: 'Cormorant SC', value: 'Cormorant SC'},
        {name: 'Cormorant Unicase', value: 'Cormorant Unicase'},
        {name: 'Cormorant Upright', value: 'Cormorant Upright'},
        {name: 'Courgette', value: 'Courgette'},
        {name: 'Courier Prime', value: 'Courier Prime'},
        {name: 'Cousine', value: 'Cousine'},
        {name: 'Coustard', value: 'Coustard'},
        {name: 'Covered By Your Grace', value: 'Covered By Your Grace'},
        {name: 'Crafty Girls', value: 'Crafty Girls'},
        {name: 'Creepster', value: 'Creepster'},
        {name: 'Crete Round', value: 'Crete Round'},
        {name: 'Crimson Pro', value: 'Crimson Pro'},
        {name: 'Crimson Text', value: 'Crimson Text'},
        {name: 'Croissant One', value: 'Croissant One'},
        {name: 'Crushed', value: 'Crushed'},
        {name: 'Cuprum', value: 'Cuprum'},
        {name: 'Cute Font', value: 'Cute Font'},
        {name: 'Cutive', value: 'Cutive'},
        {name: 'Cutive Mono', value: 'Cutive Mono'},
        {name: 'DM Mono', value: 'DM Mono'},
        {name: 'DM Sans', value: 'DM Sans'},
        {name: 'DM Serif Display', value: 'DM Serif Display'},
        {name: 'DM Serif Text', value: 'DM Serif Text'},
        {name: 'Damion', value: 'Damion'},
        {name: 'Dancing Script', value: 'Dancing Script'},
        {name: 'Dangrek', value: 'Dangrek'},
        {name: 'Darker Grotesque', value: 'Darker Grotesque'},
        {name: 'David Libre', value: 'David Libre'},
        {name: 'Dawning of a New Day', value: 'Dawning of a New Day'},
        {name: 'Days One', value: 'Days One'},
        {name: 'Dekko', value: 'Dekko'},
        {name: 'Dela Gothic One', value: 'Dela Gothic One'},
        {name: 'Delius', value: 'Delius'},
        {name: 'Delius Swash Caps', value: 'Delius Swash Caps'},
        {name: 'Delius Unicase', value: 'Delius Unicase'},
        {name: 'Della Respira', value: 'Della Respira'},
        {name: 'Denk One', value: 'Denk One'},
        {name: 'Devonshire', value: 'Devonshire'},
        {name: 'Dhurjati', value: 'Dhurjati'},
        {name: 'Didact Gothic', value: 'Didact Gothic'},
        {name: 'Diplomata', value: 'Diplomata'},
        {name: 'Diplomata SC', value: 'Diplomata SC'},
        {name: 'Do Hyeon', value: 'Do Hyeon'},
        {name: 'Dokdo', value: 'Dokdo'},
        {name: 'Domine', value: 'Domine'},
        {name: 'Donegal One', value: 'Donegal One'},
        {name: 'Dongle', value: 'Dongle'},
        {name: 'Doppio One', value: 'Doppio One'},
        {name: 'Dorsa', value: 'Dorsa'},
        {name: 'Dosis', value: 'Dosis'},
        {name: 'DotGothic16', value: 'DotGothic16'},
        {name: 'Dr Sugiyama', value: 'Dr Sugiyama'},
        {name: 'Duru Sans', value: 'Duru Sans'},
        {name: 'Dynalight', value: 'Dynalight'},
        {name: 'EB Garamond', value: 'EB Garamond'},
        {name: 'Eagle Lake', value: 'Eagle Lake'},
        {name: 'East Sea Dokdo', value: 'East Sea Dokdo'},
        {name: 'Eater', value: 'Eater'},
        {name: 'Economica', value: 'Economica'},
        {name: 'Eczar', value: 'Eczar'},
        {name: 'El Messiri', value: 'El Messiri'},
        {name: 'Electrolize', value: 'Electrolize'},
        {name: 'Elsie', value: 'Elsie'},
        {name: 'Elsie Swash Caps', value: 'Elsie Swash Caps'},
        {name: 'Emblema One', value: 'Emblema One'},
        {name: 'Emilys Candy', value: 'Emilys Candy'},
        {name: 'Encode Sans', value: 'Encode Sans'},
        {name: 'Encode Sans Condensed', value: 'Encode Sans Condensed'},
        {name: 'Encode Sans Expanded', value: 'Encode Sans Expanded'},
        {name: 'Encode Sans SC', value: 'Encode Sans SC'},
        {name: 'Encode Sans Semi Condensed', value: 'Encode Sans Semi Condensed'},
        {name: 'Encode Sans Semi Expanded', value: 'Encode Sans Semi Expanded'},
        {name: 'Engagement', value: 'Engagement'},
        {name: 'Englebert', value: 'Englebert'},
        {name: 'Enriqueta', value: 'Enriqueta'},
        {name: 'Ephesis', value: 'Ephesis'},
        {name: 'Epilogue', value: 'Epilogue'},
        {name: 'Erica One', value: 'Erica One'},
        {name: 'Esteban', value: 'Esteban'},
        {name: 'Estonia', value: 'Estonia'},
        {name: 'Euphoria Script', value: 'Euphoria Script'},
        {name: 'Ewert', value: 'Ewert'},
        {name: 'Exo', value: 'Exo'},
        {name: 'Exo 2', value: 'Exo 2'},
        {name: 'Expletus Sans', value: 'Expletus Sans'},
        {name: 'Explora', value: 'Explora'},
        {name: 'Fahkwang', value: 'Fahkwang'},
        {name: 'Familjen Grotesk', value: 'Familjen Grotesk'},
        {name: 'Fanwood Text', value: 'Fanwood Text'},
        {name: 'Farro', value: 'Farro'},
        {name: 'Farsan', value: 'Farsan'},
        {name: 'Fascinate', value: 'Fascinate'},
        {name: 'Fascinate Inline', value: 'Fascinate Inline'},
        {name: 'Faster One', value: 'Faster One'},
        {name: 'Fasthand', value: 'Fasthand'},
        {name: 'Fauna One', value: 'Fauna One'},
        {name: 'Faustina', value: 'Faustina'},
        {name: 'Federant', value: 'Federant'},
        {name: 'Federo', value: 'Federo'},
        {name: 'Felipa', value: 'Felipa'},
        {name: 'Fenix', value: 'Fenix'},
        {name: 'Festive', value: 'Festive'},
        {name: 'Finger Paint', value: 'Finger Paint'},
        {name: 'Fira Code', value: 'Fira Code'},
        {name: 'Fira Mono', value: 'Fira Mono'},
        {name: 'Fira Sans', value: 'Fira Sans'},
        {name: 'Fira Sans Condensed', value: 'Fira Sans Condensed'},
        {name: 'Fira Sans Extra Condensed', value: 'Fira Sans Extra Condensed'},
        {name: 'Fjalla One', value: 'Fjalla One'},
        {name: 'Fjord One', value: 'Fjord One'},
        {name: 'Flamenco', value: 'Flamenco'},
        {name: 'Flavors', value: 'Flavors'},
        {name: 'Fleur De Leah', value: 'Fleur De Leah'},
        {name: 'Flow Block', value: 'Flow Block'},
        {name: 'Flow Circular', value: 'Flow Circular'},
        {name: 'Flow Rounded', value: 'Flow Rounded'},
        {name: 'Fondamento', value: 'Fondamento'},
        {name: 'Fontdiner Swanky', value: 'Fontdiner Swanky'},
        {name: 'Forum', value: 'Forum'},
        {name: 'Francois One', value: 'Francois One'},
        {name: 'Frank Ruhl Libre', value: 'Frank Ruhl Libre'},
        {name: 'Fraunces', value: 'Fraunces'},
        {name: 'Freckle Face', value: 'Freckle Face'},
        {name: 'Fredericka the Great', value: 'Fredericka the Great'},
        {name: 'Fredoka', value: 'Fredoka'},
        {name: 'Fredoka One', value: 'Fredoka One'},
        {name: 'Freehand', value: 'Freehand'},
        {name: 'Fresca', value: 'Fresca'},
        {name: 'Frijole', value: 'Frijole'},
        {name: 'Fruktur', value: 'Fruktur'},
        {name: 'Fugaz One', value: 'Fugaz One'},
        {name: 'Fuggles', value: 'Fuggles'},
        {name: 'Fuzzy Bubbles', value: 'Fuzzy Bubbles'},
        {name: 'GFS Didot', value: 'GFS Didot'},
        {name: 'GFS Neohellenic', value: 'GFS Neohellenic'},
        {name: 'Gabriela', value: 'Gabriela'},
        {name: 'Gaegu', value: 'Gaegu'},
        {name: 'Gafata', value: 'Gafata'},
        {name: 'Galada', value: 'Galada'},
        {name: 'Galdeano', value: 'Galdeano'},
        {name: 'Galindo', value: 'Galindo'},
        {name: 'Gamja Flower', value: 'Gamja Flower'},
        {name: 'Gayathri', value: 'Gayathri'},
        {name: 'Gelasio', value: 'Gelasio'},
        {name: 'Gemunu Libre', value: 'Gemunu Libre'},
        {name: 'Genos', value: 'Genos'},
        {name: 'Gentium Basic', value: 'Gentium Basic'},
        {name: 'Gentium Book Basic', value: 'Gentium Book Basic'},
        {name: 'Gentium Plus', value: 'Gentium Plus'},
        {name: 'Geo', value: 'Geo'},
        {name: 'Georama', value: 'Georama'},
        {name: 'Geostar', value: 'Geostar'},
        {name: 'Geostar Fill', value: 'Geostar Fill'},
        {name: 'Germania One', value: 'Germania One'},
        {name: 'Gideon Roman', value: 'Gideon Roman'},
        {name: 'Gidugu', value: 'Gidugu'},
        {name: 'Gilda Display', value: 'Gilda Display'},
        {name: 'Girassol', value: 'Girassol'},
        {name: 'Give You Glory', value: 'Give You Glory'},
        {name: 'Glass Antiqua', value: 'Glass Antiqua'},
        {name: 'Glegoo', value: 'Glegoo'},
        {name: 'Gloria Hallelujah', value: 'Gloria Hallelujah'},
        {name: 'Glory', value: 'Glory'},
        {name: 'Gluten', value: 'Gluten'},
        {name: 'Goblin One', value: 'Goblin One'},
        {name: 'Gochi Hand', value: 'Gochi Hand'},
        {name: 'Goldman', value: 'Goldman'},
        {name: 'Gorditas', value: 'Gorditas'},
        {name: 'Gothic A1', value: 'Gothic A1'},
        {name: 'Gotu', value: 'Gotu'},
        {name: 'Goudy Bookletter 1911', value: 'Goudy Bookletter 1911'},
        {name: 'Gowun Batang', value: 'Gowun Batang'},
        {name: 'Gowun Dodum', value: 'Gowun Dodum'},
        {name: 'Graduate', value: 'Graduate'},
        {name: 'Grand Hotel', value: 'Grand Hotel'},
        {name: 'Grandstander', value: 'Grandstander'},
        {name: 'Grape Nuts', value: 'Grape Nuts'},
        {name: 'Gravitas One', value: 'Gravitas One'},
        {name: 'Great Vibes', value: 'Great Vibes'},
        {name: 'Grechen Fuemen', value: 'Grechen Fuemen'},
        {name: 'Grenze', value: 'Grenze'},
        {name: 'Grenze Gotisch', value: 'Grenze Gotisch'},
        {name: 'Grey Qo', value: 'Grey Qo'},
        {name: 'Griffy', value: 'Griffy'},
        {name: 'Gruppo', value: 'Gruppo'},
        {name: 'Gudea', value: 'Gudea'},
        {name: 'Gugi', value: 'Gugi'},
        {name: 'Gupter', value: 'Gupter'},
        {name: 'Gurajada', value: 'Gurajada'},
        {name: 'Gwendolyn', value: 'Gwendolyn'},
        {name: 'Habibi', value: 'Habibi'},
        {name: 'Hachi Maru Pop', value: 'Hachi Maru Pop'},
        {name: 'Hahmlet', value: 'Hahmlet'},
        {name: 'Halant', value: 'Halant'},
        {name: 'Hammersmith One', value: 'Hammersmith One'},
        {name: 'Hanalei', value: 'Hanalei'},
        {name: 'Hanalei Fill', value: 'Hanalei Fill'},
        {name: 'Handlee', value: 'Handlee'},
        {name: 'Hanuman', value: 'Hanuman'},
        {name: 'Happy Monkey', value: 'Happy Monkey'},
        {name: 'Harmattan', value: 'Harmattan'},
        {name: 'Headland One', value: 'Headland One'},
        {name: 'Heebo', value: 'Heebo'},
        {name: 'Henny Penny', value: 'Henny Penny'},
        {name: 'Hepta Slab', value: 'Hepta Slab'},
        {name: 'Herr Von Muellerhoff', value: 'Herr Von Muellerhoff'},
        {name: 'Hi Melody', value: 'Hi Melody'},
        {name: 'Hina Mincho', value: 'Hina Mincho'},
        {name: 'Hind', value: 'Hind'},
        {name: 'Hind Guntur', value: 'Hind Guntur'},
        {name: 'Hind Madurai', value: 'Hind Madurai'},
        {name: 'Hind Siliguri', value: 'Hind Siliguri'},
        {name: 'Hind Vadodara', value: 'Hind Vadodara'},
        {name: 'Holtwood One SC', value: 'Holtwood One SC'},
        {name: 'Homemade Apple', value: 'Homemade Apple'},
        {name: 'Homenaje', value: 'Homenaje'},
        {name: 'Hubballi', value: 'Hubballi'},
        {name: 'Hurricane', value: 'Hurricane'},
        {name: 'IBM Plex Mono', value: 'IBM Plex Mono'},
        {name: 'IBM Plex Sans', value: 'IBM Plex Sans'},
        {name: 'IBM Plex Sans Arabic', value: 'IBM Plex Sans Arabic'},
        {name: 'IBM Plex Sans Condensed', value: 'IBM Plex Sans Condensed'},
        {name: 'IBM Plex Sans Devanagari', value: 'IBM Plex Sans Devanagari'},
        {name: 'IBM Plex Sans Hebrew', value: 'IBM Plex Sans Hebrew'},
        {name: 'IBM Plex Sans KR', value: 'IBM Plex Sans KR'},
        {name: 'IBM Plex Sans Thai', value: 'IBM Plex Sans Thai'},
        {name: 'IBM Plex Sans Thai Looped', value: 'IBM Plex Sans Thai Looped'},
        {name: 'IBM Plex Serif', value: 'IBM Plex Serif'},
        {name: 'IM Fell DW Pica', value: 'IM Fell DW Pica'},
        {name: 'IM Fell DW Pica SC', value: 'IM Fell DW Pica SC'},
        {name: 'IM Fell Double Pica', value: 'IM Fell Double Pica'},
        {name: 'IM Fell Double Pica SC', value: 'IM Fell Double Pica SC'},
        {name: 'IM Fell English', value: 'IM Fell English'},
        {name: 'IM Fell English SC', value: 'IM Fell English SC'},
        {name: 'IM Fell French Canon', value: 'IM Fell French Canon'},
        {name: 'IM Fell French Canon SC', value: 'IM Fell French Canon SC'},
        {name: 'IM Fell Great Primer', value: 'IM Fell Great Primer'},
        {name: 'IM Fell Great Primer SC', value: 'IM Fell Great Primer SC'},
        {name: 'Ibarra Real Nova', value: 'Ibarra Real Nova'},
        {name: 'Iceberg', value: 'Iceberg'},
        {name: 'Iceland', value: 'Iceland'},
        {name: 'Imbue', value: 'Imbue'},
        {name: 'Imperial Script', value: 'Imperial Script'},
        {name: 'Imprima', value: 'Imprima'},
        {name: 'Inconsolata', value: 'Inconsolata'},
        {name: 'Inder', value: 'Inder'},
        {name: 'Indie Flower', value: 'Indie Flower'},
        {name: 'Ingrid Darling', value: 'Ingrid Darling'},
        {name: 'Inika', value: 'Inika'},
        {name: 'Inknut Antiqua', value: 'Inknut Antiqua'},
        {name: 'Inria Sans', value: 'Inria Sans'},
        {name: 'Inria Serif', value: 'Inria Serif'},
        {name: 'Inspiration', value: 'Inspiration'},
        {name: 'Inter', value: 'Inter'},
        {name: 'Irish Grover', value: 'Irish Grover'},
        {name: 'Island Moments', value: 'Island Moments'},
        {name: 'Istok Web', value: 'Istok Web'},
        {name: 'Italiana', value: 'Italiana'},
        {name: 'Italianno', value: 'Italianno'},
        {name: 'Itim', value: 'Itim'},
        {name: 'Jacques Francois', value: 'Jacques Francois'},
        {name: 'Jacques Francois Shadow', value: 'Jacques Francois Shadow'},
        {name: 'Jaldi', value: 'Jaldi'},
        {name: 'JetBrains Mono', value: 'JetBrains Mono'},
        {name: 'Jim Nightshade', value: 'Jim Nightshade'},
        {name: 'Joan', value: 'Joan'},
        {name: 'Jockey One', value: 'Jockey One'},
        {name: 'Jolly Lodger', value: 'Jolly Lodger'},
        {name: 'Jomhuria', value: 'Jomhuria'},
        {name: 'Jomolhari', value: 'Jomolhari'},
        {name: 'Josefin Sans', value: 'Josefin Sans'},
        {name: 'Josefin Slab', value: 'Josefin Slab'},
        {name: 'Jost', value: 'Jost'},
        {name: 'Joti One', value: 'Joti One'},
        {name: 'Jua', value: 'Jua'},
        {name: 'Judson', value: 'Judson'},
        {name: 'Julee', value: 'Julee'},
        {name: 'Julius Sans One', value: 'Julius Sans One'},
        {name: 'Junge', value: 'Junge'},
        {name: 'Jura', value: 'Jura'},
        {name: 'Just Another Hand', value: 'Just Another Hand'},
        {name: 'Just Me Again Down Here', value: 'Just Me Again Down Here'},
        {name: 'K2D', value: 'K2D'},
        {name: 'Kadwa', value: 'Kadwa'},
        {name: 'Kaisei Decol', value: 'Kaisei Decol'},
        {name: 'Kaisei HarunoUmi', value: 'Kaisei HarunoUmi'},
        {name: 'Kaisei Opti', value: 'Kaisei Opti'},
        {name: 'Kaisei Tokumin', value: 'Kaisei Tokumin'},
        {name: 'Kalam', value: 'Kalam'},
        {name: 'Kameron', value: 'Kameron'},
        {name: 'Kanit', value: 'Kanit'},
        {name: 'Kantumruy', value: 'Kantumruy'},
        {name: 'Karantina', value: 'Karantina'},
        {name: 'Karla', value: 'Karla'},
        {name: 'Karma', value: 'Karma'},
        {name: 'Katibeh', value: 'Katibeh'},
        {name: 'Kaushan Script', value: 'Kaushan Script'},
        {name: 'Kavivanar', value: 'Kavivanar'},
        {name: 'Kavoon', value: 'Kavoon'},
        {name: 'Kdam Thmor', value: 'Kdam Thmor'},
        {name: 'Kdam Thmor Pro', value: 'Kdam Thmor Pro'},
        {name: 'Keania One', value: 'Keania One'},
        {name: 'Kelly Slab', value: 'Kelly Slab'},
        {name: 'Kenia', value: 'Kenia'},
        {name: 'Khand', value: 'Khand'},
        {name: 'Khmer', value: 'Khmer'},
        {name: 'Khula', value: 'Khula'},
        {name: 'Kings', value: 'Kings'},
        {name: 'Kirang Haerang', value: 'Kirang Haerang'},
        {name: 'Kite One', value: 'Kite One'},
        {name: 'Kiwi Maru', value: 'Kiwi Maru'},
        {name: 'Klee One', value: 'Klee One'},
        {name: 'Knewave', value: 'Knewave'},
        {name: 'KoHo', value: 'KoHo'},
        {name: 'Kodchasan', value: 'Kodchasan'},
        {name: 'Koh Santepheap', value: 'Koh Santepheap'},
        {name: 'Kolker Brush', value: 'Kolker Brush'},
        {name: 'Kosugi', value: 'Kosugi'},
        {name: 'Kosugi Maru', value: 'Kosugi Maru'},
        {name: 'Kotta One', value: 'Kotta One'},
        {name: 'Koulen', value: 'Koulen'},
        {name: 'Kranky', value: 'Kranky'},
        {name: 'Kreon', value: 'Kreon'},
        {name: 'Kristi', value: 'Kristi'},
        {name: 'Krona One', value: 'Krona One'},
        {name: 'Krub', value: 'Krub'},
        {name: 'Kufam', value: 'Kufam'},
        {name: 'Kulim Park', value: 'Kulim Park'},
        {name: 'Kumar One', value: 'Kumar One'},
        {name: 'Kumar One Outline', value: 'Kumar One Outline'},
        {name: 'Kumbh Sans', value: 'Kumbh Sans'},
        {name: 'Kurale', value: 'Kurale'},
        {name: 'La Belle Aurore', value: 'La Belle Aurore'},
        {name: 'Lacquer', value: 'Lacquer'},
        {name: 'Laila', value: 'Laila'},
        {name: 'Lakki Reddy', value: 'Lakki Reddy'},
        {name: 'Lalezar', value: 'Lalezar'},
        {name: 'Lancelot', value: 'Lancelot'},
        {name: 'Langar', value: 'Langar'},
        {name: 'Lateef', value: 'Lateef'},
        {name: 'Lato', value: 'Lato'},
        {name: 'Lavishly Yours', value: 'Lavishly Yours'},
        {name: 'League Gothic', value: 'League Gothic'},
        {name: 'League Script', value: 'League Script'},
        {name: 'League Spartan', value: 'League Spartan'},
        {name: 'Leckerli One', value: 'Leckerli One'},
        {name: 'Ledger', value: 'Ledger'},
        {name: 'Lekton', value: 'Lekton'},
        {name: 'Lemon', value: 'Lemon'},
        {name: 'Lemonada', value: 'Lemonada'},
        {name: 'Lexend', value: 'Lexend'},
        {name: 'Lexend Deca', value: 'Lexend Deca'},
        {name: 'Lexend Exa', value: 'Lexend Exa'},
        {name: 'Lexend Giga', value: 'Lexend Giga'},
        {name: 'Lexend Mega', value: 'Lexend Mega'},
        {name: 'Lexend Peta', value: 'Lexend Peta'},
        {name: 'Lexend Tera', value: 'Lexend Tera'},
        {name: 'Lexend Zetta', value: 'Lexend Zetta'},
        {name: 'Libre Barcode 128', value: 'Libre Barcode 128'},
        {name: 'Libre Barcode 128 Text', value: 'Libre Barcode 128 Text'},
        {name: 'Libre Barcode 39', value: 'Libre Barcode 39'},
        {name: 'Libre Barcode 39 Extended', value: 'Libre Barcode 39 Extended'},
        {name: 'Libre Barcode 39 Extended Text', value: 'Libre Barcode 39 Extended Text'},
        {name: 'Libre Barcode 39 Text', value: 'Libre Barcode 39 Text'},
        {name: 'Libre Barcode EAN13 Text', value: 'Libre Barcode EAN13 Text'},
        {name: 'Libre Baskerville', value: 'Libre Baskerville'},
        {name: 'Libre Bodoni', value: 'Libre Bodoni'},
        {name: 'Libre Caslon Display', value: 'Libre Caslon Display'},
        {name: 'Libre Caslon Text', value: 'Libre Caslon Text'},
        {name: 'Libre Franklin', value: 'Libre Franklin'},
        {name: 'Licorice', value: 'Licorice'},
        {name: 'Life Savers', value: 'Life Savers'},
        {name: 'Lilita One', value: 'Lilita One'},
        {name: 'Lily Script One', value: 'Lily Script One'},
        {name: 'Limelight', value: 'Limelight'},
        {name: 'Linden Hill', value: 'Linden Hill'},
        {name: 'Literata', value: 'Literata'},
        {name: 'Liu Jian Mao Cao', value: 'Liu Jian Mao Cao'},
        {name: 'Livvic', value: 'Livvic'},
        {name: 'Lobster', value: 'Lobster'},
        {name: 'Lobster Two', value: 'Lobster Two'},
        {name: 'Londrina Outline', value: 'Londrina Outline'},
        {name: 'Londrina Shadow', value: 'Londrina Shadow'},
        {name: 'Londrina Sketch', value: 'Londrina Sketch'},
        {name: 'Londrina Solid', value: 'Londrina Solid'},
        {name: 'Long Cang', value: 'Long Cang'},
        {name: 'Lora', value: 'Lora'},
        {name: 'Love Light', value: 'Love Light'},
        {name: 'Love Ya Like A Sister', value: 'Love Ya Like A Sister'},
        {name: 'Loved by the King', value: 'Loved by the King'},
        {name: 'Lovers Quarrel', value: 'Lovers Quarrel'},
        {name: 'Luckiest Guy', value: 'Luckiest Guy'},
        {name: 'Lusitana', value: 'Lusitana'},
        {name: 'Lustria', value: 'Lustria'},
        {name: 'Luxurious Roman', value: 'Luxurious Roman'},
        {name: 'Luxurious Script', value: 'Luxurious Script'},
        {name: 'M PLUS 1', value: 'M PLUS 1'},
        {name: 'M PLUS 1 Code', value: 'M PLUS 1 Code'},
        {name: 'M PLUS 1p', value: 'M PLUS 1p'},
        {name: 'M PLUS 2', value: 'M PLUS 2'},
        {name: 'M PLUS Code Latin', value: 'M PLUS Code Latin'},
        {name: 'M PLUS Rounded 1c', value: 'M PLUS Rounded 1c'},
        {name: 'Ma Shan Zheng', value: 'Ma Shan Zheng'},
        {name: 'Macondo', value: 'Macondo'},
        {name: 'Macondo Swash Caps', value: 'Macondo Swash Caps'},
        {name: 'Mada', value: 'Mada'},
        {name: 'Magra', value: 'Magra'},
        {name: 'Maiden Orange', value: 'Maiden Orange'},
        {name: 'Maitree', value: 'Maitree'},
        {name: 'Major Mono Display', value: 'Major Mono Display'},
        {name: 'Mako', value: 'Mako'},
        {name: 'Mali', value: 'Mali'},
        {name: 'Mallanna', value: 'Mallanna'},
        {name: 'Mandali', value: 'Mandali'},
        {name: 'Manjari', value: 'Manjari'},
        {name: 'Manrope', value: 'Manrope'},
        {name: 'Mansalva', value: 'Mansalva'},
        {name: 'Manuale', value: 'Manuale'},
        {name: 'Marcellus', value: 'Marcellus'},
        {name: 'Marcellus SC', value: 'Marcellus SC'},
        {name: 'Marck Script', value: 'Marck Script'},
        {name: 'Margarine', value: 'Margarine'},
        {name: 'Markazi Text', value: 'Markazi Text'},
        {name: 'Marko One', value: 'Marko One'},
        {name: 'Marmelad', value: 'Marmelad'},
        {name: 'Martel', value: 'Martel'},
        {name: 'Martel Sans', value: 'Martel Sans'},
        {name: 'Marvel', value: 'Marvel'},
        {name: 'Mate', value: 'Mate'},
        {name: 'Mate SC', value: 'Mate SC'},
        {name: 'Maven Pro', value: 'Maven Pro'},
        {name: 'McLaren', value: 'McLaren'},
        {name: 'Mea Culpa', value: 'Mea Culpa'},
        {name: 'Meddon', value: 'Meddon'},
        {name: 'MedievalSharp', value: 'MedievalSharp'},
        {name: 'Medula One', value: 'Medula One'},
        {name: 'Meera Inimai', value: 'Meera Inimai'},
        {name: 'Megrim', value: 'Megrim'},
        {name: 'Meie Script', value: 'Meie Script'},
        {name: 'Meow Script', value: 'Meow Script'},
        {name: 'Merienda', value: 'Merienda'},
        {name: 'Merienda One', value: 'Merienda One'},
        {name: 'Merriweather', value: 'Merriweather'},
        {name: 'Merriweather Sans', value: 'Merriweather Sans'},
        {name: 'Metal', value: 'Metal'},
        {name: 'Metal Mania', value: 'Metal Mania'},
        {name: 'Metamorphous', value: 'Metamorphous'},
        {name: 'Metrophobic', value: 'Metrophobic'},
        {name: 'Michroma', value: 'Michroma'},
        {name: 'Milonga', value: 'Milonga'},
        {name: 'Miltonian', value: 'Miltonian'},
        {name: 'Miltonian Tattoo', value: 'Miltonian Tattoo'},
        {name: 'Mina', value: 'Mina'},
        {name: 'Miniver', value: 'Miniver'},
        {name: 'Miriam Libre', value: 'Miriam Libre'},
        {name: 'Mirza', value: 'Mirza'},
        {name: 'Miss Fajardose', value: 'Miss Fajardose'},
        {name: 'Mitr', value: 'Mitr'},
        {name: 'Mochiy Pop One', value: 'Mochiy Pop One'},
        {name: 'Mochiy Pop P One', value: 'Mochiy Pop P One'},
        {name: 'Modak', value: 'Modak'},
        {name: 'Modern Antiqua', value: 'Modern Antiqua'},
        {name: 'Mogra', value: 'Mogra'},
        {name: 'Mohave', value: 'Mohave'},
        {name: 'Molengo', value: 'Molengo'},
        {name: 'Molle', value: 'Molle'},
        {name: 'Monda', value: 'Monda'},
        {name: 'Monofett', value: 'Monofett'},
        {name: 'Monoton', value: 'Monoton'},
        {name: 'Monsieur La Doulaise', value: 'Monsieur La Doulaise'},
        {name: 'Montaga', value: 'Montaga'},
        {name: 'Montagu Slab', value: 'Montagu Slab'},
        {name: 'MonteCarlo', value: 'MonteCarlo'},
        {name: 'Montez', value: 'Montez'},
        {name: 'Montserrat', value: 'Montserrat'},
        {name: 'Montserrat Alternates', value: 'Montserrat Alternates'},
        {name: 'Montserrat Subrayada', value: 'Montserrat Subrayada'},
        {name: 'Moo Lah Lah', value: 'Moo Lah Lah'},
        {name: 'Moon Dance', value: 'Moon Dance'},
        {name: 'Moul', value: 'Moul'},
        {name: 'Moulpali', value: 'Moulpali'},
        {name: 'Mountains of Christmas', value: 'Mountains of Christmas'},
        {name: 'Mouse Memoirs', value: 'Mouse Memoirs'},
        {name: 'Mr Bedfort', value: 'Mr Bedfort'},
        {name: 'Mr Dafoe', value: 'Mr Dafoe'},
        {name: 'Mr De Haviland', value: 'Mr De Haviland'},
        {name: 'Mrs Saint Delafield', value: 'Mrs Saint Delafield'},
        {name: 'Mrs Sheppards', value: 'Mrs Sheppards'},
        {name: 'Ms Madi', value: 'Ms Madi'},
        {name: 'Mukta', value: 'Mukta'},
        {name: 'Mukta Mahee', value: 'Mukta Mahee'},
        {name: 'Mukta Malar', value: 'Mukta Malar'},
        {name: 'Mukta Vaani', value: 'Mukta Vaani'},
        {name: 'Mulish', value: 'Mulish'},
        {name: 'Murecho', value: 'Murecho'},
        {name: 'MuseoModerno', value: 'MuseoModerno'},
        {name: 'My Soul', value: 'My Soul'},
        {name: 'Mystery Quest', value: 'Mystery Quest'},
        {name: 'NTR', value: 'NTR'},
        {name: 'Nanum Brush Script', value: 'Nanum Brush Script'},
        {name: 'Nanum Gothic', value: 'Nanum Gothic'},
        {name: 'Nanum Gothic Coding', value: 'Nanum Gothic Coding'},
        {name: 'Nanum Myeongjo', value: 'Nanum Myeongjo'},
        {name: 'Nanum Pen Script', value: 'Nanum Pen Script'},
        {name: 'Neonderthaw', value: 'Neonderthaw'},
        {name: 'Nerko One', value: 'Nerko One'},
        {name: 'Neucha', value: 'Neucha'},
        {name: 'Neuton', value: 'Neuton'},
        {name: 'New Rocker', value: 'New Rocker'},
        {name: 'New Tegomin', value: 'New Tegomin'},
        {name: 'News Cycle', value: 'News Cycle'},
        {name: 'Newsreader', value: 'Newsreader'},
        {name: 'Niconne', value: 'Niconne'},
        {name: 'Niramit', value: 'Niramit'},
        {name: 'Nixie One', value: 'Nixie One'},
        {name: 'Nobile', value: 'Nobile'},
        {name: 'Nokora', value: 'Nokora'},
        {name: 'Norican', value: 'Norican'},
        {name: 'Nosifer', value: 'Nosifer'},
        {name: 'Notable', value: 'Notable'},
        {name: 'Nothing You Could Do', value: 'Nothing You Could Do'},
        {name: 'Noticia Text', value: 'Noticia Text'},
        {name: 'Noto Emoji', value: 'Noto Emoji'},
        {name: 'Noto Kufi Arabic', value: 'Noto Kufi Arabic'},
        {name: 'Noto Music', value: 'Noto Music'},
        {name: 'Noto Naskh Arabic', value: 'Noto Naskh Arabic'},
        {name: 'Noto Nastaliq Urdu', value: 'Noto Nastaliq Urdu'},
        {name: 'Noto Rashi Hebrew', value: 'Noto Rashi Hebrew'},
        {name: 'Noto Sans', value: 'Noto Sans'},
        {name: 'Noto Sans Adlam', value: 'Noto Sans Adlam'},
        {name: 'Noto Sans Adlam Unjoined', value: 'Noto Sans Adlam Unjoined'},
        {name: 'Noto Sans Anatolian Hieroglyphs', value: 'Noto Sans Anatolian Hieroglyphs'},
        {name: 'Noto Sans Arabic', value: 'Noto Sans Arabic'},
        {name: 'Noto Sans Armenian', value: 'Noto Sans Armenian'},
        {name: 'Noto Sans Avestan', value: 'Noto Sans Avestan'},
        {name: 'Noto Sans Balinese', value: 'Noto Sans Balinese'},
        {name: 'Noto Sans Bamum', value: 'Noto Sans Bamum'},
        {name: 'Noto Sans Bassa Vah', value: 'Noto Sans Bassa Vah'},
        {name: 'Noto Sans Batak', value: 'Noto Sans Batak'},
        {name: 'Noto Sans Bengali', value: 'Noto Sans Bengali'},
        {name: 'Noto Sans Bhaiksuki', value: 'Noto Sans Bhaiksuki'},
        {name: 'Noto Sans Brahmi', value: 'Noto Sans Brahmi'},
        {name: 'Noto Sans Buginese', value: 'Noto Sans Buginese'},
        {name: 'Noto Sans Buhid', value: 'Noto Sans Buhid'},
        {name: 'Noto Sans Canadian Aboriginal', value: 'Noto Sans Canadian Aboriginal'},
        {name: 'Noto Sans Carian', value: 'Noto Sans Carian'},
        {name: 'Noto Sans Caucasian Albanian', value: 'Noto Sans Caucasian Albanian'},
        {name: 'Noto Sans Chakma', value: 'Noto Sans Chakma'},
        {name: 'Noto Sans Cham', value: 'Noto Sans Cham'},
        {name: 'Noto Sans Cherokee', value: 'Noto Sans Cherokee'},
        {name: 'Noto Sans Coptic', value: 'Noto Sans Coptic'},
        {name: 'Noto Sans Cuneiform', value: 'Noto Sans Cuneiform'},
        {name: 'Noto Sans Cypriot', value: 'Noto Sans Cypriot'},
        {name: 'Noto Sans Deseret', value: 'Noto Sans Deseret'},
        {name: 'Noto Sans Devanagari', value: 'Noto Sans Devanagari'},
        {name: 'Noto Sans Display', value: 'Noto Sans Display'},
        {name: 'Noto Sans Duployan', value: 'Noto Sans Duployan'},
        {name: 'Noto Sans Egyptian Hieroglyphs', value: 'Noto Sans Egyptian Hieroglyphs'},
        {name: 'Noto Sans Elbasan', value: 'Noto Sans Elbasan'},
        {name: 'Noto Sans Elymaic', value: 'Noto Sans Elymaic'},
        {name: 'Noto Sans Georgian', value: 'Noto Sans Georgian'},
        {name: 'Noto Sans Glagolitic', value: 'Noto Sans Glagolitic'},
        {name: 'Noto Sans Gothic', value: 'Noto Sans Gothic'},
        {name: 'Noto Sans Grantha', value: 'Noto Sans Grantha'},
        {name: 'Noto Sans Gujarati', value: 'Noto Sans Gujarati'},
        {name: 'Noto Sans Gunjala Gondi', value: 'Noto Sans Gunjala Gondi'},
        {name: 'Noto Sans Gurmukhi', value: 'Noto Sans Gurmukhi'},
        {name: 'Noto Sans HK', value: 'Noto Sans HK'},
        {name: 'Noto Sans Hanifi Rohingya', value: 'Noto Sans Hanifi Rohingya'},
        {name: 'Noto Sans Hanunoo', value: 'Noto Sans Hanunoo'},
        {name: 'Noto Sans Hatran', value: 'Noto Sans Hatran'},
        {name: 'Noto Sans Hebrew', value: 'Noto Sans Hebrew'},
        {name: 'Noto Sans Imperial Aramaic', value: 'Noto Sans Imperial Aramaic'},
        {name: 'Noto Sans Indic Siyaq Numbers', value: 'Noto Sans Indic Siyaq Numbers'},
        {name: 'Noto Sans Inscriptional Pahlavi', value: 'Noto Sans Inscriptional Pahlavi'},
        {name: 'Noto Sans Inscriptional Parthian', value: 'Noto Sans Inscriptional Parthian'},
        {name: 'Noto Sans JP', value: 'Noto Sans JP'},
        {name: 'Noto Sans Javanese', value: 'Noto Sans Javanese'},
        {name: 'Noto Sans KR', value: 'Noto Sans KR'},
        {name: 'Noto Sans Kaithi', value: 'Noto Sans Kaithi'},
        {name: 'Noto Sans Kannada', value: 'Noto Sans Kannada'},
        {name: 'Noto Sans Kayah Li', value: 'Noto Sans Kayah Li'},
        {name: 'Noto Sans Kharoshthi', value: 'Noto Sans Kharoshthi'},
        {name: 'Noto Sans Khmer', value: 'Noto Sans Khmer'},
        {name: 'Noto Sans Khojki', value: 'Noto Sans Khojki'},
        {name: 'Noto Sans Khudawadi', value: 'Noto Sans Khudawadi'},
        {name: 'Noto Sans Lao', value: 'Noto Sans Lao'},
        {name: 'Noto Sans Lepcha', value: 'Noto Sans Lepcha'},
        {name: 'Noto Sans Limbu', value: 'Noto Sans Limbu'},
        {name: 'Noto Sans Linear A', value: 'Noto Sans Linear A'},
        {name: 'Noto Sans Linear B', value: 'Noto Sans Linear B'},
        {name: 'Noto Sans Lisu', value: 'Noto Sans Lisu'},
        {name: 'Noto Sans Lycian', value: 'Noto Sans Lycian'},
        {name: 'Noto Sans Lydian', value: 'Noto Sans Lydian'},
        {name: 'Noto Sans Mahajani', value: 'Noto Sans Mahajani'},
        {name: 'Noto Sans Malayalam', value: 'Noto Sans Malayalam'},
        {name: 'Noto Sans Mandaic', value: 'Noto Sans Mandaic'},
        {name: 'Noto Sans Manichaean', value: 'Noto Sans Manichaean'},
        {name: 'Noto Sans Marchen', value: 'Noto Sans Marchen'},
        {name: 'Noto Sans Masaram Gondi', value: 'Noto Sans Masaram Gondi'},
        {name: 'Noto Sans Math', value: 'Noto Sans Math'},
        {name: 'Noto Sans Mayan Numerals', value: 'Noto Sans Mayan Numerals'},
        {name: 'Noto Sans Medefaidrin', value: 'Noto Sans Medefaidrin'},
        {name: 'Noto Sans Meetei Mayek', value: 'Noto Sans Meetei Mayek'},
        {name: 'Noto Sans Meroitic', value: 'Noto Sans Meroitic'},
        {name: 'Noto Sans Miao', value: 'Noto Sans Miao'},
        {name: 'Noto Sans Modi', value: 'Noto Sans Modi'},
        {name: 'Noto Sans Mongolian', value: 'Noto Sans Mongolian'},
        {name: 'Noto Sans Mono', value: 'Noto Sans Mono'},
        {name: 'Noto Sans Mro', value: 'Noto Sans Mro'},
        {name: 'Noto Sans Multani', value: 'Noto Sans Multani'},
        {name: 'Noto Sans Myanmar', value: 'Noto Sans Myanmar'},
        {name: 'Noto Sans N Ko', value: 'Noto Sans N Ko'},
        {name: 'Noto Sans Nabataean', value: 'Noto Sans Nabataean'},
        {name: 'Noto Sans New Tai Lue', value: 'Noto Sans New Tai Lue'},
        {name: 'Noto Sans Newa', value: 'Noto Sans Newa'},
        {name: 'Noto Sans Nushu', value: 'Noto Sans Nushu'},
        {name: 'Noto Sans Ogham', value: 'Noto Sans Ogham'},
        {name: 'Noto Sans Ol Chiki', value: 'Noto Sans Ol Chiki'},
        {name: 'Noto Sans Old Hungarian', value: 'Noto Sans Old Hungarian'},
        {name: 'Noto Sans Old Italic', value: 'Noto Sans Old Italic'},
        {name: 'Noto Sans Old North Arabian', value: 'Noto Sans Old North Arabian'},
        {name: 'Noto Sans Old Permic', value: 'Noto Sans Old Permic'},
        {name: 'Noto Sans Old Persian', value: 'Noto Sans Old Persian'},
        {name: 'Noto Sans Old Sogdian', value: 'Noto Sans Old Sogdian'},
        {name: 'Noto Sans Old South Arabian', value: 'Noto Sans Old South Arabian'},
        {name: 'Noto Sans Old Turkic', value: 'Noto Sans Old Turkic'},
        {name: 'Noto Sans Oriya', value: 'Noto Sans Oriya'},
        {name: 'Noto Sans Osage', value: 'Noto Sans Osage'},
        {name: 'Noto Sans Osmanya', value: 'Noto Sans Osmanya'},
        {name: 'Noto Sans Pahawh Hmong', value: 'Noto Sans Pahawh Hmong'},
        {name: 'Noto Sans Palmyrene', value: 'Noto Sans Palmyrene'},
        {name: 'Noto Sans Pau Cin Hau', value: 'Noto Sans Pau Cin Hau'},
        {name: 'Noto Sans Phags Pa', value: 'Noto Sans Phags Pa'},
        {name: 'Noto Sans Phoenician', value: 'Noto Sans Phoenician'},
        {name: 'Noto Sans Psalter Pahlavi', value: 'Noto Sans Psalter Pahlavi'},
        {name: 'Noto Sans Rejang', value: 'Noto Sans Rejang'},
        {name: 'Noto Sans Runic', value: 'Noto Sans Runic'},
        {name: 'Noto Sans SC', value: 'Noto Sans SC'},
        {name: 'Noto Sans Samaritan', value: 'Noto Sans Samaritan'},
        {name: 'Noto Sans Saurashtra', value: 'Noto Sans Saurashtra'},
        {name: 'Noto Sans Sharada', value: 'Noto Sans Sharada'},
        {name: 'Noto Sans Shavian', value: 'Noto Sans Shavian'},
        {name: 'Noto Sans Siddham', value: 'Noto Sans Siddham'},
        {name: 'Noto Sans Sinhala', value: 'Noto Sans Sinhala'},
        {name: 'Noto Sans Sogdian', value: 'Noto Sans Sogdian'},
        {name: 'Noto Sans Sora Sompeng', value: 'Noto Sans Sora Sompeng'},
        {name: 'Noto Sans Soyombo', value: 'Noto Sans Soyombo'},
        {name: 'Noto Sans Sundanese', value: 'Noto Sans Sundanese'},
        {name: 'Noto Sans Syloti Nagri', value: 'Noto Sans Syloti Nagri'},
        {name: 'Noto Sans Symbols', value: 'Noto Sans Symbols'},
        {name: 'Noto Sans Symbols 2', value: 'Noto Sans Symbols 2'},
        {name: 'Noto Sans Syriac', value: 'Noto Sans Syriac'},
        {name: 'Noto Sans TC', value: 'Noto Sans TC'},
        {name: 'Noto Sans Tagalog', value: 'Noto Sans Tagalog'},
        {name: 'Noto Sans Tagbanwa', value: 'Noto Sans Tagbanwa'},
        {name: 'Noto Sans Tai Le', value: 'Noto Sans Tai Le'},
        {name: 'Noto Sans Tai Tham', value: 'Noto Sans Tai Tham'},
        {name: 'Noto Sans Tai Viet', value: 'Noto Sans Tai Viet'},
        {name: 'Noto Sans Takri', value: 'Noto Sans Takri'},
        {name: 'Noto Sans Tamil', value: 'Noto Sans Tamil'},
        {name: 'Noto Sans Tamil Supplement', value: 'Noto Sans Tamil Supplement'},
        {name: 'Noto Sans Telugu', value: 'Noto Sans Telugu'},
        {name: 'Noto Sans Thaana', value: 'Noto Sans Thaana'},
        {name: 'Noto Sans Thai', value: 'Noto Sans Thai'},
        {name: 'Noto Sans Thai Looped', value: 'Noto Sans Thai Looped'},
        {name: 'Noto Sans Tifinagh', value: 'Noto Sans Tifinagh'},
        {name: 'Noto Sans Tirhuta', value: 'Noto Sans Tirhuta'},
        {name: 'Noto Sans Ugaritic', value: 'Noto Sans Ugaritic'},
        {name: 'Noto Sans Vai', value: 'Noto Sans Vai'},
        {name: 'Noto Sans Wancho', value: 'Noto Sans Wancho'},
        {name: 'Noto Sans Warang Citi', value: 'Noto Sans Warang Citi'},
        {name: 'Noto Sans Yi', value: 'Noto Sans Yi'},
        {name: 'Noto Sans Zanabazar Square', value: 'Noto Sans Zanabazar Square'},
        {name: 'Noto Serif', value: 'Noto Serif'},
        {name: 'Noto Serif Ahom', value: 'Noto Serif Ahom'},
        {name: 'Noto Serif Armenian', value: 'Noto Serif Armenian'},
        {name: 'Noto Serif Balinese', value: 'Noto Serif Balinese'},
        {name: 'Noto Serif Bengali', value: 'Noto Serif Bengali'},
        {name: 'Noto Serif Devanagari', value: 'Noto Serif Devanagari'},
        {name: 'Noto Serif Display', value: 'Noto Serif Display'},
        {name: 'Noto Serif Dogra', value: 'Noto Serif Dogra'},
        {name: 'Noto Serif Ethiopic', value: 'Noto Serif Ethiopic'},
        {name: 'Noto Serif Georgian', value: 'Noto Serif Georgian'},
        {name: 'Noto Serif Grantha', value: 'Noto Serif Grantha'},
        {name: 'Noto Serif Gujarati', value: 'Noto Serif Gujarati'},
        {name: 'Noto Serif Gurmukhi', value: 'Noto Serif Gurmukhi'},
        {name: 'Noto Serif Hebrew', value: 'Noto Serif Hebrew'},
        {name: 'Noto Serif JP', value: 'Noto Serif JP'},
        {name: 'Noto Serif KR', value: 'Noto Serif KR'},
        {name: 'Noto Serif Kannada', value: 'Noto Serif Kannada'},
        {name: 'Noto Serif Khmer', value: 'Noto Serif Khmer'},
        {name: 'Noto Serif Lao', value: 'Noto Serif Lao'},
        {name: 'Noto Serif Malayalam', value: 'Noto Serif Malayalam'},
        {name: 'Noto Serif Myanmar', value: 'Noto Serif Myanmar'},
        {name: 'Noto Serif Nyiakeng Puachue Hmong', value: 'Noto Serif Nyiakeng Puachue Hmong'},
        {name: 'Noto Serif SC', value: 'Noto Serif SC'},
        {name: 'Noto Serif Sinhala', value: 'Noto Serif Sinhala'},
        {name: 'Noto Serif TC', value: 'Noto Serif TC'},
        {name: 'Noto Serif Tamil', value: 'Noto Serif Tamil'},
        {name: 'Noto Serif Tangut', value: 'Noto Serif Tangut'},
        {name: 'Noto Serif Telugu', value: 'Noto Serif Telugu'},
        {name: 'Noto Serif Thai', value: 'Noto Serif Thai'},
        {name: 'Noto Serif Tibetan', value: 'Noto Serif Tibetan'},
        {name: 'Noto Serif Yezidi', value: 'Noto Serif Yezidi'},
        {name: 'Noto Traditional Nushu', value: 'Noto Traditional Nushu'},
        {name: 'Nova Cut', value: 'Nova Cut'},
        {name: 'Nova Flat', value: 'Nova Flat'},
        {name: 'Nova Mono', value: 'Nova Mono'},
        {name: 'Nova Oval', value: 'Nova Oval'},
        {name: 'Nova Round', value: 'Nova Round'},
        {name: 'Nova Script', value: 'Nova Script'},
        {name: 'Nova Slim', value: 'Nova Slim'},
        {name: 'Nova Square', value: 'Nova Square'},
        {name: 'Numans', value: 'Numans'},
        {name: 'Nunito', value: 'Nunito'},
        {name: 'Nunito Sans', value: 'Nunito Sans'},
        {name: 'Nuosu SIL', value: 'Nuosu SIL'},
        {name: 'Odibee Sans', value: 'Odibee Sans'},
        {name: 'Odor Mean Chey', value: 'Odor Mean Chey'},
        {name: 'Offside', value: 'Offside'},
        {name: 'Oi', value: 'Oi'},
        {name: 'Old Standard TT', value: 'Old Standard TT'},
        {name: 'Oldenburg', value: 'Oldenburg'},
        {name: 'Ole', value: 'Ole'},
        {name: 'Oleo Script', value: 'Oleo Script'},
        {name: 'Oleo Script Swash Caps', value: 'Oleo Script Swash Caps'},
        {name: 'Oooh Baby', value: 'Oooh Baby'},
        {name: 'Open Sans', value: 'Open Sans'},
        {name: 'Oranienbaum', value: 'Oranienbaum'},
        {name: 'Orbitron', value: 'Orbitron'},
        {name: 'Oregano', value: 'Oregano'},
        {name: 'Orelega One', value: 'Orelega One'},
        {name: 'Orienta', value: 'Orienta'},
        {name: 'Original Surfer', value: 'Original Surfer'},
        {name: 'Oswald', value: 'Oswald'},
        {name: 'Otomanopee One', value: 'Otomanopee One'},
        {name: 'Outfit', value: 'Outfit'},
        {name: 'Over the Rainbow', value: 'Over the Rainbow'},
        {name: 'Overlock', value: 'Overlock'},
        {name: 'Overlock SC', value: 'Overlock SC'},
        {name: 'Overpass', value: 'Overpass'},
        {name: 'Overpass Mono', value: 'Overpass Mono'},
        {name: 'Ovo', value: 'Ovo'},
        {name: 'Oxanium', value: 'Oxanium'},
        {name: 'Oxygen', value: 'Oxygen'},
        {name: 'Oxygen Mono', value: 'Oxygen Mono'},
        {name: 'PT Mono', value: 'PT Mono'},
        {name: 'PT Sans', value: 'PT Sans'},
        {name: 'PT Sans Caption', value: 'PT Sans Caption'},
        {name: 'PT Sans Narrow', value: 'PT Sans Narrow'},
        {name: 'PT Serif', value: 'PT Serif'},
        {name: 'PT Serif Caption', value: 'PT Serif Caption'},
        {name: 'Pacifico', value: 'Pacifico'},
        {name: 'Padauk', value: 'Padauk'},
        {name: 'Palanquin', value: 'Palanquin'},
        {name: 'Palanquin Dark', value: 'Palanquin Dark'},
        {name: 'Palette Mosaic', value: 'Palette Mosaic'},
        {name: 'Pangolin', value: 'Pangolin'},
        {name: 'Paprika', value: 'Paprika'},
        {name: 'Parisienne', value: 'Parisienne'},
        {name: 'Passero One', value: 'Passero One'},
        {name: 'Passion One', value: 'Passion One'},
        {name: 'Passions Conflict', value: 'Passions Conflict'},
        {name: 'Pathway Gothic One', value: 'Pathway Gothic One'},
        {name: 'Patrick Hand', value: 'Patrick Hand'},
        {name: 'Patrick Hand SC', value: 'Patrick Hand SC'},
        {name: 'Pattaya', value: 'Pattaya'},
        {name: 'Patua One', value: 'Patua One'},
        {name: 'Pavanam', value: 'Pavanam'},
        {name: 'Paytone One', value: 'Paytone One'},
        {name: 'Peddana', value: 'Peddana'},
        {name: 'Peralta', value: 'Peralta'},
        {name: 'Permanent Marker', value: 'Permanent Marker'},
        {name: 'Petemoss', value: 'Petemoss'},
        {name: 'Petit Formal Script', value: 'Petit Formal Script'},
        {name: 'Petrona', value: 'Petrona'},
        {name: 'Philosopher', value: 'Philosopher'},
        {name: 'Piazzolla', value: 'Piazzolla'},
        {name: 'Piedra', value: 'Piedra'},
        {name: 'Pinyon Script', value: 'Pinyon Script'},
        {name: 'Pirata One', value: 'Pirata One'},
        {name: 'Plaster', value: 'Plaster'},
        {name: 'Play', value: 'Play'},
        {name: 'Playball', value: 'Playball'},
        {name: 'Playfair Display', value: 'Playfair Display'},
        {name: 'Playfair Display SC', value: 'Playfair Display SC'},
        {name: 'Plus Jakarta Sans', value: 'Plus Jakarta Sans'},
        {name: 'Podkova', value: 'Podkova'},
        {name: 'Poiret One', value: 'Poiret One'},
        {name: 'Poller One', value: 'Poller One'},
        {name: 'Poly', value: 'Poly'},
        {name: 'Pompiere', value: 'Pompiere'},
        {name: 'Pontano Sans', value: 'Pontano Sans'},
        {name: 'Poor Story', value: 'Poor Story'},
        {name: 'Poppins', value: 'Poppins'},
        {name: 'Port Lligat Sans', value: 'Port Lligat Sans'},
        {name: 'Port Lligat Slab', value: 'Port Lligat Slab'},
        {name: 'Potta One', value: 'Potta One'},
        {name: 'Pragati Narrow', value: 'Pragati Narrow'},
        {name: 'Praise', value: 'Praise'},
        {name: 'Prata', value: 'Prata'},
        {name: 'Preahvihear', value: 'Preahvihear'},
        {name: 'Press Start 2P', value: 'Press Start 2P'},
        {name: 'Pridi', value: 'Pridi'},
        {name: 'Princess Sofia', value: 'Princess Sofia'},
        {name: 'Prociono', value: 'Prociono'},
        {name: 'Prompt', value: 'Prompt'},
        {name: 'Prosto One', value: 'Prosto One'},
        {name: 'Proza Libre', value: 'Proza Libre'},
        {name: 'Public Sans', value: 'Public Sans'},
        {name: 'Puppies Play', value: 'Puppies Play'},
        {name: 'Puritan', value: 'Puritan'},
        {name: 'Purple Purse', value: 'Purple Purse'},
        {name: 'Qahiri', value: 'Qahiri'},
        {name: 'Quando', value: 'Quando'},
        {name: 'Quantico', value: 'Quantico'},
        {name: 'Quattrocento', value: 'Quattrocento'},
        {name: 'Quattrocento Sans', value: 'Quattrocento Sans'},
        {name: 'Questrial', value: 'Questrial'},
        {name: 'Quicksand', value: 'Quicksand'},
        {name: 'Quintessential', value: 'Quintessential'},
        {name: 'Qwigley', value: 'Qwigley'},
        {name: 'Qwitcher Grypen', value: 'Qwitcher Grypen'},
        {name: 'Racing Sans One', value: 'Racing Sans One'},
        {name: 'Radio Canada', value: 'Radio Canada'},
        {name: 'Radley', value: 'Radley'},
        {name: 'Rajdhani', value: 'Rajdhani'},
        {name: 'Rakkas', value: 'Rakkas'},
        {name: 'Raleway', value: 'Raleway'},
        {name: 'Raleway Dots', value: 'Raleway Dots'},
        {name: 'Ramabhadra', value: 'Ramabhadra'},
        {name: 'Ramaraja', value: 'Ramaraja'},
        {name: 'Rambla', value: 'Rambla'},
        {name: 'Rammetto One', value: 'Rammetto One'},
        {name: 'Rampart One', value: 'Rampart One'},
        {name: 'Ranchers', value: 'Ranchers'},
        {name: 'Rancho', value: 'Rancho'},
        {name: 'Ranga', value: 'Ranga'},
        {name: 'Rasa', value: 'Rasa'},
        {name: 'Rationale', value: 'Rationale'},
        {name: 'Ravi Prakash', value: 'Ravi Prakash'},
        {name: 'Readex Pro', value: 'Readex Pro'},
        {name: 'Recursive', value: 'Recursive'},
        {name: 'Red Hat Display', value: 'Red Hat Display'},
        {name: 'Red Hat Mono', value: 'Red Hat Mono'},
        {name: 'Red Hat Text', value: 'Red Hat Text'},
        {name: 'Red Rose', value: 'Red Rose'},
        {name: 'Redacted', value: 'Redacted'},
        {name: 'Redacted Script', value: 'Redacted Script'},
        {name: 'Redressed', value: 'Redressed'},
        {name: 'Reem Kufi', value: 'Reem Kufi'},
        {name: 'Reenie Beanie', value: 'Reenie Beanie'},
        {name: 'Reggae One', value: 'Reggae One'},
        {name: 'Revalia', value: 'Revalia'},
        {name: 'Rhodium Libre', value: 'Rhodium Libre'},
        {name: 'Ribeye', value: 'Ribeye'},
        {name: 'Ribeye Marrow', value: 'Ribeye Marrow'},
        {name: 'Righteous', value: 'Righteous'},
        {name: 'Risque', value: 'Risque'},
        {name: 'Road Rage', value: 'Road Rage'},
        {name: 'Roboto', value: 'Roboto'},
        {name: 'Roboto Condensed', value: 'Roboto Condensed'},
        {name: 'Roboto Flex', value: 'Roboto Flex'},
        {name: 'Roboto Mono', value: 'Roboto Mono'},
        {name: 'Roboto Serif', value: 'Roboto Serif'},
        {name: 'Roboto Slab', value: 'Roboto Slab'},
        {name: 'Rochester', value: 'Rochester'},
        {name: 'Rock 3D', value: 'Rock 3D'},
        {name: 'Rock Salt', value: 'Rock Salt'},
        {name: 'RocknRoll One', value: 'RocknRoll One'},
        {name: 'Rokkitt', value: 'Rokkitt'},
        {name: 'Romanesco', value: 'Romanesco'},
        {name: 'Ropa Sans', value: 'Ropa Sans'},
        {name: 'Rosario', value: 'Rosario'},
        {name: 'Rosarivo', value: 'Rosarivo'},
        {name: 'Rouge Script', value: 'Rouge Script'},
        {name: 'Rowdies', value: 'Rowdies'},
        {name: 'Rozha One', value: 'Rozha One'},
        {name: 'Rubik', value: 'Rubik'},
        {name: 'Rubik Beastly', value: 'Rubik Beastly'},
        {name: 'Rubik Bubbles', value: 'Rubik Bubbles'},
        {name: 'Rubik Glitch', value: 'Rubik Glitch'},
        {name: 'Rubik Microbe', value: 'Rubik Microbe'},
        {name: 'Rubik Mono One', value: 'Rubik Mono One'},
        {name: 'Rubik Moonrocks', value: 'Rubik Moonrocks'},
        {name: 'Rubik Puddles', value: 'Rubik Puddles'},
        {name: 'Rubik Wet Paint', value: 'Rubik Wet Paint'},
        {name: 'Ruda', value: 'Ruda'},
        {name: 'Rufina', value: 'Rufina'},
        {name: 'Ruge Boogie', value: 'Ruge Boogie'},
        {name: 'Ruluko', value: 'Ruluko'},
        {name: 'Rum Raisin', value: 'Rum Raisin'},
        {name: 'Ruslan Display', value: 'Ruslan Display'},
        {name: 'Russo One', value: 'Russo One'},
        {name: 'Ruthie', value: 'Ruthie'},
        {name: 'Rye', value: 'Rye'},
        {name: 'STIX Two Text', value: 'STIX Two Text'},
        {name: 'Sacramento', value: 'Sacramento'},
        {name: 'Sahitya', value: 'Sahitya'},
        {name: 'Sail', value: 'Sail'},
        {name: 'Saira', value: 'Saira'},
        {name: 'Saira Condensed', value: 'Saira Condensed'},
        {name: 'Saira Extra Condensed', value: 'Saira Extra Condensed'},
        {name: 'Saira Semi Condensed', value: 'Saira Semi Condensed'},
        {name: 'Saira Stencil One', value: 'Saira Stencil One'},
        {name: 'Salsa', value: 'Salsa'},
        {name: 'Sanchez', value: 'Sanchez'},
        {name: 'Sancreek', value: 'Sancreek'},
        {name: 'Sansita', value: 'Sansita'},
        {name: 'Sansita Swashed', value: 'Sansita Swashed'},
        {name: 'Sarabun', value: 'Sarabun'},
        {name: 'Sarala', value: 'Sarala'},
        {name: 'Sarina', value: 'Sarina'},
        {name: 'Sarpanch', value: 'Sarpanch'},
        {name: 'Sassy Frass', value: 'Sassy Frass'},
        {name: 'Satisfy', value: 'Satisfy'},
        {name: 'Sawarabi Gothic', value: 'Sawarabi Gothic'},
        {name: 'Sawarabi Mincho', value: 'Sawarabi Mincho'},
        {name: 'Scada', value: 'Scada'},
        {name: 'Scheherazade New', value: 'Scheherazade New'},
        {name: 'Schoolbell', value: 'Schoolbell'},
        {name: 'Scope One', value: 'Scope One'},
        {name: 'Seaweed Script', value: 'Seaweed Script'},
        {name: 'Secular One', value: 'Secular One'},
        {name: 'Sedgwick Ave', value: 'Sedgwick Ave'},
        {name: 'Sedgwick Ave Display', value: 'Sedgwick Ave Display'},
        {name: 'Sen', value: 'Sen'},
        {name: 'Send Flowers', value: 'Send Flowers'},
        {name: 'Sevillana', value: 'Sevillana'},
        {name: 'Seymour One', value: 'Seymour One'},
        {name: 'Shadows Into Light', value: 'Shadows Into Light'},
        {name: 'Shadows Into Light Two', value: 'Shadows Into Light Two'},
        {name: 'Shalimar', value: 'Shalimar'},
        {name: 'Shanti', value: 'Shanti'},
        {name: 'Share', value: 'Share'},
        {name: 'Share Tech', value: 'Share Tech'},
        {name: 'Share Tech Mono', value: 'Share Tech Mono'},
        {name: 'Shippori Antique', value: 'Shippori Antique'},
        {name: 'Shippori Antique B1', value: 'Shippori Antique B1'},
        {name: 'Shippori Mincho', value: 'Shippori Mincho'},
        {name: 'Shippori Mincho B1', value: 'Shippori Mincho B1'},
        {name: 'Shizuru', value: 'Shizuru'},
        {name: 'Shojumaru', value: 'Shojumaru'},
        {name: 'Short Stack', value: 'Short Stack'},
        {name: 'Shrikhand', value: 'Shrikhand'},
        {name: 'Siemreap', value: 'Siemreap'},
        {name: 'Sigmar One', value: 'Sigmar One'},
        {name: 'Signika', value: 'Signika'},
        {name: 'Signika Negative', value: 'Signika Negative'},
        {name: 'Simonetta', value: 'Simonetta'},
        {name: 'Single Day', value: 'Single Day'},
        {name: 'Sintony', value: 'Sintony'},
        {name: 'Sirin Stencil', value: 'Sirin Stencil'},
        {name: 'Six Caps', value: 'Six Caps'},
        {name: 'Skranji', value: 'Skranji'},
        {name: 'Slabo 13px', value: 'Slabo 13px'},
        {name: 'Slabo 27px', value: 'Slabo 27px'},
        {name: 'Slackey', value: 'Slackey'},
        {name: 'Smokum', value: 'Smokum'},
        {name: 'Smooch', value: 'Smooch'},
        {name: 'Smooch Sans', value: 'Smooch Sans'},
        {name: 'Smythe', value: 'Smythe'},
        {name: 'Sniglet', value: 'Sniglet'},
        {name: 'Snippet', value: 'Snippet'},
        {name: 'Snowburst One', value: 'Snowburst One'},
        {name: 'Sofadi One', value: 'Sofadi One'},
        {name: 'Sofia', value: 'Sofia'},
        {name: 'Solway', value: 'Solway'},
        {name: 'Song Myung', value: 'Song Myung'},
        {name: 'Sonsie One', value: 'Sonsie One'},
        {name: 'Sora', value: 'Sora'},
        {name: 'Sorts Mill Goudy', value: 'Sorts Mill Goudy'},
        {name: 'Source Code Pro', value: 'Source Code Pro'},
        {name: 'Source Sans 3', value: 'Source Sans 3'},
        {name: 'Source Sans Pro', value: 'Source Sans Pro'},
        {name: 'Source Serif 4', value: 'Source Serif 4'},
        {name: 'Source Serif Pro', value: 'Source Serif Pro'},
        {name: 'Space Grotesk', value: 'Space Grotesk'},
        {name: 'Space Mono', value: 'Space Mono'},
        {name: 'Special Elite', value: 'Special Elite'},
        {name: 'Spectral', value: 'Spectral'},
        {name: 'Spectral SC', value: 'Spectral SC'},
        {name: 'Spicy Rice', value: 'Spicy Rice'},
        {name: 'Spinnaker', value: 'Spinnaker'},
        {name: 'Spirax', value: 'Spirax'},
        {name: 'Spline Sans', value: 'Spline Sans'},
        {name: 'Spline Sans Mono', value: 'Spline Sans Mono'},
        {name: 'Squada One', value: 'Squada One'},
        {name: 'Square Peg', value: 'Square Peg'},
        {name: 'Sree Krushnadevaraya', value: 'Sree Krushnadevaraya'},
        {name: 'Sriracha', value: 'Sriracha'},
        {name: 'Srisakdi', value: 'Srisakdi'},
        {name: 'Staatliches', value: 'Staatliches'},
        {name: 'Stalemate', value: 'Stalemate'},
        {name: 'Stalinist One', value: 'Stalinist One'},
        {name: 'Stardos Stencil', value: 'Stardos Stencil'},
        {name: 'Stick', value: 'Stick'},
        {name: 'Stick No Bills', value: 'Stick No Bills'},
        {name: 'Stint Ultra Condensed', value: 'Stint Ultra Condensed'},
        {name: 'Stint Ultra Expanded', value: 'Stint Ultra Expanded'},
        {name: 'Stoke', value: 'Stoke'},
        {name: 'Strait', value: 'Strait'},
        {name: 'Style Script', value: 'Style Script'},
        {name: 'Stylish', value: 'Stylish'},
        {name: 'Sue Ellen Francisco', value: 'Sue Ellen Francisco'},
        {name: 'Suez One', value: 'Suez One'},
        {name: 'Sulphur Point', value: 'Sulphur Point'},
        {name: 'Sumana', value: 'Sumana'},
        {name: 'Sunflower', value: 'Sunflower'},
        {name: 'Sunshiney', value: 'Sunshiney'},
        {name: 'Supermercado One', value: 'Supermercado One'},
        {name: 'Sura', value: 'Sura'},
        {name: 'Suranna', value: 'Suranna'},
        {name: 'Suravaram', value: 'Suravaram'},
        {name: 'Suwannaphum', value: 'Suwannaphum'},
        {name: 'Swanky and Moo Moo', value: 'Swanky and Moo Moo'},
        {name: 'Syncopate', value: 'Syncopate'},
        {name: 'Syne', value: 'Syne'},
        {name: 'Syne Mono', value: 'Syne Mono'},
        {name: 'Syne Tactile', value: 'Syne Tactile'},
        {name: 'Tai Heritage Pro', value: 'Tai Heritage Pro'},
        {name: 'Tajawal', value: 'Tajawal'},
        {name: 'Tangerine', value: 'Tangerine'},
        {name: 'Tapestry', value: 'Tapestry'},
        {name: 'Taprom', value: 'Taprom'},
        {name: 'Tauri', value: 'Tauri'},
        {name: 'Taviraj', value: 'Taviraj'},
        {name: 'Teko', value: 'Teko'},
        {name: 'Telex', value: 'Telex'},
        {name: 'Tenali Ramakrishna', value: 'Tenali Ramakrishna'},
        {name: 'Tenor Sans', value: 'Tenor Sans'},
        {name: 'Text Me One', value: 'Text Me One'},
        {name: 'Texturina', value: 'Texturina'},
        {name: 'Thasadith', value: 'Thasadith'},
        {name: 'The Girl Next Door', value: 'The Girl Next Door'},
        {name: 'The Nautigal', value: 'The Nautigal'},
        {name: 'Tienne', value: 'Tienne'},
        {name: 'Tillana', value: 'Tillana'},
        {name: 'Timmana', value: 'Timmana'},
        {name: 'Tinos', value: 'Tinos'},
        {name: 'Tiro Bangla', value: 'Tiro Bangla'},
        {name: 'Tiro Devanagari Hindi', value: 'Tiro Devanagari Hindi'},
        {name: 'Tiro Devanagari Marathi', value: 'Tiro Devanagari Marathi'},
        {name: 'Tiro Devanagari Sanskrit', value: 'Tiro Devanagari Sanskrit'},
        {name: 'Tiro Gurmukhi', value: 'Tiro Gurmukhi'},
        {name: 'Tiro Kannada', value: 'Tiro Kannada'},
        {name: 'Tiro Tamil', value: 'Tiro Tamil'},
        {name: 'Tiro Telugu', value: 'Tiro Telugu'},
        {name: 'Titan One', value: 'Titan One'},
        {name: 'Titillium Web', value: 'Titillium Web'},
        {name: 'Tomorrow', value: 'Tomorrow'},
        {name: 'Tourney', value: 'Tourney'},
        {name: 'Trade Winds', value: 'Trade Winds'},
        {name: 'Train One', value: 'Train One'},
        {name: 'Trirong', value: 'Trirong'},
        {name: 'Trispace', value: 'Trispace'},
        {name: 'Trocchi', value: 'Trocchi'},
        {name: 'Trochut', value: 'Trochut'},
        {name: 'Truculenta', value: 'Truculenta'},
        {name: 'Trykker', value: 'Trykker'},
        {name: 'Tulpen One', value: 'Tulpen One'},
        {name: 'Turret Road', value: 'Turret Road'},
        {name: 'Twinkle Star', value: 'Twinkle Star'},
        {name: 'Ubuntu', value: 'Ubuntu'},
        {name: 'Ubuntu Condensed', value: 'Ubuntu Condensed'},
        {name: 'Ubuntu Mono', value: 'Ubuntu Mono'},
        {name: 'Uchen', value: 'Uchen'},
        {name: 'Ultra', value: 'Ultra'},
        {name: 'Uncial Antiqua', value: 'Uncial Antiqua'},
        {name: 'Underdog', value: 'Underdog'},
        {name: 'Unica One', value: 'Unica One'},
        {name: 'UnifrakturCook', value: 'UnifrakturCook'},
        {name: 'UnifrakturMaguntia', value: 'UnifrakturMaguntia'},
        {name: 'Unkempt', value: 'Unkempt'},
        {name: 'Unlock', value: 'Unlock'},
        {name: 'Unna', value: 'Unna'},
        {name: 'Updock', value: 'Updock'},
        {name: 'Urbanist', value: 'Urbanist'},
        {name: 'VT323', value: 'VT323'},
        {name: 'Vampiro One', value: 'Vampiro One'},
        {name: 'Varela', value: 'Varela'},
        {name: 'Varela Round', value: 'Varela Round'},
        {name: 'Varta', value: 'Varta'},
        {name: 'Vast Shadow', value: 'Vast Shadow'},
        {name: 'Vazirmatn', value: 'Vazirmatn'},
        {name: 'Vesper Libre', value: 'Vesper Libre'},
        {name: 'Viaoda Libre', value: 'Viaoda Libre'},
        {name: 'Vibes', value: 'Vibes'},
        {name: 'Vibur', value: 'Vibur'},
        {name: 'Vidaloka', value: 'Vidaloka'},
        {name: 'Viga', value: 'Viga'},
        {name: 'Voces', value: 'Voces'},
        {name: 'Volkhov', value: 'Volkhov'},
        {name: 'Vollkorn', value: 'Vollkorn'},
        {name: 'Vollkorn SC', value: 'Vollkorn SC'},
        {name: 'Voltaire', value: 'Voltaire'},
        {name: 'Vujahday Script', value: 'Vujahday Script'},
        {name: 'Waiting for the Sunrise', value: 'Waiting for the Sunrise'},
        {name: 'Wallpoet', value: 'Wallpoet'},
        {name: 'Walter Turncoat', value: 'Walter Turncoat'},
        {name: 'Warnes', value: 'Warnes'},
        {name: 'Water Brush', value: 'Water Brush'},
        {name: 'Waterfall', value: 'Waterfall'},
        {name: 'Wellfleet', value: 'Wellfleet'},
        {name: 'Wendy One', value: 'Wendy One'},
        {name: 'Whisper', value: 'Whisper'},
        {name: 'WindSong', value: 'WindSong'},
        {name: 'Wire One', value: 'Wire One'},
        {name: 'Work Sans', value: 'Work Sans'},
        {name: 'Xanh Mono', value: 'Xanh Mono'},
        {name: 'Yaldevi', value: 'Yaldevi'},
        {name: 'Yanone Kaffeesatz', value: 'Yanone Kaffeesatz'},
        {name: 'Yantramanav', value: 'Yantramanav'},
        {name: 'Yatra One', value: 'Yatra One'},
        {name: 'Yellowtail', value: 'Yellowtail'},
        {name: 'Yeon Sung', value: 'Yeon Sung'},
        {name: 'Yeseva One', value: 'Yeseva One'},
        {name: 'Yesteryear', value: 'Yesteryear'},
        {name: 'Yomogi', value: 'Yomogi'},
        {name: 'Yrsa', value: 'Yrsa'},
        {name: 'Yuji Boku', value: 'Yuji Boku'},
        {name: 'Yuji Hentaigana Akari', value: 'Yuji Hentaigana Akari'},
        {name: 'Yuji Hentaigana Akebono', value: 'Yuji Hentaigana Akebono'},
        {name: 'Yuji Mai', value: 'Yuji Mai'},
        {name: 'Yuji Syuku', value: 'Yuji Syuku'},
        {name: 'Yusei Magic', value: 'Yusei Magic'},
        {name: 'ZCOOL KuaiLe', value: 'ZCOOL KuaiLe'},
        {name: 'ZCOOL QingKe HuangYou', value: 'ZCOOL QingKe HuangYou'},
        {name: 'ZCOOL XiaoWei', value: 'ZCOOL XiaoWei'},
        {name: 'Zen Antique', value: 'Zen Antique'},
        {name: 'Zen Antique Soft', value: 'Zen Antique Soft'},
        {name: 'Zen Dots', value: 'Zen Dots'},
        {name: 'Zen Kaku Gothic Antique', value: 'Zen Kaku Gothic Antique'},
        {name: 'Zen Kaku Gothic New', value: 'Zen Kaku Gothic New'},
        {name: 'Zen Kurenaido', value: 'Zen Kurenaido'},
        {name: 'Zen Loop', value: 'Zen Loop'},
        {name: 'Zen Maru Gothic', value: 'Zen Maru Gothic'},
        {name: 'Zen Old Mincho', value: 'Zen Old Mincho'},
        {name: 'Zen Tokyo Zoo', value: 'Zen Tokyo Zoo'},
        {name: 'Zeyada', value: 'Zeyada'},
        {name: 'Zhi Mang Xing', value: 'Zhi Mang Xing'},
        {name: 'Zilla Slab', value: 'Zilla Slab'},
        {name: 'Zilla Slab Highlight', value: 'Zilla Slab Highlight'},
    ];

    public static mergeGACode(ga3Id, ga4Id) {
        let gaCode = '';
        if (ga3Id && !ga4Id) {
            gaCode = ga3Id;
        } else if (!ga3Id && ga4Id) {
            gaCode = ga4Id;
        } else if (ga3Id && ga4Id) {
            gaCode = ga3Id + ', ' + ga4Id;
        } else {
            gaCode = '';
        }
        return gaCode;
    }

    public static validateGA3Code(gaCode) {
        return this.ga3Regex.test(gaCode);
    }

    public static validateGA4Code(gaCode) {
        return this.ga4Regex.test(gaCode);
    }

    public static getValueAtPath(object: object, path: string): any {
        const splitPath = path.split('.');
        if (!object) {
            return null;
        }
        let result = object;
        for (const key of splitPath) {
            result = result[key];
            if (!result) {
                return null;
            }
        }
        return result;
    }

    public static downloadCSV(csvData, args): void {
        let data, filename, link;
        let csv = this.convertArrayOfObjectsToCSV(csvData);

        if (csv === null) {
            return;
        }

        filename = args || 'export.csv';

        const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
            ie11 = navigator.userAgent.match(/Trident\/7.0/) && navigator.userAgent.match(/rv:11/),
            ieEDGE = navigator.userAgent.match(/Edge/g),
            ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;

        const textFileAsBlob = new Blob([csv], {
            type: 'text/csv',
        });

        if (ieVer > -1) {
            (window.navigator as any).msSaveBlob(textFileAsBlob, filename);
        } else {
            if (!csv.match(/^data:text\/csv/i)) {
                csv = 'data:text/csv;charset=utf-8,' + csv;
            }
            data = encodeURI(csv);

            link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    private static convertArrayOfObjectsToCSV(args) {
        let result, ctr, keys, columnDelimiter, lineDelimiter, data;

        data = args || null;
        if (data === null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) {
                    result += columnDelimiter;
                }

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    public static modelComparator(f1: any, f2: any) {
        if (!f1 || !f2) {
            return !f1 && !f2;
        }
        return f1.id === f2.id;
    }

    public static get EddyCampaignTypes() {
        return EddyCampaignTypes;
    }

    public static isCreateNewContent(campaign, type: EddyCampaignTypes) {
        if (type !== EddyCampaignTypes.Schedule && campaign.id === -1) {
            return true;
        } else if (campaign.id === -1 || campaign.id === -2) {
            return true;
        }
    }

    public static switchOrganization(id: number, authService: AuthService): void {
        const requestedOrg = authService.getOrgByID(id);
        // We are checking one key of the organization object to check if it exists wholly
        // Potential bug : If the requestedOrg starts including the created key in organization object, this will fail
        if (!requestedOrg?.created) {
            authService.fetchOrganizationByID(requestedOrg.id).subscribe((org) => {
                const organization = new Organization(org);
                authService.setCurrentOrgObjInLocalStorage(organization);
            });
        } else {
            authService.setCurrentOrgObjInLocalStorage(authService.getOrgByID(id));
        }
    }

    public static blobToFile(theBlob: Blob, fileName: string): File {
        const blob: any = theBlob;
        blob.lastModifiedDate = new Date();
        blob.name = fileName;

        return <File>theBlob;
    }

    public static addHttpsToUrl(url: string) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        return url;
    }

    public static ObjectKeys(object) {
        return Object.keys(object);
    }

    public static isWhiteLabeledDashboard() {
        const host = window.location.hostname.toLowerCase();
        return (
            (BEACONSTAC_HOSTS.indexOf(host) === -1 && host.indexOf('beaconstac.com') === -1 && host.indexOf('uniqode.com') === -1) ||
            host === BEACONSTAC_ADMIN
        );
    }

    public static disableReturnButton(span): void {
        span.addEventListener('keydown', (evt) => {
            if (evt.which === 13) {
                evt.preventDefault();
            }
        });
    }

    public static getDateDifference(d1, d2, unit?: 'day' | 'minute' | 'month' | 'year') {
        const m1 = moment(d1);
        const m2 = moment(d2);
        return m1.diff(m2, unit);
    }

    public static getErrorMessage(error) {
        for (const key of Object.keys(error)) {
            if (typeof error[key] === 'string') {
                return this.capitalizeFirstLetter(error[key].split('_').join(' '));
            } else if (typeof error[key] === 'object') {
                return this.getErrorMessage(error[key]);
            } else {
                return null;
            }
        }
    }

    public static disableUserInteraction() {
        const inputs = document.getElementsByTagName('input');
        if (inputs.length > 0) {
            let i;
            for (i = 0; i < inputs.length; i++) {
                inputs[i].setAttribute('disabled', 'true');
            }
        }
        const select = document.getElementsByTagName('select');
        if (select.length > 0) {
            let i;
            for (i = 0; i < select.length; i++) {
                select[i].setAttribute('disabled', 'true');
            }
        }
        const textarea = document.getElementsByTagName('textarea');
        if (textarea.length > 0) {
            let i;
            for (i = 0; i < textarea.length; i++) {
                textarea[i].setAttribute('disabled', 'true');
            }
        }
    }

    public static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public static escapeRegExp(re) {
        return re.replace(/[.+*?|^$[\]{}()\\]/g, '\\$&');
    }

    public static getQueryMap(query) {
        if (typeof query !== 'string') {
            return null;
        }

        const toType = function (a) {
                return {}.toString
                    .call(a)
                    .match(/\s([a-zA-Z]+)/)[1]
                    .toLowerCase();
            },
            map = {};

        const sanitize = function (match: string, key: string, value: string) {
            if (key in map) {
                if (toType(map[key]) !== 'array') {
                    map[key] = [map[key]];
                }
                map[key].push(decodeURIComponent(value));
            } else {
                map[key] = decodeURIComponent(value);
            }
        };

        // @ts-ignore
        query.replace(/([^&|\?=]+)=?([^&]*)(?:&+|$)/g, sanitize);
        return map;
    }

    public static available_title_font_size = [
        {name: '18', value: 18},
        {name: '20', value: 20},
        {name: '22', value: 22},
        {name: '24', value: 24},
        {name: '26', value: 26},
        {name: '28', value: 28},
        {name: '30', value: 30},
    ];
    public static GOOGLE_FONT_MAPPING = {
        'Regular': 400,
        'Semi Bold': 600,
        'Bold': 700,
        'Medium': 500,
        'Light': 300
    }
    public static available_google_fonts = [
        {name: 'Light', value: 'Light'},
        {name: 'Regular', value: 'Regular'},
        {name: 'Medium', value: 'Medium'},
        {name: 'Semi Bold', value: 'Semi Bold'},
        {name: 'Bold', value: 'Bold'},
    ]
    public static typography_customization_font_size = [
        {name: '14', value: 14},
        {name: '16', value: 16},
        {name: '18', value: 18},
        {name: '20', value: 20},
        {name: '22', value: 22},
        {name: '24', value: 24},
        {name: '26', value: 26},
        {name: '28', value: 28},
        {name: '30', value: 30},
    ];
    public static PHONE_LABELS = [
        {name: 'No Label', value: ''},
        {name: 'Mobile', value: 'Mobile'},
        {name: 'Work', value: 'Work'},
        {name: 'Home', value: 'Home'},
        {name: 'Main', value: 'Main'},
        {name: 'Home Fax', value: 'Home Fax'},
        {name: 'Work Fax', value: 'Work Fax'},
        {name: 'Pager', value: 'Pager'},
        {name: 'Personal', value: 'Personal'},
        {name: 'Office', value: 'Office'},
        {name: 'MVPN', value: 'MVPN'},
        {name: 'Other', value: 'Other'},
    ];

    public static EMAIL_LABELS = [
        {name: 'No Label', value: ''},
        {name: 'Work', value: 'Work'},
        {name: 'Personal ', value: 'Personal'},
        {name: 'Other', value: 'Other'},
    ];

    public static WEBSITE_LABELS = [
        {name: 'No Label', value: ''},
        {name: 'Personal', value: 'Personal'},
        {name: 'Work', value: 'Work'},
        {name: 'Company', value: 'Company'},
        {name: 'Social', value: 'Social'},
        {name: 'Portfolio', value: 'Portfolio'},
        {name: 'Other', value: 'Other'},
    ];

    public static getJSON(url) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'json';
            xhr.onload = function () {
                const status = xhr.status;
                if (status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(xhr.response);
                }
            };
            xhr.send();
        });
    }

    public static PAGINATION_VALUES = [
        {name: '10', value: '10'},
        {name: '20', value: '20'},
        {name: '50', value: '50'},
        {name: '100', value: '100'},
    ];

    public static getReadableGroup(user_group): string {
        switch (user_group) {
            case USER_GROUPS.Admin:
                return 'Admin';
            case USER_GROUPS.Owner:
                return 'Owner';
            case USER_GROUPS.ReadOnly:
                return 'Viewer';
            case USER_GROUPS.SandBox:
                return 'Editor';
            default:
                return '';
        }
    }

    public static BULK_UPLOAD_DOCS_LINK =
        'https://docs.beaconstac.com/en/articles/3180578-how-to-create-qr-codes-in-bulk';

    public static readonly PREMIUM_LAYOUTS_LIST = [7, 8, 9];
    public static readonly FIRST_FLOW_LAYOUTS_LIST = [4, 1];

    public static VCARD_LAYOUTS = [
        {
            imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_4.svg`,
            name: '4',
            profile_photo: 'https://beaconstacqa.mobstac.com//static/images/dbc/ashley.png',
            first_name: 'Ashley James',
            last_name: 'Fletcher',
            prefix: 'Dr.',
            designation: 'CXO, Founding Designer',
            pronoun: 'she/her',
            company: 'Amazon Design Services',
            summary: 'I lead design across teams for Amazon and it\'s subsidiaries.',
            customizations: {
                background_color: '#FF9900',
                user_info_color: '#000000',
                secondary_color: '#000000',
                font_style: 'Open Sans',
                title_font_size: 24,
                font_type: 'google',
                custom_font_url: '',
                custom_font_style: '',
                profile_info: 'Bold',
                company_details: 'Regular',
                contact_details: 'Medium',
                button: 'Bold',
                typography: {
                    font_type: 'google',
                    font_family: 'Open Sans',
                    personal_info: getDefaultForPersonalInfo('4'),
                    company_details: getDefaultForCompanyDetails('4'),
                    contact_details: getDefaultForContactDetails('4'),
                    button: getDefaultForButton('4'),
                    bio: getDefaultForBio('4'),
                },
                button_color: '#FF9900',
                icon_color: '#FF9900',
                background: {
                    type: DBC_BACKGROUND_TYPE.Color,
                    value: '#FFFFFF'
                }
            },
            vcardImageAddress: 'DBC4.png'
        },
        {
            imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_5.svg`,
            name: '5',
            profile_photo: 'https://beaconstacqa.mobstac.com//static/images/dbc/james.png',
            first_name: 'James',
            last_name: 'McFinnigan',
            prefix: '',
            designation: 'Product Lead',
            pronoun: 'he/him',
            company: 'Nike Sports',
            summary:
                'I lead product across teams for Nike and I ski in my free time. I love to work on AR/VR and have created product strategies for the same.',
            customizations: {
                background_color: '#EE4F4F',
                user_info_color: '#FFFFFF',
                secondary_color: '#000000',
                font_style: 'Open Sans',
                title_font_size: 24,
                font_type: 'google',
                custom_font_url: '',
                custom_font_style: '',
                profile_info: 'Bold',
                company_details: 'Regular',
                contact_details: 'Medium',
                button: 'Bold',
                typography: {
                    font_type: 'google',
                    font_family: 'Open Sans',
                    personal_info: getDefaultForPersonalInfo('5'),
                    company_details: getDefaultForCompanyDetails('5'),
                    contact_details: getDefaultForContactDetails('5'),
                    button: getDefaultForButton('5'),
                    bio: getDefaultForBio('5'),
                },
                button_color: '#EE4F4F',
                icon_color: '#EE4F4F',
                background: {
                    type: DBC_BACKGROUND_TYPE.Color,
                    value: '#FFFFFF'
                }
            },
            vcardImageAddress: 'DBC5.png'
        },
        {
            imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_6.svg`,
            name: '6',
            profile_photo: 'https://beaconstacqa.mobstac.com//static/images/dbc/joe.png',
            first_name: 'Joe',
            last_name: 'Tribianni',
            prefix: '',
            designation: 'CTO',
            pronoun: 'he/him',
            company: 'IBM Cloud Services',
            summary: 'I lead tech across teams for IBM and I ski in my free time.',
            customizations: {
                background_color: '#1F70C1',
                user_info_color: '#000000',
                secondary_color: '#000000',
                font_style: 'Open Sans',
                title_font_size: 24,
                font_type: 'google',
                custom_font_url: '',
                custom_font_style: '',
                profile_info: 'Bold',
                company_details: 'Regular',
                contact_details: 'Medium',
                button: 'Bold',
                typography: {
                    font_type: 'google',
                    font_family: 'Open Sans',
                    personal_info: getDefaultForPersonalInfo('6'),
                    company_details: getDefaultForCompanyDetails('6'),
                    contact_details: getDefaultForContactDetails('6'),
                    button: getDefaultForButton('6'),
                    bio: getDefaultForBio('6'),
                },
                button_color: '#1F70C1',
                icon_color: '#1F70C1',
                background: {
                    type: DBC_BACKGROUND_TYPE.Color,
                    value: '#FFFFFF'
                }
            },
            vcardImageAddress: 'DBC6.png'
        },
        {
            imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_2.svg`,
            name: '1',
            profile_photo: 'https://beaconstacqa.mobstac.com//static/images/dbc/max.png',
            first_name: 'Max',
            last_name: 'McGonagall',
            prefix: '',
            designation: 'Automotive Engineer',
            pronoun: 'he/him',
            company: 'Aryam Motors',
            summary: 'I handle production and maintenance of our new line of electric cars here at Aryam Motors.',
            customizations: {
                background_color: '#5e53a3',
                user_info_color: '#000000',
                secondary_color: '#000000',
                font_style: 'Roboto',
                title_font_size: 30,
                font_type: 'google',
                custom_font_url: '',
                custom_font_style: '',
                profile_info: 'Bold',
                company_details: 'Regular',
                contact_details: 'Medium',
                button: 'Bold',
                typography: {
                    font_type: 'google',
                    font_family: 'Open Sans',
                    personal_info: getDefaultForPersonalInfo('1'),
                    company_details: getDefaultForCompanyDetails('1'),
                    contact_details: getDefaultForContactDetails('1'),
                    button: getDefaultForButton('1'),
                    bio: getDefaultForBio('1'),
                },
                button_color: '#5e53a3',
                icon_color: '#5e53a3',
                background: {
                    type: DBC_BACKGROUND_TYPE.Color,
                    value: '#FFFFFF'
                }
            },
            vcardImageAddress: 'DBC2.png'
        },
        {
            imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_3.svg`,
            name: '2',
            profile_photo: 'https://beaconstacqa.mobstac.com//static/images/dbc/hermoine.png',
            first_name: 'Hermione',
            last_name: 'Granger',
            prefix: '',
            designation: 'Paralegal',
            pronoun: 'she/her',
            company: 'Murdoch & Associates',
            summary: '',
            customizations: {
                background_color: '#3370f9',
                user_info_color: '#000000',
                secondary_color: '#000000',
                font_style: 'Roboto',
                title_font_size: 30,
                font_type: 'google',
                custom_font_url: '',
                custom_font_style: '',
                profile_info: 'Bold',
                company_details: 'Regular',
                contact_details: 'Medium',
                button: 'Bold',
                typography: {
                    font_type: 'google',
                    font_family: 'Open Sans',
                    personal_info: getDefaultForPersonalInfo('2'),
                    company_details: getDefaultForCompanyDetails('2'),
                    contact_details: getDefaultForContactDetails('2'),
                    button: getDefaultForButton('2'),
                    bio: getDefaultForBio('2'),
                },
                button_color: '#3370f9',
                icon_color: '#3370f9',
                background: {
                    type: DBC_BACKGROUND_TYPE.Color,
                    value: '#FFFFFF'
                }
            },
            vcardImageAddress: 'DBC3.png'
        },
        {
            imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_1.svg`,
            name: '3',
            profile_photo: 'https://beaconstacqa.mobstac.com//static/images/dbc/janet.jpeg',
            first_name: 'Janet',
            last_name: 'McGee',
            prefix: '',
            designation: 'Founder, CEO',
            pronoun: '',
            company: 'McGee Security Corp.',
            summary: 'Hi! I head one of the best cybersecurity firms in the US. Get in touch.',
            customizations: {
                background_color: '#252a7c',
                user_info_color: '#FFFFFF',
                secondary_color: '#000000',
                font_style: 'Roboto',
                title_font_size: 30,
                font_type: 'google',
                custom_font_url: '',
                custom_font_style: '',
                profile_info: 'Bold',
                company_details: 'Regular',
                contact_details: 'Medium',
                button: 'Bold',
                typography: {
                    font_type: 'google',
                    font_family: 'Open Sans',
                    personal_info: getDefaultForPersonalInfo('3'),
                    company_details: getDefaultForCompanyDetails('3'),
                    contact_details: getDefaultForContactDetails('3'),
                    button: getDefaultForButton('3'),
                    bio: getDefaultForBio('3'),
                },
                button_color: '#252a7c',
                icon_color: '#252a7c',
                background: {
                    type: DBC_BACKGROUND_TYPE.Color,
                    value: '#FFFFFF'
                }
            },
            vcardImageAddress: 'DBC1.svg'
        },
    ]

    public static digestMessageSHA256 = async (message) => {
        const textAsBuffer = new TextEncoder().encode(message);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const digest = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        return digest;
    };

    static userSignedUpAfter(dateJoined) {
        const DBC_RELEASE_DATE = 1679036421000;
        return dateJoined.getTime() > DBC_RELEASE_DATE;
    }

    static checkProductBasedOnPlan(plan) {
        switch (plan) {
            case 'solo':
            case 'team':
            case 'business':
            case PLAN_TYPES.Solo:
            case PLAN_TYPES.Team:
            case PLAN_TYPES.Business:
            case PLAN_TYPES.DBCNTeam:
            case PLAN_TYPES.DBCFreemium:
                return PRODUCT_TYPES.DBC;
            default:
                return PRODUCT_TYPES.QR;
        }
    }
}

export const planDataDetails = [
    {
        planType: 'STARTER',
        planPrice: '5',
        planFeatures: ['3 Dynamic QR Codes', '25,000 Scans', '1 Linkpage', 'Single User'],
    },
    {
        planType: 'LITE',
        planPrice: '15',
        planFeatures: ['50 Dynamic QR Codes', '500,000 Scans', '5 Linkpages', 'Single User'],
    },
    {
        planType: 'PRO',
        planPrice: '49',
        planFeatures: ['250 Dynamic QR Codes', '1,000,000 Scans', '10 Linkpages', 'Single User'],
    },
    {
        planType: 'PLUS',
        planPrice: '99',
        planFeatures: ['500 Dynamic QR Codes', '2,000,000 Scans', '25 Linkpages', '3 Users', '3 Teams'],
    },
    {
        planType: 'ENTERPRISE',
        planPrice: 'TALK TO US',
        planFeatures: [
            'Tiered pricing',
            'Custom no. of scans',
            'Custom no. of linkpages',
            'Tiered users',
            'Unlimited teams',
            'Azure Active Directory Integration',
        ],
    },
];

export enum UPSELL_CLOSE_EVENT {
    PDF_SIZE = 'PDF_SIZE',
    TRIAL_FIRST_UPSELL = 'TRIAL_FIRST_UPSELL',
}

export enum DASHBOARD_MENU {
    CARDS = 'Cards',
    QRCODES = 'QrCodes',
}

export enum NotificationListType {
    LANGUAGE = 'language',
    LOCATION = 'location',
}

export enum DBCCardTypes {
    MY_CARDS,
    TEAM_CARDS,
    TEMPLATE_CARDS,
    BULK_CARDS
}

export enum WALLET_PASS_DEFAULT_COLOR {
    BACKGROUND_COLOR = '#16212F',
    FOREGROUND_COLOR = '#FFFFFF',
}

export enum BULK_ACTION_PRODUCT {
    QR,
    CARDS,
    MOBILE_CARDS,
}

export enum CARD_BULK_OPERATIONS {
    WALLET_PASS,
    DELETE,
    BULK_DOWNLOAD,
    LABEL
}

export enum QR_CODE_CUSTOMIZATION {
    TEMPLATE = 'template',
    EYE = 'eye',
    LOGO = 'logo',
    PATTERN = 'pattern',
    BACKGROUND = 'background',
    FRAME = 'frame',
    AUTO_DESIGN = 'auto_design',
}

export enum CONFIG_VALUES {
    maxLimitCheck = 2500,
    minLimitCheck = 50,
}

export const DBC_LANGUAGE_SPECIFIC_FIELDS = [
    'first_name',
    'last_name',
    'prefix',
    'suffix',
    'designation',
    'department',
    'pronouns_v2',
    'company',
    'summary',
    'logo_url',
    'logo_size',
    'phone',
    'phone_v2',
    'email',
    'email_v2',
    'website',
    'website_v2',
    'custom_fields',
    'address_url',
    'address_v2',
    'social_links',
    'user_image_url',
    'contact_info_ordering',
    '__contact_info_list__'
];

export const DBC_CARRY_FORWARD_FIELDS = [
    'logo_url',
    'user_image_url',
    'social_links',
    'phone_v2',
    'email_v2',
    'website_v2',
    'logo_size'
];



export function deepCopy<T>(obj: T): T {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const newArray: any[] = [];
        for (const item of obj) {
            newArray.push(deepCopy(item));
        }
        return newArray as T;
    }

    const newObj: Record<string, any> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = deepCopy(obj[key]);
        }
    }
    return newObj as T;

    /*
        Example usage:
        const originalObject = {
            name: 'John',
            age: 30,
            address: {
                city: 'Mumbai',
                country: 'India',
            },
        };
        const copiedObject = deepCopy(originalObject);
        console.log(copiedObject);
    */
}

export enum COMPANY_SIZE_VALUES {
    Individual = 'Individual',
    SmallCompanySize = '2-50 employees',
    MediumCompanySize = '51-250 employees',
    MediumLargeCompanySize = '251-1,000 employees',
    LargeCompanySize = 'Over 1,000 employees',
}

export const languages = [
    {
        name: 'Abkhazian',
        value: 'Abkhazian',
    },
    {
        name: 'Afar',
        value: 'Afar',
    },
    {
        name: 'Afrikaans',
        value: 'Afrikaans',
    },
    {
        name: 'Afrikaans (Namibia)',
        value: 'Afrikaans (Namibia)',
    },
    {
        name: 'Afrikaans (South Africa)',
        value: 'Afrikaans (South Africa)',
    },
    {
        name: 'Akan',
        value: 'Akan',
    },
    {
        name: 'Akan (Ghana)',
        value: 'Akan (Ghana)',
    },
    {
        name: 'Albanian',
        value: 'Albanian',
    },
    {
        name: 'Albanian (Albania)',
        value: 'Albanian (Albania)',
    },
    {
        name: 'Amharic',
        value: 'Amharic',
    },
    {
        name: 'Amharic (Ethiopia)',
        value: 'Amharic (Ethiopia)',
    },
    {
        name: 'Arabic',
        value: 'Arabic',
    },
    {
        name: 'Arabic (Algeria)',
        value: 'Arabic (Algeria)',
    },
    {
        name: 'Arabic (Bahrain)',
        value: 'Arabic (Bahrain)',
    },
    {
        name: 'Arabic (Egypt)',
        value: 'Arabic (Egypt)',
    },
    {
        name: 'Arabic (Iraq)',
        value: 'Arabic (Iraq)',
    },
    {
        name: 'Arabic (Jordan)',
        value: 'Arabic (Jordan)',
    },
    {
        name: 'Arabic (Kuwait)',
        value: 'Arabic (Kuwait)',
    },
    {
        name: 'Arabic (Lebanon)',
        value: 'Arabic (Lebanon)',
    },
    {
        name: 'Arabic (Libya)',
        value: 'Arabic (Libya)',
    },
    {
        name: 'Arabic (Morocco)',
        value: 'Arabic (Morocco)',
    },
    {
        name: 'Arabic (Oman)',
        value: 'Arabic (Oman)',
    },
    {
        name: 'Arabic (Qatar)',
        value: 'Arabic (Qatar)',
    },
    {
        name: 'Arabic (Saudi Arabia)',
        value: 'Arabic (Saudi Arabia)',
    },
    {
        name: 'Arabic (Sudan)',
        value: 'Arabic (Sudan)',
    },
    {
        name: 'Arabic (Syria)',
        value: 'Arabic (Syria)',
    },
    {
        name: 'Arabic (Tunisia)',
        value: 'Arabic (Tunisia)',
    },
    {
        name: 'Arabic (United Arab Emirates)',
        value: 'Arabic (United Arab Emirates)',
    },
    {
        name: 'Arabic (Yemen)',
        value: 'Arabic (Yemen)',
    },
    {
        name: 'Armenian',
        value: 'Armenian',
    },
    {
        name: 'Armenian (Armenia)',
        value: 'Armenian (Armenia)',
    },
    {
        name: 'Assamese',
        value: 'Assamese',
    },
    {
        name: 'Assamese (India)',
        value: 'Assamese (India)',
    },
    {
        name: 'Asu',
        value: 'Asu',
    },
    {
        name: 'Asu (Tanzania)',
        value: 'Asu (Tanzania)',
    },
    {
        name: 'Aymara',
        value: 'Aymara',
    },
    {
        name: 'Azerbaijani',
        value: 'Azerbaijani',
    },
    {
        name: 'Azerbaijani (Cyrillic)',
        value: 'Azerbaijani (Cyrillic)',
    },
    {
        name: 'Azerbaijani (Cyrillic, Azerbaijan)',
        value: 'Azerbaijani (Cyrillic, Azerbaijan)',
    },
    {
        name: 'Azerbaijani (Latin)',
        value: 'Azerbaijani (Latin)',
    },
    {
        name: 'Azerbaijani (Latin, Azerbaijan)',
        value: 'Azerbaijani (Latin, Azerbaijan)',
    },
    {
        name: 'Bambara',
        value: 'Bambara',
    },
    {
        name: 'Bambara (Mali)',
        value: 'Bambara (Mali)',
    },
    {
        name: 'Bashkir',
        value: 'Bashkir',
    },
    {
        name: 'Basque',
        value: 'Basque',
    },
    {
        name: 'Basque (Spain)',
        value: 'Basque (Spain)',
    },
    {
        name: 'Belarusian',
        value: 'Belarusian',
    },
    {
        name: 'Belarusian (Belarus)',
        value: 'Belarusian (Belarus)',
    },
    {
        name: 'Bemba',
        value: 'Bemba',
    },
    {
        name: 'Bemba (Zambia)',
        value: 'Bemba (Zambia)',
    },
    {
        name: 'Bena',
        value: 'Bena',
    },
    {
        name: 'Bena (Tanzania)',
        value: 'Bena (Tanzania)',
    },
    {
        name: 'Bengali',
        value: 'Bengali',
    },
    {
        name: 'Bengali (Bangladesh)',
        value: 'Bengali (Bangladesh)',
    },
    {
        name: 'Bengali (India)',
        value: 'Bengali (India)',
    },
    {
        name: 'Bhutani',
        value: 'Bhutani',
    },
    {
        name: 'Bihari',
        value: 'Bihari',
    },
    {
        name: 'Bislama',
        value: 'Bislama',
    },
    {
        name: 'Bosnian',
        value: 'Bosnian',
    },
    {
        name: 'Bosnian (Bosnia and Herzegovina)',
        value: 'Bosnian (Bosnia and Herzegovina)',
    },
    {
        name: 'Breton',
        value: 'Breton',
    },
    {
        name: 'Bulgarian',
        value: 'Bulgarian',
    },
    {
        name: 'Bulgarian (Bulgaria)',
        value: 'Bulgarian (Bulgaria)',
    },
    {
        name: 'Burmese',
        value: 'Burmese',
    },
    {
        name: 'Burmese (Myanmar [Burma])',
        value: 'Burmese (Myanmar [Burma])',
    },
    {
        name: 'Byelorussian',
        value: 'Byelorussian',
    },
    {
        name: 'Cambodian',
        value: 'Cambodian',
    },
    {
        name: 'Cantonese (Traditional, Hong Kong SAR China)',
        value: 'Cantonese (Traditional, Hong Kong SAR China)',
    },
    {
        name: 'Catalan',
        value: 'Catalan',
    },
    {
        name: 'Catalan (Spain)',
        value: 'Catalan (Spain)',
    },
    {
        name: 'Central Morocco Tamazight',
        value: 'Central Morocco Tamazight',
    },
    {
        name: 'Central Morocco Tamazight (Latin)',
        value: 'Central Morocco Tamazight (Latin)',
    },
    {
        name: 'Central Morocco Tamazight (Latin, Morocco)',
        value: 'Central Morocco Tamazight (Latin, Morocco)',
    },
    {
        name: 'Cherokee',
        value: 'Cherokee',
    },
    {
        name: 'Cherokee (United States)',
        value: 'Cherokee (United States)',
    },
    {
        name: 'Chiga',
        value: 'Chiga',
    },
    {
        name: 'Chiga (Uganda)',
        value: 'Chiga (Uganda)',
    },
    {
        name: 'Chinese',
        value: 'Chinese',
    },
    {
        name: 'Chinese (Simplified Han)',
        value: 'Chinese (Simplified Han)',
    },
    {
        name: 'Chinese (Simplified Han, China)',
        value: 'Chinese (Simplified Han, China)',
    },
    {
        name: 'Chinese (Simplified Han, Hong Kong SAR China)',
        value: 'Chinese (Simplified Han, Hong Kong SAR China)',
    },
    {
        name: 'Chinese (Simplified Han, Macau SAR China)',
        value: 'Chinese (Simplified Han, Macau SAR China)',
    },
    {
        name: 'Chinese (Simplified Han, Singapore)',
        value: 'Chinese (Simplified Han, Singapore)',
    },
    {
        name: 'Chinese (Traditional Han)',
        value: 'Chinese (Traditional Han)',
    },
    {
        name: 'Chinese (Traditional Han, Hong Kong SAR China)',
        value: 'Chinese (Traditional Han, Hong Kong SAR China)',
    },
    {
        name: 'Chinese (Traditional Han, Macau SAR China)',
        value: 'Chinese (Traditional Han, Macau SAR China)',
    },
    {
        name: 'Chinese (Traditional Han, Taiwan)',
        value: 'Chinese (Traditional Han, Taiwan)',
    },
    {
        name: 'Cornish',
        value: 'Cornish',
    },
    {
        name: 'Cornish (United Kingdom)',
        value: 'Cornish (United Kingdom)',
    },
    {
        name: 'Corsican',
        value: 'Corsican',
    },
    {
        name: 'Croatian',
        value: 'Croatian',
    },
    {
        name: 'Croatian (Croatia)',
        value: 'Croatian (Croatia)',
    },
    {
        name: 'Czech',
        value: 'Czech',
    },
    {
        name: 'Czech (Czech Republic)',
        value: 'Czech (Czech Republic)',
    },
    {
        name: 'Danish',
        value: 'Danish',
    },
    {
        name: 'Danish (Denmark)',
        value: 'Danish (Denmark)',
    },
    {
        name: 'Dutch',
        value: 'Dutch',
    },
    {
        name: 'Dutch (Belgium)',
        value: 'Dutch (Belgium)',
    },
    {
        name: 'Dutch (Netherlands)',
        value: 'Dutch (Netherlands)',
    },
    {
        name: 'Embu',
        value: 'Embu',
    },
    {
        name: 'Embu (Kenya)',
        value: 'Embu (Kenya)',
    },
    {
        name: 'English',
        value: 'English',
    },
    {
        name: 'English (American Samoa)',
        value: 'English (American Samoa)',
    },
    {
        name: 'English (Australia)',
        value: 'English (Australia)',
    },
    {
        name: 'English (Belgium)',
        value: 'English (Belgium)',
    },
    {
        name: 'English (Belize)',
        value: 'English (Belize)',
    },
    {
        name: 'English (Botswana)',
        value: 'English (Botswana)',
    },
    {
        name: 'English (Canada)',
        value: 'English (Canada)',
    },
    {
        name: 'English (Guam)',
        value: 'English (Guam)',
    },
    {
        name: 'English (Hong Kong SAR China)',
        value: 'English (Hong Kong SAR China)',
    },
    {
        name: 'English (India)',
        value: 'English (India)',
    },
    {
        name: 'English (Ireland)',
        value: 'English (Ireland)',
    },
    {
        name: 'English (Israel)',
        value: 'English (Israel)',
    },
    {
        name: 'English (Jamaica)',
        value: 'English (Jamaica)',
    },
    {
        name: 'English (Malaysia)',
        value: 'English (Malaysia)',
    },
    {
        name: 'English (Malta)',
        value: 'English (Malta)',
    },
    {
        name: 'English (Marshall Islands)',
        value: 'English (Marshall Islands)',
    },
    {
        name: 'English (Mauritius)',
        value: 'English (Mauritius)',
    },
    {
        name: 'English (Namibia)',
        value: 'English (Namibia)',
    },
    {
        name: 'English (New Zealand)',
        value: 'English (New Zealand)',
    },
    {
        name: 'English (Northern Mariana Islands)',
        value: 'English (Northern Mariana Islands)',
    },
    {
        name: 'English (Pakistan)',
        value: 'English (Pakistan)',
    },
    {
        name: 'English (Philippines)',
        value: 'English (Philippines)',
    },
    {
        name: 'English (Singapore)',
        value: 'English (Singapore)',
    },
    {
        name: 'English (South Africa)',
        value: 'English (South Africa)',
    },
    {
        name: 'English (Trinidad and Tobago)',
        value: 'English (Trinidad and Tobago)',
    },
    {
        name: 'English (U.S. Minor Outlying Islands)',
        value: 'English (U.S. Minor Outlying Islands)',
    },
    {
        name: 'English (U.S. Virgin Islands)',
        value: 'English (U.S. Virgin Islands)',
    },
    {
        name: 'English (United Kingdom)',
        value: 'English (United Kingdom)',
    },
    {
        name: 'English (United States)',
        value: 'English (United States)',
    },
    {
        name: 'English (Zimbabwe)',
        value: 'English (Zimbabwe)',
    },
    {
        name: 'Esperanto',
        value: 'Esperanto',
    },
    {
        name: 'Estonian',
        value: 'Estonian',
    },
    {
        name: 'Estonian (Estonia)',
        value: 'Estonian (Estonia)',
    },
    {
        name: 'Ewe',
        value: 'Ewe',
    },
    {
        name: 'Ewe (Ghana)',
        value: 'Ewe (Ghana)',
    },
    {
        name: 'Ewe (Togo)',
        value: 'Ewe (Togo)',
    },
    {
        name: 'Faeroese',
        value: 'Faeroese',
    },
    {
        name: 'Faroese',
        value: 'Faroese',
    },
    {
        name: 'Faroese (Faroe Islands)',
        value: 'Faroese (Faroe Islands)',
    },
    {
        name: 'Fiji',
        value: 'Fiji',
    },
    {
        name: 'Filipino',
        value: 'Filipino',
    },
    {
        name: 'Filipino (Philippines)',
        value: 'Filipino (Philippines)',
    },
    {
        name: 'Finnish',
        value: 'Finnish',
    },
    {
        name: 'Finnish (Finland)',
        value: 'Finnish (Finland)',
    },
    {
        name: 'French',
        value: 'French',
    },
    {
        name: 'French (Belgium)',
        value: 'French (Belgium)',
    },
    {
        name: 'French (Benin)',
        value: 'French (Benin)',
    },
    {
        name: 'French (Burkina Faso)',
        value: 'French (Burkina Faso)',
    },
    {
        name: 'French (Burundi)',
        value: 'French (Burundi)',
    },
    {
        name: 'French (Cameroon)',
        value: 'French (Cameroon)',
    },
    {
        name: 'French (Canada)',
        value: 'French (Canada)',
    },
    {
        name: 'French (Central African Republic)',
        value: 'French (Central African Republic)',
    },
    {
        name: 'French (Chad)',
        value: 'French (Chad)',
    },
    {
        name: 'French (Comoros)',
        value: 'French (Comoros)',
    },
    {
        name: 'French (Congo - Brazzaville)',
        value: 'French (Congo - Brazzaville)',
    },
    {
        name: 'French (Congo - Kinshasa)',
        value: 'French (Congo - Kinshasa)',
    },
    {
        name: 'French (Côte d’Ivoire)',
        value: 'French (Côte d’Ivoire)',
    },
    {
        name: 'French (Djibouti)',
        value: 'French (Djibouti)',
    },
    {
        name: 'French (Equatorial Guinea)',
        value: 'French (Equatorial Guinea)',
    },
    {
        name: 'French (France)',
        value: 'French (France)',
    },
    {
        name: 'French (Gabon)',
        value: 'French (Gabon)',
    },
    {
        name: 'French (Guadeloupe)',
        value: 'French (Guadeloupe)',
    },
    {
        name: 'French (Guinea)',
        value: 'French (Guinea)',
    },
    {
        name: 'French (Luxembourg)',
        value: 'French (Luxembourg)',
    },
    {
        name: 'French (Madagascar)',
        value: 'French (Madagascar)',
    },
    {
        name: 'French (Mali)',
        value: 'French (Mali)',
    },
    {
        name: 'French (Martinique)',
        value: 'French (Martinique)',
    },
    {
        name: 'French (Monaco)',
        value: 'French (Monaco)',
    },
    {
        name: 'French (Niger)',
        value: 'French (Niger)',
    },
    {
        name: 'French (Rwanda)',
        value: 'French (Rwanda)',
    },
    {
        name: 'French (Réunion)',
        value: 'French (Réunion)',
    },
    {
        name: 'French (Saint Barthélemy)',
        value: 'French (Saint Barthélemy)',
    },
    {
        name: 'French (Saint Martin)',
        value: 'French (Saint Martin)',
    },
    {
        name: 'French (Senegal)',
        value: 'French (Senegal)',
    },
    {
        name: 'French (Switzerland)',
        value: 'French (Switzerland)',
    },
    {
        name: 'French (Togo)',
        value: 'French (Togo)',
    },
    {
        name: 'Frisian',
        value: 'Frisian',
    },
    {
        name: 'Fulah',
        value: 'Fulah',
    },
    {
        name: 'Fulah (Senegal)',
        value: 'Fulah (Senegal)',
    },
    {
        name: 'Galician',
        value: 'Galician',
    },
    {
        name: 'Galician (Spain)',
        value: 'Galician (Spain)',
    },
    {
        name: 'Ganda',
        value: 'Ganda',
    },
    {
        name: 'Ganda (Uganda)',
        value: 'Ganda (Uganda)',
    },
    {
        name: 'Georgian',
        value: 'Georgian',
    },
    {
        name: 'Georgian (Georgia)',
        value: 'Georgian (Georgia)',
    },
    {
        name: 'German',
        value: 'German',
    },
    {
        name: 'German (Austria)',
        value: 'German (Austria)',
    },
    {
        name: 'German (Belgium)',
        value: 'German (Belgium)',
    },
    {
        name: 'German (Germany)',
        value: 'German (Germany)',
    },
    {
        name: 'German (Liechtenstein)',
        value: 'German (Liechtenstein)',
    },
    {
        name: 'German (Luxembourg)',
        value: 'German (Luxembourg)',
    },
    {
        name: 'German (Switzerland)',
        value: 'German (Switzerland)',
    },
    {
        name: 'Greek',
        value: 'Greek',
    },
    {
        name: 'Greek (Cyprus)',
        value: 'Greek (Cyprus)',
    },
    {
        name: 'Greek (Greece)',
        value: 'Greek (Greece)',
    },
    {
        name: 'Greenlandic',
        value: 'Greenlandic',
    },
    {
        name: 'Guarani',
        value: 'Guarani',
    },
    {
        name: 'Gujarati',
        value: 'Gujarati',
    },
    {
        name: 'Gujarati (India)',
        value: 'Gujarati (India)',
    },
    {
        name: 'Gusii',
        value: 'Gusii',
    },
    {
        name: 'Gusii (Kenya)',
        value: 'Gusii (Kenya)',
    },
    {
        name: 'Hausa',
        value: 'Hausa',
    },
    {
        name: 'Hausa (Latin)',
        value: 'Hausa (Latin)',
    },
    {
        name: 'Hausa (Latin, Ghana)',
        value: 'Hausa (Latin, Ghana)',
    },
    {
        name: 'Hausa (Latin, Niger)',
        value: 'Hausa (Latin, Niger)',
    },
    {
        name: 'Hausa (Latin, Nigeria)',
        value: 'Hausa (Latin, Nigeria)',
    },
    {
        name: 'Hawaiian',
        value: 'Hawaiian',
    },
    {
        name: 'Hawaiian (United States)',
        value: 'Hawaiian (United States)',
    },
    {
        name: 'Hebrew',
        value: 'Hebrew',
    },
    {
        name: 'Hebrew (Israel)',
        value: 'Hebrew (Israel)',
    },
    {
        name: 'Hindi',
        value: 'Hindi',
    },
    {
        name: 'Hindi (India)',
        value: 'Hindi (India)',
    },
    {
        name: 'Hungarian',
        value: 'Hungarian',
    },
    {
        name: 'Hungarian (Hungary)',
        value: 'Hungarian (Hungary)',
    },
    {
        name: 'Icelandic',
        value: 'Icelandic',
    },
    {
        name: 'Icelandic (Iceland)',
        value: 'Icelandic (Iceland)',
    },
    {
        name: 'Igbo',
        value: 'Igbo',
    },
    {
        name: 'Igbo (Nigeria)',
        value: 'Igbo (Nigeria)',
    },
    {
        name: 'Indonesian',
        value: 'Indonesian',
    },
    {
        name: 'Indonesian (Indonesia)',
        value: 'Indonesian (Indonesia)',
    },
    {
        name: 'Interlingua',
        value: 'Interlingua',
    },
    {
        name: 'Interlingue',
        value: 'Interlingue',
    },
    {
        name: 'Inuktitut',
        value: 'Inuktitut',
    },
    {
        name: 'Inupiak',
        value: 'Inupiak',
    },
    {
        name: 'Irish',
        value: 'Irish',
    },
    {
        name: 'Irish (Ireland)',
        value: 'Irish (Ireland)',
    },
    {
        name: 'Italian',
        value: 'Italian',
    },
    {
        name: 'Italian (Italy)',
        value: 'Italian (Italy)',
    },
    {
        name: 'Italian (Switzerland)',
        value: 'Italian (Switzerland)',
    },
    {
        name: 'Japanese',
        value: 'Japanese',
    },
    {
        name: 'Japanese (Japan)',
        value: 'Japanese (Japan)',
    },
    {
        name: 'Javanese',
        value: 'Javanese',
    },
    {
        name: 'Kabuverdianu',
        value: 'Kabuverdianu',
    },
    {
        name: 'Kabuverdianu (Cape Verde)',
        value: 'Kabuverdianu (Cape Verde)',
    },
    {
        name: 'Kabyle',
        value: 'Kabyle',
    },
    {
        name: 'Kabyle (Algeria)',
        value: 'Kabyle (Algeria)',
    },
    {
        name: 'Kalaallisut',
        value: 'Kalaallisut',
    },
    {
        name: 'Kalaallisut (Greenland)',
        value: 'Kalaallisut (Greenland)',
    },
    {
        name: 'Kalenjin',
        value: 'Kalenjin',
    },
    {
        name: 'Kalenjin (Kenya)',
        value: 'Kalenjin (Kenya)',
    },
    {
        name: 'Kamba',
        value: 'Kamba',
    },
    {
        name: 'Kamba (Kenya)',
        value: 'Kamba (Kenya)',
    },
    {
        name: 'Kannada',
        value: 'Kannada',
    },
    {
        name: 'Kannada (India)',
        value: 'Kannada (India)',
    },
    {
        name: 'Kashmiri',
        value: 'Kashmiri',
    },
    {
        name: 'Kazakh',
        value: 'Kazakh',
    },
    {
        name: 'Kazakh (Cyrillic)',
        value: 'Kazakh (Cyrillic)',
    },
    {
        name: 'Kazakh (Cyrillic, Kazakhstan)',
        value: 'Kazakh (Cyrillic, Kazakhstan)',
    },
    {
        name: 'Khmer',
        value: 'Khmer',
    },
    {
        name: 'Khmer (Cambodia)',
        value: 'Khmer (Cambodia)',
    },
    {
        name: 'Kikuyu',
        value: 'Kikuyu',
    },
    {
        name: 'Kikuyu (Kenya)',
        value: 'Kikuyu (Kenya)',
    },
    {
        name: 'Kinyarwanda',
        value: 'Kinyarwanda',
    },
    {
        name: 'Kinyarwanda (Rwanda)',
        value: 'Kinyarwanda (Rwanda)',
    },
    {
        name: 'Kirghiz',
        value: 'Kirghiz',
    },
    {
        name: 'Kirundi',
        value: 'Kirundi',
    },
    {
        name: 'Konkani',
        value: 'Konkani',
    },
    {
        name: 'Konkani (India)',
        value: 'Konkani (India)',
    },
    {
        name: 'Korean',
        value: 'Korean',
    },
    {
        name: 'Korean (South Korea)',
        value: 'Korean (South Korea)',
    },
    {
        name: 'Koyra Chiini',
        value: 'Koyra Chiini',
    },
    {
        name: 'Koyra Chiini (Mali)',
        value: 'Koyra Chiini (Mali)',
    },
    {
        name: 'Koyraboro Senni',
        value: 'Koyraboro Senni',
    },
    {
        name: 'Koyraboro Senni (Mali)',
        value: 'Koyraboro Senni (Mali)',
    },
    {
        name: 'Kurdish',
        value: 'Kurdish',
    },
    {
        name: 'Langi',
        value: 'Langi',
    },
    {
        name: 'Langi (Tanzania)',
        value: 'Langi (Tanzania)',
    },
    {
        name: 'Laothian',
        value: 'Laothian',
    },
    {
        name: 'Latin',
        value: 'Latin',
    },
    {
        name: 'Latvian',
        value: 'Latvian',
    },
    {
        name: 'Latvian (Latvia)',
        value: 'Latvian (Latvia)',
    },
    {
        name: 'Lettish',
        value: 'Lettish',
    },
    {
        name: 'Lingala',
        value: 'Lingala',
    },
    {
        name: 'Lithuanian',
        value: 'Lithuanian',
    },
    {
        name: 'Lithuanian (Lithuania)',
        value: 'Lithuanian (Lithuania)',
    },
    {
        name: 'Luo',
        value: 'Luo',
    },
    {
        name: 'Luo (Kenya)',
        value: 'Luo (Kenya)',
    },
    {
        name: 'Luyia',
        value: 'Luyia',
    },
    {
        name: 'Luyia (Kenya)',
        value: 'Luyia (Kenya)',
    },
    {
        name: 'Macedonian',
        value: 'Macedonian',
    },
    {
        name: 'Macedonian (Macedonia)',
        value: 'Macedonian (Macedonia)',
    },
    {
        name: 'Machame',
        value: 'Machame',
    },
    {
        name: 'Machame (Tanzania)',
        value: 'Machame (Tanzania)',
    },
    {
        name: 'Makonde',
        value: 'Makonde',
    },
    {
        name: 'Makonde (Tanzania)',
        value: 'Makonde (Tanzania)',
    },
    {
        name: 'Malagasy',
        value: 'Malagasy',
    },
    {
        name: 'Malagasy (Madagascar)',
        value: 'Malagasy (Madagascar)',
    },
    {
        name: 'Malay',
        value: 'Malay',
    },
    {
        name: 'Malay (Brunei)',
        value: 'Malay (Brunei)',
    },
    {
        name: 'Malay (Malaysia)',
        value: 'Malay (Malaysia)',
    },
    {
        name: 'Malayalam',
        value: 'Malayalam',
    },
    {
        name: 'Malayalam (India)',
        value: 'Malayalam (India)',
    },
    {
        name: 'Maltese',
        value: 'Maltese',
    },
    {
        name: 'Maltese (Malta)',
        value: 'Maltese (Malta)',
    },
    {
        name: 'Manx',
        value: 'Manx',
    },
    {
        name: 'Manx (United Kingdom)',
        value: 'Manx (United Kingdom)',
    },
    {
        name: 'Maori',
        value: 'Maori',
    },
    {
        name: 'Marathi',
        value: 'Marathi',
    },
    {
        name: 'Marathi (India)',
        value: 'Marathi (India)',
    },
    {
        name: 'Masai',
        value: 'Masai',
    },
    {
        name: 'Masai (Kenya)',
        value: 'Masai (Kenya)',
    },
    {
        name: 'Masai (Tanzania)',
        value: 'Masai (Tanzania)',
    },
    {
        name: 'Meru',
        value: 'Meru',
    },
    {
        name: 'Meru (Kenya)',
        value: 'Meru (Kenya)',
    },
    {
        name: 'Moldavian',
        value: 'Moldavian',
    },
    {
        name: 'Mongolian',
        value: 'Mongolian',
    },
    {
        name: 'Morisyen',
        value: 'Morisyen',
    },
    {
        name: 'Morisyen (Mauritius)',
        value: 'Morisyen (Mauritius)',
    },
    {
        name: 'Nama',
        value: 'Nama',
    },
    {
        name: 'Nama (Namibia)',
        value: 'Nama (Namibia)',
    },
    {
        name: 'Nauru',
        value: 'Nauru',
    },
    {
        name: 'Nepali',
        value: 'Nepali',
    },
    {
        name: 'Nepali (India)',
        value: 'Nepali (India)',
    },
    {
        name: 'Nepali (Nepal)',
        value: 'Nepali (Nepal)',
    },
    {
        name: 'North Ndebele',
        value: 'North Ndebele',
    },
    {
        name: 'North Ndebele (Zimbabwe)',
        value: 'North Ndebele (Zimbabwe)',
    },
    {
        name: 'Norwegian',
        value: 'Norwegian',
    },
    {
        name: 'Norwegian Bokmål',
        value: 'Norwegian Bokmål',
    },
    {
        name: 'Norwegian Bokmål (Norway)',
        value: 'Norwegian Bokmål (Norway)',
    },
    {
        name: 'Norwegian Nynorsk',
        value: 'Norwegian Nynorsk',
    },
    {
        name: 'Norwegian Nynorsk (Norway)',
        value: 'Norwegian Nynorsk (Norway)',
    },
    {
        name: 'Nyankole',
        value: 'Nyankole',
    },
    {
        name: 'Nyankole (Uganda)',
        value: 'Nyankole (Uganda)',
    },
    {
        name: 'Occitan',
        value: 'Occitan',
    },
    {
        name: 'Oriya',
        value: 'Oriya',
    },
    {
        name: 'Oriya (India)',
        value: 'Oriya (India)',
    },
    {
        name: 'Oromo',
        value: 'Oromo',
    },
    {
        name: 'Oromo (Ethiopia)',
        value: 'Oromo (Ethiopia)',
    },
    {
        name: 'Oromo (Kenya)',
        value: 'Oromo (Kenya)',
    },
    {
        name: 'Pashto',
        value: 'Pashto',
    },
    {
        name: 'Pashto (Afghanistan)',
        value: 'Pashto (Afghanistan)',
    },
    {
        name: 'Persian',
        value: 'Persian',
    },
    {
        name: 'Persian (Afghanistan)',
        value: 'Persian (Afghanistan)',
    },
    {
        name: 'Persian (Iran)',
        value: 'Persian (Iran)',
    },
    {
        name: 'Polish',
        value: 'Polish',
    },
    {
        name: 'Polish (Poland)',
        value: 'Polish (Poland)',
    },
    {
        name: 'Portuguese',
        value: 'Portuguese',
    },
    {
        name: 'Portuguese (Brazil)',
        value: 'Portuguese (Brazil)',
    },
    {
        name: 'Portuguese (Guinea-Bissau)',
        value: 'Portuguese (Guinea-Bissau)',
    },
    {
        name: 'Portuguese (Mozambique)',
        value: 'Portuguese (Mozambique)',
    },
    {
        name: 'Portuguese (Portugal)',
        value: 'Portuguese (Portugal)',
    },
    {
        name: 'Punjabi',
        value: 'Punjabi',
    },
    {
        name: 'Punjabi (Arabic)',
        value: 'Punjabi (Arabic)',
    },
    {
        name: 'Punjabi (Arabic, Pakistan)',
        value: 'Punjabi (Arabic, Pakistan)',
    },
    {
        name: 'Punjabi (Gurmukhi)',
        value: 'Punjabi (Gurmukhi)',
    },
    {
        name: 'Punjabi (Gurmukhi, India)',
        value: 'Punjabi (Gurmukhi, India)',
    },
    {
        name: 'Pushto',
        value: 'Pushto',
    },
    {
        name: 'Quechua',
        value: 'Quechua',
    },
    {
        name: 'Rhaeto',
        value: 'Rhaeto',
    },
    {
        name: 'Romanian',
        value: 'Romanian',
    },
    {
        name: 'Romanian (Moldova)',
        value: 'Romanian (Moldova)',
    },
    {
        name: 'Romanian (Romania)',
        value: 'Romanian (Romania)',
    },
    {
        name: 'Romansh',
        value: 'Romansh',
    },
    {
        name: 'Romansh (Switzerland)',
        value: 'Romansh (Switzerland)',
    },
    {
        name: 'Rombo',
        value: 'Rombo',
    },
    {
        name: 'Rombo (Tanzania)',
        value: 'Rombo (Tanzania)',
    },
    {
        name: 'Russian',
        value: 'Russian',
    },
    {
        name: 'Russian (Moldova)',
        value: 'Russian (Moldova)',
    },
    {
        name: 'Russian (Russia)',
        value: 'Russian (Russia)',
    },
    {
        name: 'Russian (Ukraine)',
        value: 'Russian (Ukraine)',
    },
    {
        name: 'Rwa',
        value: 'Rwa',
    },
    {
        name: 'Rwa (Tanzania)',
        value: 'Rwa (Tanzania)',
    },
    {
        name: 'Samburu',
        value: 'Samburu',
    },
    {
        name: 'Samburu (Kenya)',
        value: 'Samburu (Kenya)',
    },
    {
        name: 'Samoan',
        value: 'Samoan',
    },
    {
        name: 'Sango',
        value: 'Sango',
    },
    {
        name: 'Sango (Central African Republic)',
        value: 'Sango (Central African Republic)',
    },
    {
        name: 'Sangro',
        value: 'Sangro',
    },
    {
        name: 'Sanskrit',
        value: 'Sanskrit',
    },
    {
        name: 'Scots',
        value: 'Scots',
    },
    {
        name: 'Sena',
        value: 'Sena',
    },
    {
        name: 'Sena (Mozambique)',
        value: 'Sena (Mozambique)',
    },
    {
        name: 'Serbian',
        value: 'Serbian',
    },
    {
        name: 'Serbian (Cyrillic)',
        value: 'Serbian (Cyrillic)',
    },
    {
        name: 'Serbian (Cyrillic, Bosnia and Herzegovina)',
        value: 'Serbian (Cyrillic, Bosnia and Herzegovina)',
    },
    {
        name: 'Serbian (Cyrillic, Montenegro)',
        value: 'Serbian (Cyrillic, Montenegro)',
    },
    {
        name: 'Serbian (Cyrillic, Serbia)',
        value: 'Serbian (Cyrillic, Serbia)',
    },
    {
        name: 'Serbian (Latin)',
        value: 'Serbian (Latin)',
    },
    {
        name: 'Serbian (Latin, Bosnia and Herzegovina)',
        value: 'Serbian (Latin, Bosnia and Herzegovina)',
    },
    {
        name: 'Serbian (Latin, Montenegro)',
        value: 'Serbian (Latin, Montenegro)',
    },
    {
        name: 'Serbian (Latin, Serbia)',
        value: 'Serbian (Latin, Serbia)',
    },
    {
        name: 'Serbo',
        value: 'Serbo',
    },
    {
        name: 'Sesotho',
        value: 'Sesotho',
    },
    {
        name: 'Setswana',
        value: 'Setswana',
    },
    {
        name: 'Shona',
        value: 'Shona',
    },
    {
        name: 'Shona (Zimbabwe)',
        value: 'Shona (Zimbabwe)',
    },
    {
        name: 'Sichuan Yi',
        value: 'Sichuan Yi',
    },
    {
        name: 'Sichuan Yi (China)',
        value: 'Sichuan Yi (China)',
    },
    {
        name: 'Sindhi',
        value: 'Sindhi',
    },
    {
        name: 'Singhalese',
        value: 'Singhalese',
    },
    {
        name: 'Sinhala',
        value: 'Sinhala',
    },
    {
        name: 'Sinhala (Sri Lanka)',
        value: 'Sinhala (Sri Lanka)',
    },
    {
        name: 'Siswati',
        value: 'Siswati',
    },
    {
        name: 'Slovak',
        value: 'Slovak',
    },
    {
        name: 'Slovak (Slovakia)',
        value: 'Slovak (Slovakia)',
    },
    {
        name: 'Slovenian',
        value: 'Slovenian',
    },
    {
        name: 'Slovenian (Slovenia)',
        value: 'Slovenian (Slovenia)',
    },
    {
        name: 'Soga',
        value: 'Soga',
    },
    {
        name: 'Soga (Uganda)',
        value: 'Soga (Uganda)',
    },
    {
        name: 'Somali',
        value: 'Somali',
    },
    {
        name: 'Somali (Djibouti)',
        value: 'Somali (Djibouti)',
    },
    {
        name: 'Somali (Ethiopia)',
        value: 'Somali (Ethiopia)',
    },
    {
        name: 'Somali (Kenya)',
        value: 'Somali (Kenya)',
    },
    {
        name: 'Somali (Somalia)',
        value: 'Somali (Somalia)',
    },
    {
        name: 'Spanish',
        value: 'Spanish',
    },
    {
        name: 'Spanish (Argentina)',
        value: 'Spanish (Argentina)',
    },
    {
        name: 'Spanish (Bolivia)',
        value: 'Spanish (Bolivia)',
    },
    {
        name: 'Spanish (Chile)',
        value: 'Spanish (Chile)',
    },
    {
        name: 'Spanish (Colombia)',
        value: 'Spanish (Colombia)',
    },
    {
        name: 'Spanish (Costa Rica)',
        value: 'Spanish (Costa Rica)',
    },
    {
        name: 'Spanish (Dominican Republic)',
        value: 'Spanish (Dominican Republic)',
    },
    {
        name: 'Spanish (Ecuador)',
        value: 'Spanish (Ecuador)',
    },
    {
        name: 'Spanish (El Salvador)',
        value: 'Spanish (El Salvador)',
    },
    {
        name: 'Spanish (Equatorial Guinea)',
        value: 'Spanish (Equatorial Guinea)',
    },
    {
        name: 'Spanish (Guatemala)',
        value: 'Spanish (Guatemala)',
    },
    {
        name: 'Spanish (Honduras)',
        value: 'Spanish (Honduras)',
    },
    {
        name: 'Spanish (Latin America)',
        value: 'Spanish (Latin America)',
    },
    {
        name: 'Spanish (Mexico)',
        value: 'Spanish (Mexico)',
    },
    {
        name: 'Spanish (Nicaragua)',
        value: 'Spanish (Nicaragua)',
    },
    {
        name: 'Spanish (Panama)',
        value: 'Spanish (Panama)',
    },
    {
        name: 'Spanish (Paraguay)',
        value: 'Spanish (Paraguay)',
    },
    {
        name: 'Spanish (Peru)',
        value: 'Spanish (Peru)',
    },
    {
        name: 'Spanish (Puerto Rico)',
        value: 'Spanish (Puerto Rico)',
    },
    {
        name: 'Spanish (Spain)',
        value: 'Spanish (Spain)',
    },
    {
        name: 'Spanish (United States)',
        value: 'Spanish (United States)',
    },
    {
        name: 'Spanish (Uruguay)',
        value: 'Spanish (Uruguay)',
    },
    {
        name: 'Spanish (Venezuela)',
        value: 'Spanish (Venezuela)',
    },
    {
        name: 'Sudanese',
        value: 'Sudanese',
    },
    {
        name: 'Swahili',
        value: 'Swahili',
    },
    {
        name: 'Swahili (Kenya)',
        value: 'Swahili (Kenya)',
    },
    {
        name: 'Swahili (Tanzania)',
        value: 'Swahili (Tanzania)',
    },
    {
        name: 'Swedish',
        value: 'Swedish',
    },
    {
        name: 'Swedish (Finland)',
        value: 'Swedish (Finland)',
    },
    {
        name: 'Swedish (Sweden)',
        value: 'Swedish (Sweden)',
    },
    {
        name: 'Swiss German',
        value: 'Swiss German',
    },
    {
        name: 'Swiss German (Switzerland)',
        value: 'Swiss German (Switzerland)',
    },
    {
        name: 'Tachelhit',
        value: 'Tachelhit',
    },
    {
        name: 'Tachelhit (Latin)',
        value: 'Tachelhit (Latin)',
    },
    {
        name: 'Tachelhit (Latin, Morocco)',
        value: 'Tachelhit (Latin, Morocco)',
    },
    {
        name: 'Tachelhit (Tifinagh)',
        value: 'Tachelhit (Tifinagh)',
    },
    {
        name: 'Tachelhit (Tifinagh, Morocco)',
        value: 'Tachelhit (Tifinagh, Morocco)',
    },
    {
        name: 'Tagalog',
        value: 'Tagalog',
    },
    {
        name: 'Taita',
        value: 'Taita',
    },
    {
        name: 'Taita (Kenya)',
        value: 'Taita (Kenya)',
    },
    {
        name: 'Tajik',
        value: 'Tajik',
    },
    {
        name: 'Tamil',
        value: 'Tamil',
    },
    {
        name: 'Tamil (India)',
        value: 'Tamil (India)',
    },
    {
        name: 'Tamil (Sri Lanka)',
        value: 'Tamil (Sri Lanka)',
    },
    {
        name: 'Tatar',
        value: 'Tatar',
    },
    {
        name: 'Telugu',
        value: 'Telugu',
    },
    {
        name: 'Telugu (India)',
        value: 'Telugu (India)',
    },
    {
        name: 'Teso',
        value: 'Teso',
    },
    {
        name: 'Teso (Kenya)',
        value: 'Teso (Kenya)',
    },
    {
        name: 'Teso (Uganda)',
        value: 'Teso (Uganda)',
    },
    {
        name: 'Thai',
        value: 'Thai',
    },
    {
        name: 'Thai (Thailand)',
        value: 'Thai (Thailand)',
    },
    {
        name: 'Tibetan',
        value: 'Tibetan',
    },
    {
        name: 'Tibetan (China)',
        value: 'Tibetan (China)',
    },
    {
        name: 'Tibetan (India)',
        value: 'Tibetan (India)',
    },
    {
        name: 'Tigrinya',
        value: 'Tigrinya',
    },
    {
        name: 'Tigrinya (Eritrea)',
        value: 'Tigrinya (Eritrea)',
    },
    {
        name: 'Tigrinya (Ethiopia)',
        value: 'Tigrinya (Ethiopia)',
    },
    {
        name: 'Tonga',
        value: 'Tonga',
    },
    {
        name: 'Tonga (Tonga)',
        value: 'Tonga (Tonga)',
    },
    {
        name: 'Tsonga',
        value: 'Tsonga',
    },
    {
        name: 'Turkish',
        value: 'Turkish',
    },
    {
        name: 'Turkish (Turkey)',
        value: 'Turkish (Turkey)',
    },
    {
        name: 'Turkmen',
        value: 'Turkmen',
    },
    {
        name: 'Twi',
        value: 'Twi',
    },
    {
        name: 'Uigur',
        value: 'Uigur',
    },
    {
        name: 'Ukrainian',
        value: 'Ukrainian',
    },
    {
        name: 'Ukrainian (Ukraine)',
        value: 'Ukrainian (Ukraine)',
    },
    {
        name: 'Urdu',
        value: 'Urdu',
    },
    {
        name: 'Urdu (India)',
        value: 'Urdu (India)',
    },
    {
        name: 'Urdu (Pakistan)',
        value: 'Urdu (Pakistan)',
    },
    {
        name: 'Uzbek',
        value: 'Uzbek',
    },
    {
        name: 'Uzbek (Arabic)',
        value: 'Uzbek (Arabic)',
    },
    {
        name: 'Uzbek (Arabic, Afghanistan)',
        value: 'Uzbek (Arabic, Afghanistan)',
    },
    {
        name: 'Uzbek (Cyrillic)',
        value: 'Uzbek (Cyrillic)',
    },
    {
        name: 'Uzbek (Cyrillic, Uzbekistan)',
        value: 'Uzbek (Cyrillic, Uzbekistan)',
    },
    {
        name: 'Uzbek (Latin)',
        value: 'Uzbek (Latin)',
    },
    {
        name: 'Uzbek (Latin, Uzbekistan)',
        value: 'Uzbek (Latin, Uzbekistan)',
    },
    {
        name: 'Vietnamese',
        value: 'Vietnamese',
    },
    {
        name: 'Vietnamese (Vietnam)',
        value: 'Vietnamese (Vietnam)',
    },
    {
        name: 'Volapuk',
        value: 'Volapuk',
    },
    {
        name: 'Vunjo',
        value: 'Vunjo',
    },
    {
        name: 'Vunjo (Tanzania)',
        value: 'Vunjo (Tanzania)',
    },
    {
        name: 'Welch',
        value: 'Welch',
    },
    {
        name: 'Welsh',
        value: 'Welsh',
    },
    {
        name: 'Welsh (United Kingdom)',
        value: 'Welsh (United Kingdom)',
    },
    {
        name: 'Wolof',
        value: 'Wolof',
    },
    {
        name: 'Xhosa',
        value: 'Xhosa',
    },
    {
        name: 'Yiddish',
        value: 'Yiddish',
    },
    {
        name: 'Yoruba',
        value: 'Yoruba',
    },
    {
        name: 'Yoruba (Nigeria)',
        value: 'Yoruba (Nigeria)',
    },
    {
        name: 'Zhuang',
        value: 'Zhuang',
    },
    {
        name: 'Zulu',
        value: 'Zulu',
    },
    {
        name: 'Zulu (South Africa)',
        value: 'Zulu (South Africa)',
    },
];

export const QR_FILTER_OPTIONS: Array<Filter> = [
    new Filter({
        name: 'QR Code Name',
        icon: 'fas fa-qrcode',
        value: 'name',
        input: '',
        show: true,
        operators: [
            { name: 'contains', value: '__icontains_all' },
            { name: 'does not contain', value: '__icontains_nin' },
            { name: 'is', value: '__in' },
            { name: 'is not', value: '__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.String,
    }),
    new Filter({
        name: 'QR Code Link',
        icon: 'fas fa-link',
        value: 'url',
        input: '',
        show: true,
        operators: [
            { name: 'contains', value: '__icontains_all' },
            { name: 'does not contain', value: '__icontains_nin' },
            { name: 'is', value: '__in' },
            { name: 'is not', value: '__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.String,
    }),
    new Filter({
        name: 'QR Code Type',
        icon: 'fas fa-layer-group',
        value: 'campaign',
        input: '',
        show: true,
        operators: [
            { name: 'is', value: '__content_type__in' },
            { name: 'is not', value: '__content_type__nin' },
        ],
        placeholder: 'Select QR Code Type',
        dropdownOptions: [
            { text: 'No Campaign', value: EddyCampaignTypes.NoCampaign },
            { text: 'Website', value: EddyCampaignTypes.CustomURL },
            { text: 'Landing page', value: EddyCampaignTypes.Card },
            { text: 'Form', value: EddyCampaignTypes.Form },
            { text: 'App Download', value: EddyCampaignTypes.AppLinks },
            { text: 'Restaurant Menu', value: EddyCampaignTypes.RestaurantMenu },
            { text: 'PDF', value: EddyCampaignTypes.PDF },
            { text: 'Social Media', value: EddyCampaignTypes.SocialMedia },
            { text: 'Facebook Page', value: EddyCampaignTypes.FacebookPage },
            { text: 'Coupon Code', value: EddyCampaignTypes.CouponCode },
            { text: 'Business Page', value: EddyCampaignTypes.BusinessCard },
            { text: 'Call', value: EddyCampaignTypes.PhoneCall },
            { text: 'SMS', value: EddyCampaignTypes.PhoneSMS },
            { text: 'Email', value: EddyCampaignTypes.Email },
            { text: 'Linkpage', value: EddyCampaignTypes.Linkpage },
            { text: 'Image', value: EddyCampaignTypes.IMAGE },
            { text: 'MP3', value: EddyCampaignTypes.MP3 },
            { text: 'Location', value: EddyCampaignTypes.LOCATION },
            { text: 'Geolocation', value: EddyCampaignTypes.GeoLocation },
            { text: 'Smart Rules', value: EddyCampaignTypes.SmartRules },
            { text: 'Digital Business Card (vCard)', value: EddyCampaignTypes.VCardPlus },
        ],
        type: FILTER_OPERATOR_TYPE.Dropdown,
    }),
    new Filter({
        name: 'Label',
        icon: 'fas fa-tags',
        value: 'tags',
        input: '',
        show: true,
        placeholder: 'Select Label',
        dropdownOptions: [],
        operators: [
            { name: 'is', value: '__id__all_in' },
            { name: 'is not', value: '__id__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.Dropdown,
    }),
    new Filter({
        name: 'QR Scan Count',
        placeholder: 'Enter a number',
        icon: 'fas fa-camera',
        value: 'scans',
        input: '',
        show: true,
        operators: [
            { name: 'is', value: '' },
            { name: 'is less than', value: '__lt' },
            { name: 'is greater than', value: '__gt' },
            { name: 'is not', value: '__neq' },
        ],
        type: FILTER_OPERATOR_TYPE.Number,
    }),
    new Filter({
        name: 'Date Created',
        icon: 'fas fa-calendar-alt',
        value: 'created',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    }),
    new Filter({
        name: 'Last Updated',
        icon: 'fas fa-calendar-alt',
        value: 'updated',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    }),
    new Filter({
        name: 'Source',
        icon: 'fas fa-table',
        value: 'creation_source',
        input: '',
        show: true,
        operators: [
            { name: 'is', value: '' },
            { name: 'is not', value: '__neq' },
        ],
        placeholder: 'Select Source',
        dropdownOptions: [
            { text: 'Bulk', value: 'bulk' },
            { text: 'Dashboard', value: 'dashboard' },
        ],
        type: FILTER_OPERATOR_TYPE.Dropdown,
    }),
    new Filter({
        name: 'My Watchlist',
        value: 'is_watchlisted',
        input: 'true',
        icon: 'fas fa-eye',
        useOnce: true,
        type: FILTER_OPERATOR_TYPE.String,
        operators: [
            { name: 'is', value: '' },
        ],
        show: true,
        isPremium: true,
        placeholder: 'My Watchlist',
    }),
    new Filter({
        name: 'Selected QR Code',
        value: 'product_id',
        icon: 'fas',
        operators: [
            { name: 'is', value: '__in' },
        ],
        useOnce: true,
        type: FILTER_OPERATOR_TYPE.NUMBER_OF_PRODUCTS_SELECTED,
    })
];

export const QR_OWNER_FILTER: Array<Filter> = [
    new Filter({
        name: 'Owner',
        icon: 'fas fa-user-alt',
        value: 'owner',
        input: '',
        show: true,
        placeholder: 'Enter Owner Name',
        dropdownOptions: [],
        operators: [
            { name: 'contains', value: '__icontains_all' },
            { name: 'does not contain', value: '__icontains_nin' },
            { name: 'is', value: '__in' },
            { name: 'is not', value: '__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.Dropdown,
    }),
];

export const CARD_FILTER_STRING_OPERATORS = [
    { name: 'contains', value: '__icontains_all' },
    { name: 'does not contain', value: '__icontains_nin' },
    { name: 'is', value: '__in' },
    { name: 'is not', value: '__nin' },
]

export const CARD_FILTER_NUMBER_OPERATORS = [
    { name: 'is', value: '' },
    { name: 'is less than', value: '__lt' },
    { name: 'is greater than', value: '__gt' },
    { name: 'is not', value: '__neq' },
]

export const CARD_FILTER_BOOLEAN_OPERATORS = [
    { name: 'is', value: '' },
    { name: 'is not', value: '__isnot' }
]

export const CARDS_FILTER_OPTIONS: Array<Filter> = [
    new Filter({
        name: 'Full Name',
        icon: 'fas fa-user',
        value: 'card_name',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Full Name',
        useOnce: true
    }),
    new Filter({
        name: 'Owner Email',
        icon: 'fas fa-envelope',
        value: 'card_owner_email',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Owner Email',
        useOnce: true
    }),
    new Filter({
        name: 'Card Link',
        icon: 'fas fa-link',
        value: 'url',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Card Link',
        useOnce: true
    }),
    new Filter({
        name: 'Card Views',
        icon: 'fas fa-eye',
        value: 'views',
        input: '',
        show: true,
        operators: CARD_FILTER_NUMBER_OPERATORS,
        type: FILTER_OPERATOR_TYPE.Number,
        placeholder: 'Enter View Count',
    }),
    new Filter({
        name: 'Card Saves',
        icon: 'fas fa-download',
        value: 'saves',
        input: '',
        show: true,
        operators: CARD_FILTER_NUMBER_OPERATORS,
        type: FILTER_OPERATOR_TYPE.Number,
        placeholder: 'Enter Save Count',
    }),
    new Filter({
        name: 'Location',
        icon: 'fas fa-map-marker-alt',
        value: 'address_v2',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Location',
        useOnce: true
    }),
    new Filter({
        name: 'Department',
        icon: 'fas fa-users',
        value: 'department',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Department',
        useOnce: true
    }),
    new Filter({
        name: 'Company Name',
        icon: 'fas fa-building',
        value: 'company',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Search Company Name',
        useOnce: true
    }),
    new Filter({
        name: 'Job Title',
        icon: 'fas fa-id-card',
        value: 'designation',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Job Title',
        useOnce: true
    }),
    new Filter({
        name: 'Card Template',
        icon: 'fas fa-pager',
        value: 'card_template',
        input: '',
        show: true,
        useOnce: true,
        operators: CARD_FILTER_BOOLEAN_OPERATORS,
        dropdownOptions: [],
        type: FILTER_OPERATOR_TYPE.Dropdown,
        placeholder: 'Select Template'
    }),
    new Filter({
        name: 'Date Created',
        icon: 'fas fa-calendar-alt',
        value: 'created',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    }),
    new Filter({
        name: 'Last Updated',
        icon: 'fas fa-calendar-alt',
        value: 'updated',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    }),
    new Filter({
        name: 'Label',
        icon: 'fas fa-tags',
        value: 'tags',
        input: '',
        show: true,
        placeholder: 'Select Label',
        dropdownOptions: [],
        operators: [
            { name: 'is', value: '__id__all_in' },
            { name: 'is not', value: '__id__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.Dropdown,
    }),
];

export const LEAD_FILTER_OPTIONS: Array<Filter> = [
    new Filter({
        name: 'Name',
        icon: 'fas fa-user',
        value: 'lead_name',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Name',
        useOnce: true
    }),
    new Filter({
        name: 'Owner Email',
        icon: 'fas fa-envelope',
        value: 'owner_email',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Owner Email',
        useOnce: true
    }),
    new Filter({
        name: 'Company',
        icon: 'fas fa-building',
        value: 'company',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Search Company Name',
        useOnce: true
    }),
    new Filter({
        name: 'Email',
        icon: 'fas fa-building',
        value: 'email',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Search Email',
        useOnce: true
    }),
    new Filter({
        name: 'Job Title',
        icon: 'fas fa-id-card',
        value: 'designation',
        input: '',
        show: true,
        operators: CARD_FILTER_STRING_OPERATORS,
        type: FILTER_OPERATOR_TYPE.String,
        placeholder: 'Enter Job Title',
        useOnce: true
    }),
    new Filter({
        name: 'Date Created',
        icon: 'fas fa-calendar-alt',
        value: 'created',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    }),
    new Filter({
        name: 'Last Updated',
        icon: 'fas fa-calendar-alt',
        value: 'updated',
        input: '',
        useOnce: true,
        show: true,
        radioOptions: [
            {
                name: 'Last 24 hours',
                state: false,
                disabled: false,
                value: '1',
            },
            {
                name: 'Last week',
                state: false,
                disabled: false,
                value: '7',
            },
            {
                name: 'Last month',
                state: false,
                disabled: false,
                value: '30',
            },
            {
                name: 'Last 3 months',
                state: false,
                disabled: false,
                value: '90',
            },
            {
                name: 'Custom',
                state: false,
                disabled: false,
            },
        ],
        type: FILTER_OPERATOR_TYPE.Date,
    }),
    new Filter({
        name: 'Label',
        icon: 'fas fa-tags',
        value: 'tags',
        input: '',
        show: true,
        placeholder: 'Select Label',
        dropdownOptions: [],
        operators: [
            { name: 'is', value: '__id__all_in' },
            { name: 'is not', value: '__id__nin' },
        ],
        type: FILTER_OPERATOR_TYPE.Dropdown,
    }),
];

export const QR_FILTER_OPTIONS_USE_ONCE = [
    {
        name: 'Date created',
        value: 'created',
    },
    {
        name: 'Last Updated',
        value: 'updated',
    },
];

export function isBase64String(str: string) {
    try {
        const decodedString = atob(str);
        for (let i = 0; i < decodedString.length; i++) {
            if (decodedString.charCodeAt(i) > 255) {
                return false;
            }
        }
        return true;
    } catch (error) {
        return false;
    }
}

export enum FirebaseNotificationTypes {
    DBC_BULK = 'DBC_BULK',
}

export function isBeaconstacDomainRequest(){
    const domainString = 'domain=beaconstac'
    return  window.location.search.indexOf(domainString) !== -1 || document.cookie.indexOf(domainString) !== -1;
}

export function getAuth0Domain() {
    const domainString = 'domain=beaconstac; path=/; domain=.uniqode.com;'
    if (isBeaconstacDomainRequest()) {
        document.cookie = domainString;
        return environment.auth0Domain.replace('uniqode', 'beaconstac');
    }
    return environment.auth0Domain;
}

export async function copyImageToClipboard(svg) {
    try {
        // Convert SVG text to blob
        const svgBlob = new Blob([svg], { type: 'image/svg+xml' });

        // Function to convert SVG blob to PNG blob
        const getPngBlob = async () => {
            const pngDataUrl: any = await svgToPngDataUrl(svgBlob);
            const imageData = atob(pngDataUrl.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(imageData.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < imageData.length; i++) {
                uint8Array[i] = imageData.charCodeAt(i);
            }

            return new Blob([arrayBuffer], { type: 'image/png' });
        };

        const data = [
            new ClipboardItem({
                'image/png': getPngBlob(),
            }),
        ];

        await navigator.clipboard.write(data);
    } catch (err) {
        console.error(err.name, err.message);
    }
}

export async function svgToPngDataUrl(svgBlob) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width * 2;
            canvas.height = img.height * 2;
            ctx.imageSmoothingEnabled = false;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            const pngDataUrl = canvas.toDataURL('image/png');
            resolve(pngDataUrl);
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(svgBlob);
    });
}

export async function svgToPng(svgString) {
    return new Promise((resolve, reject) => {
        try {
            const encodedSvgString = encodeURIComponent(svgString);
            const img = new Image();
            img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodedSvgString));

            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width * 2;
                canvas.height = img.height * 2;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                const pngDataUrl = canvas.toDataURL('image/png');
                resolve(pngDataUrl);
            };

            img.onerror = function (error) {
                reject(new Error('Failed to load SVG image: '));
            };
        } catch (error) {
            reject(new Error('Error during SVG to PNG conversion: ' + error.message));
        }
    });
}

export function findDifferencesInForm (obj1: any, obj2: any): string[] {
    const differences: string[] = [];

    const checkDifferences = (a: any, b: any, parentKey = '') => {
        if (isEqual(a, b)) {
            return;
        }

        if (typeof a === 'object' && typeof b === 'object' && a && b) {
            if (Array.isArray(a) && Array.isArray(b)) {
                if (a.length !== b.length || !a.every((item, index) => isEqual(item, b[index]))) {
                    differences.push(parentKey);
                }
                return;
            }

            if (parentKey !== '') {
                differences.push(parentKey);
            }

            const keys = new Set([...Object.keys(a), ...Object.keys(b)]);
            keys.forEach((key) => {
                checkDifferences(a[key], b[key], parentKey ? `${parentKey}.${key}` : key);
            });
        } else {
            differences.push(parentKey);
        }
    };

    checkDifferences(obj1, obj2);
    return differences;
}

export function getSpecificFieldDifferences(obj1: any, obj2: any, fieldToLogTextMap): string[] {
    const differences: string[] = [];
    const checkDifferences = (a: any, b: any, parentKey = '') => {
        if (isEqual(a, b)) {
            return;
        }
        if (fieldToLogTextMap[parentKey]) {
            differences.push(fieldToLogTextMap[parentKey]);
            return;
        }
        if (typeof a === 'object' && typeof b === 'object' && a && b) {
            const keys = new Set([...Object.keys(a), ...Object.keys(b)]);
            keys.forEach((key) => {
                checkDifferences(a[key], b[key], parentKey ? `${parentKey}.${key}` : key);
            });
        }
    };
    checkDifferences(obj1, obj2);
    return differences;
}

export const isObjectEmpty = (objectName) => {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
};

export const linksInMessageExpression = /\b((https?|ftp):\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/gi;

export function toBase64Unicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(_, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }));
}

export enum PRICING_PAGE_TYPE {
    UPGRADE_NOW = 'upgrade-now',
    RENEW_PLAN = 'renew-plan',
}

export function getInvertedColor(bgColor) {
    // This function is used to get the inverted color of the background color
    const color = bgColor.substring(1, 7);
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#ffffff';
}

export function decodeBase64EncodedString(base64String: string){
    const decodedString = atob(base64String);
    const decodedArray = new Uint8Array(decodedString.split('').map(char => char.charCodeAt(0)));
    const decodedJsonStr = new TextDecoder().decode(decodedArray);
    const decodedParams = JSON.parse(decodedJsonStr);
    return decodedParams;
}

export function getCustomColoredSocialIconSvg(name, backgroundColor = '#000000', color = '#ffffff') {

    name = name.toLowerCase();

    switch (name) {
        case 'wistia':
            return `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" width="14" height="14" viewBox="0 0 34.4 26.78">
                      <g id="Wistia_Logos" data-name="Wistia Logos">
                        <path id="Wistia_Flags_One_Color_White" data-name="Wistia Flags One Color White" class="cls-1" d="m16.09,17.1h-5.2c-1.58,0-3.08.68-4.11,1.87L.21,26.53c4.78.25,9.78.25,13.3.25,18.31,0,20.89-11.27,20.89-16.55-1.59,1.93-6.06,6.87-18.32,6.87ZM32.14,0c-.08.92-.59,4.69-11.31,4.69-8.72,0-12.24,0-20.83-.17l6.44,7.4c1.25,1.44,3.05,2.28,4.96,2.3,2.13.03,5.05.06,5.53.06,11.01,0,17.19-5.05,17.19-9.89,0-2.01-.67-3.44-1.97-4.4Z" fill="${color}" stroke-width="0"/>
                      </g>
                    </svg>`;
        case 'calendly':
            return `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 120 123" fill="none">
                        <g clip-path="url(#clip0_772_93)">
                            <path d="M82.1752 79.6035C78.2922 83.0373 73.4735 87.312 64.6782 87.312H59.415C53.0524 87.312 47.2746 84.9995 43.1343 80.8182C39.0875 76.7303 36.8655 71.1474 36.8655 65.074V57.9027C36.8655 51.8293 39.0875 46.2464 43.1343 42.1585C47.2746 37.9772 53.0524 35.6646 59.415 35.6646H64.6548C73.4501 35.6646 78.269 39.9394 82.1521 43.3732C86.1755 46.9238 89.6606 50.0073 98.9004 50.0073C100.351 50.0073 101.754 49.8905 103.111 49.6802C103.111 49.6569 103.088 49.6335 103.088 49.6101C102.526 48.2553 101.895 46.9005 101.146 45.6157L94.9472 34.9172C89.2629 25.0829 78.7603 19.0328 67.4152 19.0328H55.0407C43.6723 19.0328 33.1696 25.0829 27.5088 34.9172L21.3098 45.6157C15.6256 55.45 15.6256 67.5501 21.3098 77.3843L27.5088 88.0829C33.193 97.9171 43.6957 103.967 55.0407 103.967H67.4152C78.7837 103.967 89.2863 97.9171 94.9472 88.0829L101.146 77.3843C101.895 76.0996 102.55 74.7447 103.088 73.3899C103.088 73.3665 103.111 73.3432 103.111 73.3198C101.731 73.1096 100.327 72.9928 98.9004 72.9928C89.6606 72.9694 86.1986 76.0295 82.1752 79.6035Z" fill="${color}"/>
                            <path d="M64.6783 42.1819H59.4152C49.7544 42.1819 43.3918 49.0729 43.3918 57.9027V65.074C43.3918 73.9038 49.7544 80.7948 59.4152 80.7948H64.6547C78.7366 80.7948 77.6372 66.4522 98.9237 66.4522C100.959 66.4522 102.971 66.6391 104.936 67.0128C105.591 63.3688 105.591 59.6313 104.936 55.9872C102.971 56.361 100.959 56.5478 98.9237 56.5478C77.6606 56.5245 78.7602 42.1819 64.6783 42.1819Z" fill="${color}"/>
                            <path d="M117.169 72.2686C113.544 69.5823 109.357 67.8537 104.936 67.0128C104.936 67.0595 104.936 67.0828 104.912 67.1296C104.538 69.2319 103.953 71.3109 103.134 73.3198C106.854 73.9271 110.292 75.3287 113.24 77.4777C113.24 77.5011 113.216 77.5478 113.216 77.5712C111.532 83.0606 108.959 88.2464 105.637 92.9649C102.339 97.6134 98.339 101.725 93.7776 105.135C84.2805 112.236 72.9825 115.997 61.076 115.997C53.7076 115.997 46.5729 114.549 39.836 111.722C33.3331 108.989 27.5088 105.042 22.5029 100.043C17.497 95.0439 13.5672 89.2041 10.807 82.7336C7.95316 76.0294 6.52619 68.8815 6.52619 61.5233C6.52619 54.1652 7.97655 47.0406 10.807 40.3131C13.5438 33.8192 17.497 28.0027 22.5029 23.0039C27.5088 18.0049 33.3565 14.0806 39.836 11.3242C46.5495 8.47438 53.7076 7.04947 61.076 7.04947C72.9825 7.04947 84.2805 10.8103 93.7776 17.9115C98.339 21.3454 102.339 25.4332 105.637 30.0817C108.982 34.8003 111.532 39.9627 113.216 45.4755C113.216 45.4989 113.24 45.5456 113.24 45.5689C110.292 47.718 106.854 49.1195 103.134 49.7269C103.93 51.7358 104.538 53.8148 104.912 55.9171C104.912 55.9405 104.912 55.9872 104.936 56.0105C109.357 55.193 113.52 53.441 117.169 50.7547C120.655 48.1852 119.977 45.2653 119.439 43.5367C111.766 18.6357 88.538 0.532227 61.076 0.532227C27.345 0.532227 0.0233154 27.8392 0.0233154 61.5C0.0233154 95.1607 27.3684 122.468 61.076 122.468C88.538 122.468 111.766 104.364 119.439 79.4633C119.977 77.7347 120.655 74.8381 117.169 72.2686Z" fill="${color}"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_772_93">
                                <rect width="120" height="121.935" fill="white" transform="translate(0 0.532227)"/>
                            </clipPath>
                        </defs>
                        </svg>`;
        case 'cashapp':
            return `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 121 120" fill="none">
                        <g clip-path="url(#clip0_771_59)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M64.7611 28.7146C74.0883 28.7146 83.0281 32.4915 88.8691 37.6755C90.3465 38.9873 92.5578 38.9792 93.9443 37.5743L100.894 30.5254C102.36 29.0377 102.307 26.6019 100.723 25.2424C96.0597 21.2333 89.7988 17.2909 82.093 14.7669L84.2728 4.38753C84.7453 2.12523 83.0227 -0.000854492 80.7097 -0.000854492H67.2798C65.5626 -0.000854492 64.0776 1.2011 63.7198 2.8811L61.7565 12.1119C43.904 13.004 28.7731 21.9115 28.7731 40.1888C28.7731 56.0118 41.2917 62.7933 54.5047 67.486C67.0238 72.179 73.6289 73.9205 73.6289 80.5257C73.6289 87.3072 67.0238 91.3059 57.2853 91.3059C48.4072 91.3059 39.1069 88.3755 31.8898 81.2601C30.4741 79.8633 28.2092 79.8525 26.7935 81.2497L19.3257 88.6163C17.8646 90.0558 17.8781 92.4224 19.3496 93.8485C25.1726 99.5 32.5441 103.592 40.9578 105.884L38.9089 115.499C38.431 117.754 40.1379 119.88 42.4455 119.898L55.8965 120C57.6272 120.013 59.1307 118.803 59.4835 117.107L61.4253 107.863C82.8009 106.543 95.8833 94.9249 95.8833 77.9188C95.8833 62.2727 82.8463 55.6644 67.0238 50.2747C57.9823 46.9681 50.1563 44.7109 50.1563 37.932C50.1563 31.3269 57.4617 28.7146 64.7611 28.7146Z" fill="${color}"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_771_59">
                                <rect width="120" height="120" fill="white" transform="translate(0.0884399)"/>
                            </clipPath>
                        </defs>
                    </svg>`;
        case 'venmo':
            return `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 121 121" fill="none">
                    <g clip-path="url(#clip0_771_56)">
                        <path d="M6.34852 -3.91577C1.68051 3.81058 -0.412049 11.7516 -0.412049 21.7851C-0.412049 53.7636 26.8985 95.3464 49.0581 124.535H99.7087L120.044 3.11306L75.7248 -1.07204L64.9937 85.3129C54.9602 68.9481 42.5658 43.3008 42.5658 25.7556C42.5658 16.1513 44.2291 9.65901 46.7509 4.23982L6.34852 -3.91577Z" fill="${color}"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_771_56">
                            <rect width="120" height="120" fill="white" transform="matrix(-1 0 0 1 120.044 0.30957)"/>
                        </clipPath>
                    </defs>
                </svg>`;
        case 'twitter':
            return `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 121 123" fill="none">
                    <g clip-path="url(#clip0_767_44)">
                        <path d="M72.3723 51.8941L117.045 0H106.459L67.6697 45.059L36.6888 0H0.955994L47.8052 68.1371L0.955994 122.556H11.5426L52.5051 74.9723L85.2232 122.556H120.956L72.3697 51.8941H72.3723ZM57.8725 68.7374L53.1257 61.9525L15.3571 7.96418H31.6175L62.0972 51.5345L66.844 58.3194L106.464 114.954H90.2036L57.8725 68.74V68.7374Z" fill="${color}"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_767_44">
                            <rect width="120" height="122.619" fill="white" transform="translate(0.955994)"/>
                        </clipPath>
                    </defs>
                </svg>`;
        case 'custom_url':
        case 'custom url':
            return `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 121 120" fill="none">
                        <circle cx="60.1324" cy="60" r="56.4849" stroke="${color}" stroke-width="7.03025"/>
                        <path d="M60.1324 115.055C75.3964 115.055 87.7703 90.4061 87.7703 60C87.7703 29.5938 75.3964 4.94482 60.1324 4.94482" stroke="${color}" stroke-width="7.03025"/>
                        <path d="M60.1324 115.055C44.8685 115.055 32.4946 90.4061 32.4946 60C32.4946 29.5938 44.8685 4.94482 60.1324 4.94482" stroke="${color}" stroke-width="7.03025"/>
                        <line x1="58.7748" y1="4.94482" x2="58.7748" y2="115.055" stroke="${color}" stroke-width="7.03025"/>
                        <path d="M103.237 23.7741C93.1503 30.1372 77.5903 34.2163 60.1325 34.2163C43.15 34.2163 27.9634 30.3563 17.8643 24.2886" stroke="${color}" stroke-width="7.03025"/>
                        <path d="M102.481 95.5492C92.3826 89.4535 77.1603 85.5729 60.1324 85.5729C43.1045 85.5729 27.8822 89.4535 17.7833 95.5492" stroke="${color}" stroke-width="7.03025"/>
                        <line x1="5.07733" y1="61.3579" x2="115.188" y2="61.3579" stroke="${color}" stroke-width="7.03025"/>
                    </svg>`;
    }

    return '';
}

export const HOMEPAGE_ROUTE = '/overview'

export enum LEAD_SYNC_INTEGRATION_CONFIG_TYPE {
    SALESFORCE = 1,
    HUBSPOT,
    PIPEDRIVE,
}

/**
 * Formats text into multiple lines, ensuring each line does not exceed `maxLength` characters.
 * - Preserves spaces where possible.
 * - Splits words exceeding `maxLength` into separate chunks.
 * - Maintains newline structure from the original text.
 */

function breakIntoLines(text: string, maxLength: number = 30): string[] {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    for (const word of words) {
        // If a word is longer than maxLength, break it into chunks
        if (word.length > maxLength) {
            // Push the current line before handling long words
            if (currentLine.length > 0) {
                lines.push(currentLine);
                currentLine = '';
            }

            for (let i = 0; i < word.length; i += maxLength) {
                lines.push(word.substring(i, i + maxLength));
            }
            continue; // Skip adding this word to the current line
        }

        // Check if adding this word exceeds maxLength
        if ((currentLine.length + word.length + (currentLine ? 1 : 0)) <= maxLength) {
            currentLine += (currentLine ? ' ' : '') + word;
        } else {
            // Store the current line and start a new one
            lines.push(currentLine);
            currentLine = word;
        }
    }

    if (currentLine) {
        lines.push(currentLine);
    }
    return lines;
}

export function formatTextWithCharLimit(originalText: string, maxLength: number = 30): string {
    return originalText
        .split('\n')
        .map(line => breakIntoLines(line, maxLength))
        .reduce((acc, val) => acc.concat(val), [])
        .join('\n');
}

export interface ParsedPhoneNumber {
    original: string;
    formatted: string;
    nationalNumber: string;
    countryCallingCode?: string;
    country?: string;
    isValid: boolean;
}

export function parsePhoneNumberString(phoneString: string): ParsedPhoneNumber {
    if (!phoneString) {
        return {
            original: '',
            formatted: '',
            nationalNumber: '',
            isValid: false
        };
    }

    const phoneNumber = parsePhoneNumberFromString(phoneString);

    if (phoneNumber) {
        return {
            original: phoneString,
            formatted: phoneNumber.formatInternational(),
            nationalNumber: phoneNumber.nationalNumber,
            countryCallingCode: phoneNumber.countryCallingCode,
            country: phoneNumber.country,
            isValid: phoneNumber.isValid()
        };
    }

    // Fallback for invalid numbers
    return {
        original: phoneString,
        formatted: phoneString,
        nationalNumber: phoneString,
        isValid: false
    };
}
